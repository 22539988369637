import WS from '../api/ws';
import View from '../api/view';
import DataSource from 'devextreme/data/data_source';

//import { projetoStatusList } from './ProjetoStatus';
//import { projetoClassificacaoList } from './ProjetoClassificacao';
//import { projetoCategoriaList } from './ProjetoCategoria';

export const projetoStatusAtividade = [
    {
        "id": "1",
        "descricao": "Programado"
    },
    {
        "id": "2",
        "descricao": "Andamento"
    },
    {
        "id": "3",
        "descricao": "Concluído"
    },
    {
        "id": "4",
        "descricao": "Não iniciado"
    },
    {
        "id": "5",
        "descricao": "Atrasado"
    }
];

export let projetoColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Atividade",
        icon: "fa fa-pencil-alt",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.load('projeto/detalhes', function(html_id, projeto) {
                projeto.show(e.row.data.projeto_id, e.row.data.id, window.FORMULARIO.EDITAR);
            }, View.ABA.MULTIPLAS);
        }
    }
};

export const projetoColunaButtons = {
    type: "buttons",
    buttons: [
        projetoColunaButtonVisualizar()
    ]
};

export let projetoColunas = () => {
    return [
        {
            dataField: "codigo",
            width: "100px"
        },
        {
            dataField: "nome",
            caption: "Nome do projeto",
            width: "100px"
        },
        {
            dataField: "categoria",
            caption: "Categoria",
            groupIndex: 0
        },
        {
            dataField: "status_nome",
            caption: 'Status',
            width: "100px"
        },
        {
            dataField: "status_atividades",
            dataType: "number",
            format: 'fixedPoint',
            precision: 0,
            lookup: {
                dataSource: projetoStatusAtividade,
                displayExpr: "descricao",
                valueExpr: "id"
            },
            width: "100px"
        },
        {
            dataField: "classificacao_nome",
            caption: "Classificação",
            width: "100px"
        },
        {
            dataField: "inicio",
            caption: "Ínicio",
            dataType: "date",
            format: "dd/MM/yyyy",
            width: "100px"
        },
        {
            dataField: "fim",
            caption: "Fim",
            dataType: "date",
            format: "dd/MM/yyyy",
            width: "100px"
        },
        {
            dataField: "horas_previstas",
            calculateSortValue: function(data) {
                return data.horas_previstas ? window.fn_converter_horas_para_segundos(data.horas_previstas) : null;
            },
            width: "80px"
        },
        {
            dataField: "horas_trabalhadas",
            calculateSortValue: function(data) {
                return data.horas_trabalhadas ? window.fn_converter_horas_para_segundos(data.horas_trabalhadas) : null;
            },
            width: "80px"
        },
        {
            dataField: "saldo_horas",
            calculateSortValue: function(data) {
                return data.saldo_horas ? window.fn_converter_horas_para_segundos(data.saldo_horas) : null;
            },
            width: "80px"
        },
        {
            dataField: "percentual_horas_trabalhadas",
            caption: "% Trabalhado",
            dataType: "number",
            calculateSortValue: function(data) {
                return data.percentual_horas_trabalhadas ? parseInt(data.percentual_horas_trabalhadas) : null;
            },
            calculateDisplayValue: function(data) {
                return data.percentual_horas_trabalhadas ? (data.percentual_horas_trabalhadas + " %") : null;
            },
            width: "80px"
        },
        {
            dataField: "percentual_saldo_horas",
            caption: "% Saldo",
            dataType: "number",
            calculateSortValue: function(data) {
                return data.percentual_saldo_horas ? parseInt(data.percentual_saldo_horas) : null;
            },
            calculateDisplayValue: function(data) {
                return data.percentual_saldo_horas ? (data.percentual_saldo_horas + " %") : null;
            },
            width: "80px"
        },
        {
            dataField: "porcentagem_concluido",
            caption: "% Conclusão",
            dataType: "number",
            calculateSortValue: function(data) {
                return data.porcentagem_concluido ? parseInt(data.porcentagem_concluido) : null;
            },
            calculateDisplayValue: function(data) {
                return data.porcentagem_concluido ? (data.porcentagem_concluido + " %") : null;
            },
            width: "80px"
        },
        {
            dataField: "percentual_estimado",
            caption: "% Estimado",
            dataType: "number",
            calculateSortValue: function(data) {
                return data.percentual_estimado ? parseInt(data.percentual_estimado) : null;
            },
            calculateDisplayValue: function(data) {
                return data.percentual_estimado ? (data.percentual_estimado + " %") : null;
            },
            width: "80px"
        },
        {
            dataField: "cliente_nome",
            caption: "Cliente",
            width: "100px"
        },
        {
            dataField: "tipo_nome",
            caption: "Tipo Projeto",
            width: "80px"
        }
    ]
};

export let projetoList = (projetoId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'projeto_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "projeto/listar/",
                data: {
                    template: 'N',
                    projeto_id: projetoId,
                    retorno_obj: false,
                    dx_grid: true,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "projeto/listar/",
                data: {
                    template: 'N',
                    projeto_id: key,
                    retorno_obj: false,
                    dx_grid: true
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});