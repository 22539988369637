import View from '../api/view';

export let tarefaQuadroListaCartaoColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Cartão",
        icon: "fa fa-pencil-alt",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.load('tarefa/detalhes_cartao', function(html_id, detalhes) {
                detalhes.show(e.row.data.id);
            }, View.ABA.MULTIPLAS);
        }
    }
};

export const tarefaQuadroListaCartaoColunaButtons = {
    type: "buttons",
    buttons: [
        tarefaQuadroListaCartaoColunaButtonVisualizar()
    ]
};

export const tarefaQuadroListaCartaoColunas = [
    { 
        dataField: "nome",
        caption: "Cartão",
    },
    { 
        dataField: "nome_lista",
        caption: "Lista",
    },
    { 
        dataField: "nome_quadro",
        caption: "Quadro",
    },
    { 
        dataField: "data_prazo",
        caption: "Prazo",
        minWidth: 100,
        dataType: "datetime",
        format: "dd/MM/yyyy HH:mm",
        calculateCellValue: function(data) {
            if (data.data_prazo) {
                return data.data_prazo.format_date_time();
            }
        },
    },
    { 
        dataField: "data_conclusao",
        caption: "Conclusão",
        minWidth: 100,
        dataType: "datetime",
        format: "dd/MM/yyyy HH:mm",
        calculateCellValue: function(data) {
            if (data.data_conclusao) {
                return data.data_conclusao.format_date_time();
            }
        }
    }
];