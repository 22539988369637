import { Editor } from '@tinymce/tinymce-react';
import App from '../api/app';
import WS from '../api/ws';

export default class RichTextEditor extends Editor {
    constructor(props) {
        super(props);
        this.props.init.images_upload_handler = this.handleImagesUpload.bind(this);
        this.props.init.content_css = "./" + App.url_css_bootstrap;
    }

    handleImagesUpload(blobInfo, success, failure) {
        let xhr, formData;
        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', window.WS_URI + '/imagem_tinymce/upload/');
        xhr.setRequestHeader("X-Auth-Token", WS.token);
        xhr.onload = function() {
            let json;
        
            if (xhr.status !== 200) {
                failure('HTTP Error: ' + xhr.status);
                return;
            }
        
            json = JSON.parse(xhr.responseText);
        
            if (!json || typeof json.location !== 'string') {
                failure('Invalid JSON: ' + xhr.responseText);
                return;
            }
        
            success(json.location);
        };
        
        formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        if (typeof this.props.moduloId !== 'undefined') {
            formData.append('modulo_id', this.props.moduloId);
        }
    
        xhr.send(formData);
    };
}

RichTextEditor.defaultProps = {
    moduloId: null,
    init: {
        menubar: false,
        statusbar: true,
        branding: false,
        theme: "silver",
        menu : { },
        height: 310,
        extended_valid_elements : "script[language|type]",
        invalid_elements : "script",
        language: "pt_BR",
        format: "text",
        paste_data_images: true,
        browser_spellcheck: true,
        contextmenu: false,
        media_live_embeds: true,
        media_poster: false,
        media_dimensions: false,
        media_alt_source: false,
        resize: true,
        min_height: 310,
        plugins: [
            "fullscreen media advlist autolink lists link image imagetools charmap print preview hr anchor pagebreak",
            "searchreplace visualblocks visualchars code",
            "insertdatetime nonbreaking table directionality",
            "template paste textpattern"
        ],
        toolbar: [
            "undo redo | styleselect | bold italic | forecolor backcolor | fullscreen",
            "alignleft aligncenter alignright alignjustify | bullist numlist | link image media "
        ],
        image_advtab: true,
        forced_root_block : ''
    }    
};