import React from 'react';

export default class LabelMotivo extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        let motivo_cor = window.Colors(this.state.detalhes.motivo_cor);
        return (
            <span className="label" style={{background: motivo_cor.hex, color: motivo_cor.nome_hex }}>{this.state.detalhes.motivo}</span>
        );
    }
}

LabelMotivo.defaultProps = {
    
};