import React from 'react';
import DataGridPadrao from './DataGridPadrao';
import DropDownBox from 'devextreme-react/drop-down-box';
//import './SelecaoPadrao.scss';

export default class SelecaoPadrao extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selecionado: props.selecionado ? [props.selecionado] : null,
            placeholder: props.placeholder ?? "",
            dataSource: props.dataSource ?? "",
            columns: props.columns ?? [],
            readOnly: props.readOnly ?? false,
            fieldRender: props.fieldRender ?? null,
            displayExpr: props.displayExpr ?? null,
            visible: props.visible ?? true,
            width: props.width ?? null,
        };

        this.dropDownBoxRef = React.createRef();

        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
        this.onOpened = this.onOpened.bind(this);
        this.onClosed = this.onClosed.bind(this);
        this.ajustarTamanhoJanela = this.ajustarTamanhoJanela.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
    }

    get dropDownBox() {
        return this.dropDownBoxRef.current ? this.dropDownBoxRef.current.instance : null;
    }

    render() {
        return (
            <DropDownBox
                ref={this.dropDownBoxRef}
                dataSource={this.state.dataSource}
                value={this.state.selecionado}
                deferRendering={false}
                displayExpr={this.state.displayExpr}
                placeholder={this.props.placeholder}
                showClearButton={this.props.showClearButton}
                onValueChanged={this.syncDataGridSelection}
                contentRender={this.dataGridRender}
                onOpened={this.onOpened}
                onClosed={this.onClosed}
                stylingMode={this.props.stylingMode}
                fieldRender={this.props.fieldRender}
                readOnly={this.state.readOnly}
                visible={this.state.visible}
                width={this.state.width}
            >
            </DropDownBox>
        );
    }

    dataGridRender() {
        let searchPanel = {
            visible: !window.detectmob(),
            width:200,
            placeholder: "Pesquisa Geral"
        };
        return (
            <DataGridPadrao
                dataSource={this.state.dataSource}
                columns={this.state.columns}
                hoverStateEnabled={true}
                selectedRowKeys={this.state.selecionado}
                onSelectionChanged={this.dataGrid_onSelectionChanged}
                searchPanel={searchPanel}
                groupPanel={
                    {
                        visible: false
                    }
                }
            >
            </DataGridPadrao>
        );
    }

    syncDataGridSelection(e) {
        this.setState({
            selecionado: e.value
        });

        if (this.props.aoAlterar) {
            this.props.aoAlterar(this.state.selecionado && this.state.selecionado.length > 0 ? this.state.selecionado[0] : null);
        }
    }

    dataGrid_onSelectionChanged(e) {
        if(e.selectedRowKeys.length === 0){
            return;
        }

        this.setState({
            selecionado: e.selectedRowKeys,
            selecionadoPropriedades: e.selectedRowsData
        });
        
        if (this.dropDownBox) {
            this.dropDownBox.close();
        }
        
        if (this.props.aoAlterar) {
            let selecionado = this.state.selecionado && this.state.selecionado.length > 0 ? this.state.selecionado[0] : null;
            let selecionadoPropriedades = this.state.selecionadoPropriedades && this.state.selecionadoPropriedades.length > 0 ? this.state.selecionadoPropriedades[0] : null;
            this.props.aoAlterar(selecionado, selecionadoPropriedades);
        }
    }

    ajustarTamanhoJanela(arg){
        if(arg.component._popup.option("fullScreen")){
            arg.component._popup.content().parents(".dx-popup-fullscreen").css("max-height", "none").css("overflow-y", "scroll");
            arg.component._popup.content().parents(".dx-popup-wrapper").css("width", "100%").css("max-height", "100vh").css("height", "100%");
            arg.component._popup.content().css("overflow", "");
        }else{
            arg.component._popup.content().css("overflow", "auto");
        }

        arg.component._popup.content().children().on("dxmousewheel", function(e) {
            e.stopPropagation();
        });
    }
    
    onOpened(arg) {
        const main = this;

        setTimeout(function(){
            window.document.body.style.overflow = "hidden";
            window.desabilitarScrollViewPrincipal();
        });
        
        arg.component._popup.option("toolbarItems", [
            {
                widget: "dxButton",
                location: "after",
                options: { 
                    icon: "fa fa-window-restore", 
                    onClick: function(e) {
                        arg.component._popup.option("fullScreen", !arg.component._popup.option("fullScreen"));
                        main.ajustarTamanhoJanela(arg);
                    }
                }
            },
            {
                widget: "dxButton",
                location: "after",
                options: { 
                    icon: "fa fa-times ", 
                    onClick: function(e) {
                        arg.component._popup.hide();
                    }
                }
            }
        ])
        arg.component._popup.option("showTitle", true);
        this.ajustarTamanhoJanela(arg);
        setTimeout(function() {
            arg.component._popup.content().find('.dx-toolbar .dx-texteditor-input').focus();
        }, 300);
    }

    onClosed(){
        setTimeout(function(){
            window.document.body.style.overflow = "visible";
            window.habilitarScrollViewPrincipal();
        });
    }
}

SelecaoPadrao.defaultProps = {
    columns: [],
    dataSource: null,
    placeholder: "Pesquisar",
    aoAlterar: null,
    displayExpr: null,
    stylingMode: "filled",
    showClearButton: false,
    readOnly: false,
};