import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaChamadoMotivoNome = {
    dataField: "nome",
    caption: "Motivo"
};

export const chamadoMotivoColunas = [
    colunaChamadoMotivoNome,
];

export let chamadoMotivoList = (chamadoId, grupoMotivoId, motivoVinculadoID) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'chamado_motivo_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado_motivo/listar/",
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    grupo_motivo_id: grupoMotivoId,
                    desconsidera_bloqueado: true,
                    motivo_vinculado_id: motivoVinculadoID,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado_motivo/listar/",
                data: {
                    dx_grid: true,
                    chamado_motivo_id: key,
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});