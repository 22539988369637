import React from 'react';
import App from '../../api/app';
import SelecaoChamadoSituacao from '../../components/SelecaoChamadoSituacao';
import { reclassificar } from '../../datasource/Chamado';
import LabelCard from './LabelCard';

// Contextos
import { MensagemContext } from '../../contexts/MensagemContext';

export default class CardSituacao extends React.Component {

    constructor(props) {
        super(props);

        this.sugerirSituacaoMotivo = true;

        this.state = {
            sugerirSituacaoMotivo: true,
            ...this.propsToObjState(),
        };
        
        this.sugerirSituacaoMotivo = this.state ? this.state.sugerirSituacaoMotivo : this.sugerirSituacaoMotivo;

        this.aoAlterar = this.aoAlterar.bind(this);
    }

    propsToObjState() {
        let situacao = this.props.detalhes ? +this.props.detalhes.situacao : null;

        if (this.props.novaMensagem && this.props.detalhes) {
            if (App.sessao.dados.parametro_chamado.sugerir_situacao === "S" && ((this.state && this.state.sugerirSituacaoMotivo) || (!this.state && this.sugerirSituacaoMotivo)) && +App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA) {
                switch (this.props.detalhes.tipo) {
                    case window.TIPO_ENTIDADE.CLIENTE:
                        situacao = window.CHAMADO_SITUACAO.AGUARDANDO_CLIENTE;
                        break;
                    case window.TIPO_ENTIDADE.ORGANIZACAO:
                        situacao = window.CHAMADO_SITUACAO.AGUARDANDO_ORGANIZACAO;
                        break;
                    case window.TIPO_ENTIDADE.FORNECEDOR:
                        situacao = window.CHAMADO_SITUACAO.AGUARDANDO_FORNECEDOR;
                        break;
                    case window.TIPO_ENTIDADE.EMPRESA:
                        if (situacao === window.CHAMADO_SITUACAO.AGUARDANDO_EMPRESA && +this.props.detalhes.usuario_responsavel_id !== +App.sessao.dados.usuario_id) {
                            situacao = window.CHAMADO_SITUACAO.AGUARDANDO_RESPONSAVEL;
                        }
                        else {
                            if (+this.props.detalhes.solicitante_id !== +App.sessao.dados.usuario_id) {
                                situacao = window.CHAMADO_SITUACAO.AGUARDANDO_EMPRESA;
                            }
                        }
                        break;
                    default:
                        break;
                }
                if (!this.props.reclassificacoesMemoria && situacao !== +this.props.detalhes.situacao && this.props.aoAlterar) {
                    this.props.aoAlterar(situacao);
                }
            }
            else if (this.props.reclassificacoesMemoria && this.props.reclassificacoesMemoria.situacao) {
                situacao = this.props.reclassificacoesMemoria.situacao;
            }
        }
        
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: situacao,
            novaMensagem: this.props.novaMensagem ?? false,
            sugerirMotivo: true
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes.situacao !== this.props.detalhes.situacao
            || prevProps.detalhes.rascunho !== this.props.detalhes.rascunho
            || prevProps.novaMensagem !== this.props.novaMensagem
            || !window.isEquivalent(prevProps.reclassificacoesMemoria, this.props.reclassificacoesMemoria)) {
            this.setState(this.propsToObjState());
        }
    }

    aoAlterar(selecionado) {
        let selecionadoAnterior = this.state.selecionado;
        
        if (+selecionadoAnterior !== +selecionado) {
            this.setState({ selecionado: selecionado, sugerirSituacaoMotivo: false });
        }

        if (!this.state.novaMensagem
            && selecionado
            && +this.state.detalhes.id
            && +this.state.detalhes.situacao !== +selecionado) {

            reclassificar(
                {
                    "chamado_id": this.state.detalhes.id,
                    "situacao": selecionado,
                },
                (this.props.aoAlterar ? this.props.aoAlterar : null),
                (response => this.setState({ selecionado: selecionadoAnterior }))
            );
        }
        else {
            if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
        }
    }

    render() {
        let selecionado = this.state.selecionado ? this.state.selecionado.toString() : null
        let readOnly = (
            (!this.state.novaMensagem && this.context.botaoPressionado && this.context.botaoPressionado !== "reclassificar")
            || App.sessao.dados.tipo !== window.USUARIO_TIPO.ANALISTA
            || 
            (
                !this.state.detalhes.rascunho
                && 
                (
                    !App.verifica_permissao(App.temp.empresa, "reclassificar_chamado")
                    || [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.state.detalhes.situacao)
                )
            )
        );

        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <LabelCard label={this.props.label} novaMensagem={this.state.novaMensagem} />
                <SelecaoChamadoSituacao
                    chamadoId={this.state.detalhes.id}
                    entidadeTipo={this.state.detalhes.tipo ?? null}
                    selecionado={selecionado}
                    aoAlterar={this.aoAlterar}
                    stylingMode="filled"
                    readOnly={readOnly}
                />
            </div>
        );
    }
}

CardSituacao.defaultProps = {
    label: "Situação"
};

CardSituacao.contextType = MensagemContext;