import React from 'react';
import DataGridPadrao from '../../../src/components/DataGridPadrao';
import { listarFormularios, colunasListagemFormularios } from '../../datasource/ListagemFormularios';

export default class ListagemModelagemFormulario extends React.Component {
    
    onToolbarPreparing(e) {
        const arr_botoes = [
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-plus",
                    "hint":"Novo Formulário",
                    "text":"Novo Formulário",
                    onClick: function(){
                        window.View.load('formulario/detalhes', function(html_id, formulario_detalhes) {
                            formulario_detalhes.onclose = e.component.refresh;
                            formulario_detalhes.show(null, window.FORMULARIO.NOVO);
                        });
                    },
                },
                "location":"after",
                "name":"BtnNovoFormulario",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-upload",
                    "hint":"Importar Formulário",
                    "text":"Importar",
                    onClick: function(){
                        window.View.load('formulario/importacao', function(html_id, formulario_importacao) {
                            formulario_importacao.onclose = e.component.refresh;
                            formulario_importacao.show();
                        });
                    },
                },
                "location":"after",
                "name":"BtnImportarFormulario",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-sync",
                    "hint":"Recarregar",
                    "text":"Recarregar",
                    onClick: function() {
                        e.component.refresh();
                    },
                },
                "showText":"inMenu",
                "location":"after",
                "name":"BtnRefresh",
                "locateInMenu":"auto"
            },
        ];

        e.toolbarOptions.items = window.add_botao_dx_toolbar(arr_botoes, e.toolbarOptions.items);
    }

    render() {
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <DataGridPadrao 
                            style={{ height: 'calc(100vh - 50px)' }}
                            dataSource={listarFormularios({ dx_grid: true })}
                            columns={colunasListagemFormularios}
                            pager={{ showPageSizeSelector: true, allowedPageSizes: [25, 50, 100], showInfo: true }}
                            onToolbarPreparing={this.onToolbarPreparing}
                            export={{ enabled: true, fileName: 'Listagem_Formularios_' + window.agora(), allowExportSelectedData: false }}
                            stateStoring={{
                                enabled: true,
                                type: "custom",
                                customLoad: function (state) {
                                    return window.App.get_parametro_usuario('listagemModelagemFormulario');
                                },
                                customSave: function (state) {
                                    window.App.set_parametro_usuario('listagemModelagemFormulario', state);
                                }
                            }}
                        >    
                        </DataGridPadrao>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ListagemModelagemFormulario.defaultProps = {
    abaTitulo: 'Formulários'
}
