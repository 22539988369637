import React from 'react';
import TextBox from 'devextreme-react/text-box';
import LabelCard from './LabelCard';
import { mascaraHorasDx } from '../../helper/MascaraHorasDx'

export default class CardHorasTrabalhadas extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
    }

    propsToObjState() {
        return {
            valor: this.props.valor ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.valor !== this.props.valor) {
            this.setState(this.propsToObjState());
        }
    }

    aoAlterar(valor) {
        if (this.props.aoAlterar) this.props.aoAlterar(valor);
    }
    render() {
        let valor = this.state.valor ? this.state.valor.toString() : null
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <LabelCard label={this.props.label} novaMensagem={true} />
                <TextBox
                    mask={mascaraHorasDx(this.props.mascaraHorasTrabalhadas)}
                    maskRules={{ 'X': /[0-5]/ }}
                    useMaskedValue={true}
                    onValueChanged={this.aoAlterar}
                    value={valor}
                    stylingMode="filled"
                />
            </div>
        );
    }
}

CardHorasTrabalhadas.defaultProps = {
    label: "Horas Trabalhadas",
    valor: null
};