// Estilos
import styles from '../styles/components/cardCockpit.module.css';

export default function CardCockpit({ 
    // Props
    nomeModulo='?', 
    qtdAbertoHoje=0, 
    qtdEmAtraso=0, 
    qtdTotal=0,
    iconeUrl="", 
    btnAddClick=null,
    btnListClick=null,
    customButtons=[],
})
{
    function handleBtnAddClick() {
        if (btnAddClick && typeof btnAddClick === "function") {
            btnAddClick();
        }
    }

    function handleBtnListClick() {
        if (btnListClick && typeof btnListClick === "function") {
            btnListClick();
        }
    }

    return(
        <div className={`${styles.container} col-md-4 col-sm-6 col-lg-3 templateCard`}>
            <div className={styles.card}>
                <div>
                    <span className={styles.topoTitulo}>{nomeModulo}</span>
                    {btnAddClick && (
                        <span className={styles.button} onClick={handleBtnAddClick}>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </span>
                    )}
                    {btnListClick && (
                        <span className={styles.button} onClick={handleBtnListClick}>
                            <i className="fa fa-external-link-alt" aria-hidden="true"></i>
                        </span>
                    )}
                    {customButtons.length > 0 && (
                        customButtons.map((e, index) => (typeof e.visible === "undefined" || e.visible === true) && (
                            <span className={styles.button} onClick={e.onClick} title={e.hint} key={index}>
                                <i className={e.icon} aria-hidden="true"></i>
                            </span>
                        ))
                    )}
                </div>
                <div className={styles.cardCorpo}>
                    <p>{qtdAbertoHoje} Hoje</p>
                    <p className={styles.qtdEmAtraso}>{qtdEmAtraso} Em Atraso</p>
                </div>
                <div className={styles.cardRodape}>
                    <span className={styles.iconeModulo}>
                        {iconeUrl !== "" && <img src={iconeUrl} width="20" alt="" />}
                    </span>
                    <span className={styles.totalSection}>
                        <span className="text-muted">Total</span>&nbsp;&nbsp;<span className={styles.totalIndicator}>{qtdTotal}</span>
                    </span>
                </div>
            </div>
        </div>
    );
}
