import React from 'react';

export default class LabelCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    propsToObjState() {
        let tooltip = this.props.tooltip ?? null;

        if (this.props.novaMensagem) {
            tooltip = 'As alterações serão aplicadas após acionar o botão "Confirmar"';
        }
        
        return {
            label: this.props.label ?? null,
            tooltip: tooltip,
            novaMensagem: this.props.novaMensagem ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.label !== this.props.label
            || prevProps.tooltip !== this.props.tooltip
            || prevProps.novaMensagem !== this.props.novaMensagem) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        return (
            <label className="small text-muted" title={this.state.tooltip}>
                {this.state.label}
                
                {this.state.novaMensagem ?
                <React.Fragment>
                    &nbsp;<i className="text-info fas fa-info-circle"></i>
                    </React.Fragment>
                : null }
            </label>
        );
    }
}

LabelCard.defaultProps = {
    
};