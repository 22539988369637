import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaProspectCodigo = {
    dataField: "codigo",
    caption: "Código"
};

export const colunaProspectUnidade = {
    dataField: "unidade",
    caption: "Unidade"
};

export const colunaProspectNomeFantasia = {
    dataField: "nome_fantasia",
    caption: "Nome Fantasia"
};

export const colunaProspectRazaoSocial = {
    dataField: "razao_social",
    caption: "Razão Social"
};

export const colunaProspectTelefone1 = {
    dataField: "telefone1",
    caption: "Telefone 1"
};

export const colunaProspectTelefone2 = {
    dataField: "telefone2",
    caption: "Telefone 2"
};

export const colunaProspectTelefone3 = {
    dataField: "telefone3",
    caption: "Telefone 3"
};

export const colunaProspectTelefone4 = {
    dataField: "telefone4",
    caption: "Telefone 4"
};

export const ProspectBotoes = function() {
    var icon_class = "icon_black";

    return {
        type: "buttons",
        buttons: [
            {
                hint: "Editar",
                icon: "fas fa-pencil-alt",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('prospect/detalhes', function(html_id, prospect_detalhes) {
                        prospect_detalhes.onclose = e.component.refresh;
                        prospect_detalhes.show(e.row.data.prospect_id, window.FORMULARIO.EDITAR);
                    }, window.View.ABA.MULTIPLAS);
                },
                visible: window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'prospect'),
            },
            {
                hint: "Criar Unidade",
                icon: "fas fa-plus",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('prospect/detalhes', function(html_id, prospect_detalhes) {
                        prospect_detalhes.onclose = e.component.refresh;
                        prospect_detalhes.show(e.row.data.prospect_id, window.FORMULARIO.CRIAR_UNIDADE);
                    }, window.View.ABA.MULTIPLAS);
                },
                visible: window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'prospect'),
            },
            {
                hint: "Efetivar Organização",
                icon: "fas fa-check",
                cssClass: icon_class,
                visible: function(e){
                    return e.row.data.efetivado === "N" && window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'prospect');
                },
                onClick: function(e) {
                    var validacao = new window.Validation();
                    validacao.add(new window.ValidationMessage(window.Validation.CODES.ERR_FIELD, 'Deseja efetivar ' + e.row.data.nome_fantasia));
                    window.alert_modal('Organização', validacao, 'Efetivar', function() {

                        WS.post(
                            "prospect/efetivar",
                            {
                                prospect_id: e.row.data.prospect_id
                            },
                            function (response) {
                                window.alert_saved(response.nome_fantasia + ' efetivado com sucesso');
                                e.component.refresh();
                            }
                        );

                    }, true);
                }
            },
            {
                hint: "Histórico Entidade",
                icon: "fas fa-file",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load("entidade_historico/listar", function(html_id, entidade_historico) {
                        entidade_historico.show(e.row.data.prospect_id, 'O');
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: "Visualizar",
                icon: "fas fa-eye",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('prospect/detalhes', function(html_id, prospect_detalhes) {
                        prospect_detalhes.show(e.row.data.prospect_id, window.FORMULARIO.VISUALIZAR);
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: "Excluir",
                icon: "fas fa-trash",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('prospect/detalhes', function(html_id, prospect_detalhes) {
                        prospect_detalhes.onclose = e.component.refresh;
                        prospect_detalhes.show(e.row.data.prospect_id, window.FORMULARIO.EXCLUIR);
                    }, window.View.ABA.MULTIPLAS);
                },
                visible: window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'prospect'),
            }
        ],
    }
};

export const prospectColunas = function() {
    return [
        colunaProspectCodigo,
        colunaProspectUnidade,
        colunaProspectNomeFantasia,
        colunaProspectRazaoSocial,
        colunaProspectTelefone1,
        colunaProspectTelefone2,
        colunaProspectTelefone3,
        colunaProspectTelefone4,
        ProspectBotoes(),
    ];
}

export const dataGridConfig = {
    stateStoring: {
        enabled: true,
        type: "custom",
        customLoad: function (state) {
            return window.App.get_parametro_usuario('listarProspects');
        },
        customSave: function (state) {
            window.App.set_parametro_usuario('listarProspects', state);
        }
    },
    "export": {
        enabled: true,
        fileName: "Relatório de usuários " + window.agora(),
        allowExportSelectedData: true
    },
}

export let listarProspects = (parametros) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'prospect_id',
    load: (loadOptions) => {
        let dxParams = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "prospect/listar/", 
                data: {
                    ...dxParams,
                    ...parametros
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;

        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "prospect/listar/", 
                data: {
                    dx_grid: true,
                    prospect_id: key,
                    
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});