import React from 'react';
import SelecaoChamadoNotaAvaliacao from '../../components/SelecaoChamadoNotaAvaliacao';

export default class CardAvaliacaoNota extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: this.props.detalhes ? (this.props.detalhes.nota_avaliacao_id ?? this.props.detalhes.avaliacao_padrao_id) : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
    }

    aoAlterar(selecionado) {
        if (!selecionado) return false;
        if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
    }

    render() {
        let selecionado = this.state.selecionado ? this.state.selecionado.toString() : null

        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <label className="small text-muted">{this.props.label}</label>
                <SelecaoChamadoNotaAvaliacao
                    chamadoId={this.state.detalhes.id}
                    selecionado={selecionado}
                    aoAlterar={this.aoAlterar}
                    stylingMode="filled"
                    readOnly={this.state.detalhes.nota_avaliacao_id}
                />
            </div>
        );
    }
}

CardAvaliacaoNota.defaultProps = {
    label: "Referente ao atendimento realizado, você ficou",
};