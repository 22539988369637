import React from 'react';
import $ from 'jquery';
import App from '../../api/app';
import WS from '../../api/ws';
import View from '../../api/view';
import FotoThumb from '../../components/FotoThumb';
import Mensagem from './mensagem/Mensagem';
import { Usuario } from './mensagem/Usuario';
import DataEnvio from './mensagem/DataEnvio';
import { HorasTrabalhadas } from './mensagem/HorasTrabalhadas';
import { Tipo } from './mensagem/Tipo';
import { Observacoes } from './mensagem/Observacoes';
import { Documentos } from './mensagem/Documentos';
import { VerticalSpacer, HorizontalSpacer } from '../../components/Spacer';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { ButtonGroup, Item as ButtonGroupItem } from 'devextreme-react/button-group';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import CardPrevisaoRetorno from './CardPrevisaoRetorno';
import CardSituacao from './CardSituacao';
import CardHorasTrabalhadas from './CardHorasTrabalhadas';
import DataGridPadrao from '../../components/DataGridPadrao';
import { Column, Button as DataGridButton } from 'devextreme-react/data-grid';
import CardAvaliacaoNota from './CardAvaliacaoNota';
import CardAvaliacaoFormulario from './CardAvaliacaoFormulario';
import FormularioPersonalizado from '../../components/FormularioPersonalizado';

// Contextos
import { MensagemContext } from '../../contexts/MensagemContext';

export default class CardMensagemChamado extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mensagem: typeof this.props.mensagem !== 'undefined' ? this.props.mensagem : null,
            mensagemEdicao: typeof this.props.mensagem !== 'undefined' ? this.props.mensagem.mensagem : null,
            anexos: [],
            ...this.propsToObjState(),
            nova: typeof this.props.nova !== 'undefined' ? this.props.nova : false,
            editavel: typeof this.props.editavel !== 'undefined' ? this.props.editavel : false,
            exibirBotaoDownloadAnexos: this.props.exibirBotaoDownloadAnexos ?? false,
            situacao: this.props.situacao ?? null,
            previsaoRetorno: this.props.previsaoRetorno ?? null,
            horasTrabalhadas: this.props.horasTrabalhadas ?? null,
            notificarEnvolvidos: this.props.notificarEnvolvidos ?? true,
            mascaraHorasTrabalhadas: this.props.mascaraHorasTrabalhadas ?? '000:00'
        };
        this.escreverMensagem = this.escreverMensagem.bind(this);
        this.observacaoInterna = this.observacaoInterna.bind(this);
        this.reclassificar = this.reclassificar.bind(this);
        this.encerrar = this.encerrar.bind(this);
        this.avaliar = this.avaliar.bind(this);
        this.reabertura = this.reabertura.bind(this);
        this.confirmar = this.confirmar.bind(this);
        this.cancelar = this.cancelar.bind(this);
        this.solicitarRefresh = this.solicitarRefresh.bind(this);
        this.aoEditarMensagem = this.aoEditarMensagem.bind(this);
        this.aoAlterarTipoExibicaoDataEnvio = this.aoAlterarTipoExibicaoDataEnvio.bind(this);
        this.aoAlterarSituacao = this.aoAlterarSituacao.bind(this);
        this.aoAlterarPrevisaoRetorno = this.aoAlterarPrevisaoRetorno.bind(this);
        this.aoAlterarHorasTrabalhadas = this.aoAlterarHorasTrabalhadas.bind(this);
        this.aoAlterarNotificarEnvolvidos = this.aoAlterarNotificarEnvolvidos.bind(this);
        this.aoAlterarNotaAvaliacao = this.aoAlterarNotaAvaliacao.bind(this);
        this.downloadTodosAnexos = this.downloadTodosAnexos.bind(this);
        this.btnFusaoChamadosClick = this.btnFusaoChamadosClick.bind(this);
        this.btnEncerrarInatividadeClick = this.btnEncerrarInatividadeClick.bind(this);
        this.btnExcluirClick = this.btnExcluirClick.bind(this);
        this.btnAnexarArquivoClick = this.btnAnexarArquivoClick.bind(this);
        this.btnRemoverAnexo = this.btnRemoverAnexo.bind(this);

        this.formularioAvaliacao = {};
        this.formularioAbertura = React.createRef();
        this.formularioAberturaMain = {};
        this.emmitAoAlterarBotaoPressionado();
    }

    propsToObjState() {
        let botaoPressionado = (typeof this.state === 'undefined' || typeof this.state.botaoPressionado === 'undefined') ? (this.props.botaoPressionado ?? null) : null;
        botaoPressionado = this.state && this.state.botaoPressionado ? this.state.botaoPressionado : botaoPressionado;
        const editavel = this.state && this.state.editavel ? this.state.editavel : false;

        let rascunho = {};
        if (this.props.detalhes) {
            let parametroRascunho = App.get_parametro_usuario("chamado_rascunho_" + this.props.detalhes.id);
            if (parametroRascunho) {
                if (typeof parametroRascunho.mensagemEdicao !== 'undefined') {
                    rascunho.mensagemEdicao = parametroRascunho.mensagemEdicao;
                }
                if (typeof parametroRascunho.botaoPressionado !== 'undefined' && botaoPressionado !== 'avaliar') {
                    rascunho.botaoPressionado = this.props.detalhes && this.props.detalhes.rascunho ? 'abertura' : parametroRascunho.botaoPressionado;
                }
                if (typeof parametroRascunho.anexos !== 'undefined') {
                    rascunho.anexos = parametroRascunho.anexos || [];
                }
            }
        }

        return {
            detalhes: this.props.detalhes ?? null,
            botaoPressionado: this.props.detalhes && this.props.detalhes.rascunho ? 'abertura' : botaoPressionado,
            editavel: this.props.detalhes && this.props.detalhes.rascunho ? true : editavel,
            dataEnvioExtenso: this.props.dataEnvioExtenso ?? false,
            chamadoMotivoId: this.props.detalhes ? this.props.detalhes.chamado_motivo_id : null,
            notaAvaliacaoId: this.props.detalhes ? (this.props.detalhes.nota_avaliacao_id ?? this.props.detalhes.avaliacao_padrao_id) : null,
            ...rascunho
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes || prevProps.dataEnvioExtenso !== this.props.dataEnvioExtenso) {
            this.setState(this.propsToObjState());
        }
    }

    componentWillUnmount() {
        if (this.state.nova) {
            App.set_parametro_usuario("chamado_rascunho_" + this.state.detalhes.id, {
                mensagemEdicao: this.state.mensagemEdicao,
                botaoPressionado: this.state.botaoPressionado,
                anexos: this.state.anexos,
            });
        }
    }

    emmitAoAlterarBotaoPressionado() {
        if (this.props.aoAlterarBotaoPressionado) this.props.aoAlterarBotaoPressionado(this.state.botaoPressionado);
    }

    escreverMensagem() {
        this.context.handleSetBotaoPressionado('escreverMensagem');

        this.setState({
            botaoPressionado: 'escreverMensagem',
            editavel: true
        });
        this.emmitAoAlterarBotaoPressionado();
    }

    observacaoInterna() {
        this.context.handleSetBotaoPressionado('observacaoInterna');

        this.setState({
            botaoPressionado: 'observacaoInterna',
            editavel: true
        });
        this.emmitAoAlterarBotaoPressionado();
    }

    reclassificar() {
        this.context.handleSetBotaoPressionado('reclassificar');

        this.setState({
            botaoPressionado: 'reclassificar',
            editavel: true
        });
        this.emmitAoAlterarBotaoPressionado();
    }

    reabertura() {
        this.context.handleSetBotaoPressionado('reabertura');

        this.setState({
            botaoPressionado: 'reabertura',
            editavel: true
        });
        this.emmitAoAlterarBotaoPressionado();
    }

    encerrar() {
        this.context.handleSetBotaoPressionado('encerrar');

        this.setState({
            botaoPressionado: 'encerrar',
            editavel: true
        });
        this.emmitAoAlterarBotaoPressionado();
    }

    avaliar() {
        this.context.handleSetBotaoPressionado('avaliar');

        this.setState({
            botaoPressionado: 'avaliar',
            editavel: true
        });
        this.emmitAoAlterarBotaoPressionado();
    }

    confirmar() {

        let aoSalvar = (response) => {
            window.alert_saved('Mensagem salva com sucesso');

            App.set_parametro_usuario("chamado_rascunho_" + this.state.detalhes.id, {
                mensagemEdicao: null,
                botaoPressionado: null,
                anexos: [],
            });

            this.context.handleSetBotaoPressionado(null);

            this.setState({
                botaoPressionado: null,
                editavel: false,
                processando: false,
                anexos: []
            });
            
            if (this.props.aoSalvar) this.props.aoSalvar(response);
        };
        
        let aoFalhar = (response) => {
            window.alert_fail('Falhou, tente novamente.');
            console.log(response);
            this.setState({
                editavel: true,
                processando: false
            });
        };

        let params = {
            chamado_id: this.state.detalhes.id,
            mensagem: this.state.mensagemEdicao,
            situacao: this.state.situacao ? this.state.situacao : this.state.detalhes.situacao,
            data_previsao_retorno: this.state.previsaoRetorno ? window.formatDateTime(this.state.previsaoRetorno, true) : null,
            documentos: this.state.anexos.map((anexo) => anexo.file),
            enviar_documento: this.state.anexos.map((anexo) => anexo.email.toString()).join(','),
            horas_trabalhadas: this.state.horasTrabalhadas ? this.state.horasTrabalhadas.replace(" ", "0") : null,
            notificacao_mensagem_reclassificacao: this.state.notificarEnvolvidos,
            nota_avaliacao_id: this.state.notaAvaliacaoId ?? null,
            rascunho: false,
            tipo: null
        };

        let urlPost = "mensagem/salvar";
        
        if (this.state.botaoPressionado === 'observacaoInterna') {
            urlPost = "mensagem/salvar_observacao_interna";
        }
        else if (["encerrar", "avaliar"].includes(this.state.botaoPressionado) && this.state.detalhes.exibir_avaliacao === 'S' && +this.state.detalhes.solicitante_id === +App.sessao.usuario_id) {
            if(this.formularioAvaliacao.campos_personalizados){
                if (!this.validar_campos_obrigatorios() || !this.validar_data_form()) {
                    return false;   
                }
            }

            urlPost = "chamado/salvar_avaliacao";
            params = { ...params, campos_personalizados: JSON.stringify(this.formularioAvaliacao.campos_personalizados) };

            if(this.formularioAvaliacao.campos_personalizados){
                $(this.formularioAvaliacao.campos_personalizados.grupos).each(function(index, item) {
                    $(item.elementos).each(function(index2, item2) {
                        if ((item2.tipo === window.ELEMENTO_TIPO.ANEXO) && (item2.anexo)) {
                            params[item2.elem_key] = item2.anexo;
                        }
                        else if ((item2.tipo === window.ELEMENTO_TIPO.TABELA) && (item2.valor)) {
                            $(item2).each(function(index3, item3) {
                                
                                if(item3.valor){
                                    $(item3.valor).each(function(index_anexo, item_anexo) {
                                        params[item_anexo.elem_key + '_linha_' + item_anexo.linha] = item_anexo.anexo;
                                    });
                                }
                            });
                        }
                    });
                });
            }
        }
        else if (this.state.botaoPressionado === 'encerrar') {
            urlPost = "chamado/encerrar_chamado";
        }
        else if (this.state.botaoPressionado === 'reabertura') {
            urlPost = "chamado/reabrir_chamado";
        }
        else if (this.state.botaoPressionado === 'abertura') {
            if (!this.formularioAbertura.current.validar()) {
                return false;   
            }

            urlPost = "mensagem/salvar";

            params = {
                ...params,
                ...this.formularioAbertura.current.getPostParams(),
                tipo: window.TIPO_MENSAGEM_CHAMADO.MENSAGEM_TIPO_ABERTURA
            };
        }
        else if (this.state.botaoPressionado === 'reclassificar') {
            urlPost = "chamado/salvar";
            params = { ...params, ...this.props.reclassificacoesMemoria ?? {} };
        }

        WS.post(
            urlPost,
            params,
            aoSalvar,
            null,
            null,
            true,
            null,
            null,
            null,
            aoFalhar
        );
    }

    cancelar() {
        if (!this.state.detalhes.rascunho) {
            this.context.handleSetBotaoPressionado(null);

            this.setState({
                botaoPressionado: null,
                editavel: false,
                anexos: []
            });
            this.solicitarRefresh();
        }
        else {

            let aoSalvar = (response) => {
                window.alert_saved('Rascunho cancelado.');
                View.unload(this.props.viewHtmlId);
            };
            
            let aoFalhar = (response) => {
                window.alert_fail('Falhou, tente novamente.');
                console.log(response);
                this.setState({
                    editavel: true,
                    processando: false
                });
            };

            window.alertaPopUp(
                "Cancelar", 
                "Deseja cancelar? Este <b>rascunho</b> de chamado será <b>excluído</b> permanentemente.",
                "Sim, excluir este rascunho",
                () => {
                    WS.post({
                        route: "chamado/excluir",
                        data: {
                            chamado_id: this.state.detalhes.id
                        },
                        func_response: aoSalvar,
                        func_fail: aoFalhar
                    });
                },
                "Não, voltar para edição",
                () => {},
                null,
                "200px"
            );
        }

        App.set_parametro_usuario("chamado_rascunho_" + this.state.detalhes.id, {
            mensagemEdicao: null,
            botaoPressionado: null,
            anexos: []
        });

        this.emmitAoAlterarBotaoPressionado();
    }

    solicitarRefresh(chamado) {
        if (this.props.aoSolicitarRefresh) this.props.aoSolicitarRefresh(chamado);
    };

    aoEditarMensagem(mensagem) {
        this.setState({mensagemEdicao: mensagem});
    }

    aoAlterarSituacao(selecionado) {
        this.setState({situacao: selecionado});
    }

    aoAlterarPrevisaoRetorno(selecionado) {
        this.setState({previsaoRetorno: selecionado.value});
    }

    aoAlterarHorasTrabalhadas(selecionado) {
        this.setState({horasTrabalhadas: selecionado.value});
    }

    aoAlterarNotificarEnvolvidos(selecionado) {
        this.setState({notificarEnvolvidos: selecionado.value});
    }

    aoAlterarNotaAvaliacao(selecionado) {
        this.setState({notaAvaliacaoId: selecionado});
    }

    form_get_elemento_by_id(elemento_id) {
        var elemento = { };
        $(this.formularioAvaliacao.campos_personalizados.grupos).each(function(obj_grupo_index, obj_grupo) {
            if (Array.isArray(obj_grupo.elementos)) {
                $(obj_grupo.elementos).each(function(obj_elemento_index, obj_elemento) {
                    if (obj_elemento.id_elemento === elemento_id) {
                        elemento = obj_elemento;
                    }
                });
            }
        });
        elemento = window.spread({nome: ''}, elemento);
        return elemento;
    }

    validar_data_form() {

        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var hoje = now.getFullYear()+"-"+(month)+"-"+(day);
        var campos_obrigatorios = '';
        
        // percorrer campos de data
        this.formularioAvaliacao.form_editor.find('[type="date"]').each(function(i, item) {
            var $item = $(item);

            // se o campo não estiver desabilitado
            if (typeof ($item.attr('disabled') || $item.attr('readonly')) == 'undefined' && $(item).css('display') !== 'none') {

                if(item.value !== '' && item.value != null){

                    if($item.attr('data_retroativa') === 'S'){

                        if(window.get_datepicker($item) < hoje){

                            $item.tooltip({title: 'Data Inválida', placement: 'auto'});
                            $item.tooltip('show');

                            campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                        }else{
                            $item.tooltip('destroy');
                        }

                    }

                    if($item.attr('data_retroativa_igual') === 'S'){

                        if(window.get_datepicker($item) <= hoje){

                            $item.tooltip({title: 'Data Inválida', placement: 'auto'});
                            $item.tooltip('show');

                            campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                        }else{
                            $item.tooltip('destroy');
                        }
                    }
                }
            }

        });

        if(campos_obrigatorios !== ''){
            window.alert_modal('<i class="fa fa-exclamation-triangle pull-left" aria-hidden="true"></i> Data Inválida !',' Data informada fora do período válido, informe corretamente para os campos abaixo: <br /><br /> ' + campos_obrigatorios);
            return false;
        }

        return true;
    }

    validar_campos_obrigatorios() {

        var campos_obrigatorios = '';

        // percorrer campos obrigatorios
        this.formularioAvaliacao.form_editor.find('[required]').not('.selectpicker').each((i, item) => {

            var $item = $(item);

            // Diego Turco - Chamado #3742 @ 19/02/2016
            // Os elementos dos tipos abaixo vai passar a trabalhar com readonly na sua validação,
            // pois essa propriedade permite selecionar o texto do input.
            if ($item.attr('type') === 'text' && typeof $item.attr('maskmoney') == 'undefined') {

                if ((typeof $item.attr('readonly') == 'undefined' && $(item).css('display') !== 'none') || $(item).hasClass('input_consulta_integracao')){

                    // se for um campo de mascara
                    if (typeof $item.attr('maskmoney') !== 'undefined') {
                        // se o valor do campo for vazio, ou o valor sem mascara for zero
                        if ((item.value.trim() === '') || ($item.maskMoney('unmasked')[0] === 0)) {
                            // preparo retorno negativo
                            campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                            // mostrar mensagem de campo obrigatorio
                            this.alerta_campo_obrigatorio($item);
                        }
                    }
                    else {
                        if (item.value.trim() === '') {
                            // preparo retorno negativo
                            campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                            // mostrar mensagem de campo obrigatorio
                            this.alerta_campo_obrigatorio($item);
                        }
                    }

                }else{
                    $item.tooltip('destroy');
                }

            }else if (typeof $item.attr('disabled') == 'undefined' && $(item).css('display') !== 'none') {

                // se for um campo de mascara
                if (typeof $item.attr('maskmoney') !== 'undefined') {
                    // se o valor do campo for vazio, ou o valor sem mascara for zero
                    if (item.value.trim() === "") {
                        // preparo retorno negativo
                        campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                        // mostrar mensagem de campo obrigatorio
                        this.alerta_campo_obrigatorio($item);
                    }
                }else if($item.attr('type') === 'date'
                         && typeof $item.attr('disabled') == 'undefined'
                         && typeof $item.attr('readonly') == 'undefined'){

                    if (item.value.trim() === '') {
                        // preparo retorno negativo
                        campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                        // mostrar mensagem de campo obrigatorio
                        this.alerta_campo_obrigatorio($item);
                    }
                }
                else {
                    if (item.value.trim() === '' && $item.attr('type') !== 'date') {
                        // preparo retorno negativo
                        campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                        // mostrar mensagem de campo obrigatorio
                        this.alerta_campo_obrigatorio($item);
                    }
                }

            }
            else {
                $item.tooltip('destroy');
            }

        });

        // verifico obrigatoriedade em lista tipo checkbox
        this.formularioAvaliacao.form_editor.find('.vld_lista_check').each((i, item) => {

            var $item = $(item);
            
            // Verifica se o campo não está desabilitado
            if (!$item[0]['children'][0]['disabled'] && $item[0]['children'][0]['style']['display'] !== "none") {

                var checks_selecionados = $item.find('input:checked');
                var elem_tooltip = $item.parent().find('.lbl_nome');
                // se não encontrar nada selecionado
                if (!checks_selecionados || checks_selecionados.length === 0) {
                    // mostrar mensagem de campo obrigatorio
                    campos_obrigatorios += this.form_get_elemento_by_id($(item).find("input:first").attr("id_elemento")).nome + "<br />";
                    this.alerta_campo_obrigatorio(elem_tooltip);
                }
                else {
                    elem_tooltip.tooltip('destroy');
                }
            }
        });

        // verifico obrigatoriedade em lista tipo radio
        this.formularioAvaliacao.form_editor.find('.vld_lista_radio').each((i, item) => {
            var $item = $(item);

            // Verifica se o campo não está desabilitado
            if (!$item[0]['children'][0]['disabled'] && $item[0]['children'][0]['style']['display'] !== "none") {

                var radios_selecionados = $item.find('input:checked');
                var elem_tooltip = $item.parent().find('.lbl_nome');
                // se não encontrar nada selecionado
                if (!radios_selecionados || radios_selecionados.length === 0) {
                    // mostrar mensagem de campo obrigatorio
                    campos_obrigatorios += this.form_get_elemento_by_id($(item).find("input:first").attr("id_elemento")).nome + "<br />";
                    this.alerta_campo_obrigatorio(elem_tooltip);
                }
                else{
                    elem_tooltip.tooltip('destroy');
                }
            }
        });

        // verifico obrigatoriedade em lista tipo option com seleção multipla
        this.formularioAvaliacao.form_editor.find('select.selectpicker[required]').each((i, item) => {

            var $item = $(item);

            // Verifica se o campo não está desabilitado
            if (!$item[0]['disabled'] && $item[0]['style']['display'] !== "none") {

                var options_selecionados = $item.val();
                var elem_tooltip = $item.closest('.div_campo').find('.lbl_nome');
                // se não encontrar nada selecionado
                if (!options_selecionados || options_selecionados.length === 0) {
                    // mostrar mensagem de campo obrigatorio
                    campos_obrigatorios += this.form_get_elemento_by_id($(item).attr("id_elemento")).nome + "<br />";
                    this.alerta_campo_obrigatorio(elem_tooltip);
                }
                else {
                    elem_tooltip.tooltip('destroy');
                }
            }
        });

        if(campos_obrigatorios !== ''){
            window.alert_modal('<i class="fa fa-exclamation-triangle pull-left" aria-hidden="true"></i> Campos Obrigatórios !',' Por gentileza, preencha os campos abaixo: <br /><br /> ' + campos_obrigatorios);
            return false;
        }

        return true;
    };

    alerta_campo_obrigatorio($item, placement) {
        $item.tooltip({title: 'Campo obrigatorio', placement: typeof placement != 'undefined' ? placement : 'auto'});
        $item.tooltip('show');
    };

    renderGridAnexos() {
        if (!this.state.anexos || this.state.anexos.length === 0) return false;
        return (
            <DataGridPadrao
                dataSource={this.state.anexos}
                filterPanel={{visible: false}}
                filterRow={{visible: false}}
                headerFilter={{visible: false}}
                groupPanel={{visible: false}}
                export={{enabled: false}}
                allowColumnReordering={false}
                columnChooser={{enabled: false}}
                editing={{
                    mode: "cell",
                    allowUpdating: true
                }}
                keyExpr="id"
                showBorders={false}
                showColumnLines={false}
                columnFixing={{enable: false}}
                rowAlternationEnabled={false}
            >
                <Column dataField="arquivo" allowEditing={false}/>
                <Column dataField="tamanho" allowEditing={false} width={100} />
                <Column dataField="email" width={100} />
                <Column type="buttons" width={50} alignment="right">
                    <DataGridButton hint="Remover anexo" icon="fa fa-times" onClick={this.btnRemoverAnexo} />
                </Column>
            </DataGridPadrao>
        )
    }

    btnRemoverAnexo(e) {
        let anexos = this.state.anexos;
        let novosAnexos = [];
        anexos.forEach((arquivo) => {
            if (e.row.data.file !== arquivo.file) {
                novosAnexos.push(arquivo);
            }
        });

        this.setState({
            anexos: novosAnexos
        });
    }

    downloadTodosAnexos() {
        App.obter_token_publico((token_publico) => {
            App.download(window.WS_URI+"chamado/download/?chamado_id="+this.state.detalhes.id+"&token_publico="+token_publico);
        });
    }

    btnFusaoChamadosClick() {
        View.load('chamado/fusao_chamados', (html_id, fusao_chamados) => {
            const onclose = () => { if (this.props.aoSalvar) this.props.aoSalvar() }
            fusao_chamados.onclose = onclose;
            fusao_chamados.show(this.state.detalhes.id);
        });
    }

    btnEncerrarInatividadeClick() {
        View.load('chamado/encerramento_chamado_inatividade', (html_id, encerramento_chamado_inatividade) => {
            const onclose = () => { if (this.props.aoSalvar) this.props.aoSalvar() }
            encerramento_chamado_inatividade.onclose = onclose;
            encerramento_chamado_inatividade.show(this.state.detalhes.id);
        });
    }

    btnExcluirClick() {
        View.load('chamado/excluir_chamado', (html_id, excluir_chamado) => {
            const onclose = () => {
                View.unload(this.props.viewHtmlId);
            }
            excluir_chamado.onclose = onclose;
            excluir_chamado.show(this.state.detalhes.id);
        });
    }

    btnAnexarArquivoClick() {
        const aoSelecionarArquivos = (e) => {
            const files = Array.from(e.target.files);
            let novosAnexos = [];
            files.forEach((arquivo) => {
                novosAnexos.push({
                    arquivo: arquivo.name,
                    tamanho: window.filesize(arquivo.size),
                    email: false,
                    mimeType: arquivo.type,
                    file: arquivo,
                    id: window.elem_key_random()
                });
            });

            this.setState({
                anexos: [...this.state.anexos, ...novosAnexos]
            });
        }
        
        let arquivo = document.createElement('input');
        arquivo.type = 'file';
        arquivo.multiple = 'multiple';
        arquivo.onchange = aoSelecionarArquivos;
        arquivo.click();
    }

    aoAlterarTipoExibicaoDataEnvio(extenso) {
        if (this.props.aoAlterarTipoExibicaoDataEnvio) {
            this.props.aoAlterarTipoExibicaoDataEnvio(extenso);
        }
    }

    render() {
        let tipo = typeof this.props.mensagem !== 'undefined' ? this.props.mensagem.tipo : null;
        
        if (!tipo) {
            let encerrado = [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.props.chamadoSituacao);
            
            let permiteEncerrar = false;
            if (this.state.detalhes.somente_admin_encerra === 'S' && App.sessao.dados.admin) {
                permiteEncerrar = true;
            }
            else if (this.state.detalhes.somente_solicitante_encerra === 'S' && (App.sessao.usuario_id === +this.state.detalhes.solicitante_id || App.sessao.dados.admin)) {
                permiteEncerrar = true;
            }
            else if (this.state.detalhes.somente_admin_encerra === 'N' && this.state.detalhes.somente_solicitante_encerra === 'N') {
                permiteEncerrar = true;
            }

            if (this.state.botaoPressionado === "encerrar") {
                let mensagemPadraoEncerramento = window.nl2br(this.state.detalhes.mensagem_padrao);
                if (this.state.detalhes.sugerir_mensagem_padrao_colaboradores === "S" && +App.sessao.dados.tipo !== window.USUARIO_TIPO.ANALISTA) {
                    mensagemPadraoEncerramento = null;
                }
                if (!!mensagemPadraoEncerramento && !this.state.mensagemEdicao && this.state.mensagemEdicao !== mensagemPadraoEncerramento) {
                    this.setState({
                        mensagemEdicao: mensagemPadraoEncerramento
                    });
                }
            }
            
            let botoes = [];
            if (App.PE.CardMensagemChamadoTopoBotoesAdicionais) {
                try {
                    let peBotoes = App.PE.CardMensagemChamadoTopoBotoesAdicionais(this.props.viewHtmlId, this.state, this.solicitarRefresh);
                    
                    if (Array.isArray(peBotoes)) {
                        peBotoes.forEach(item => {
                            botoes.push(
                                <ButtonGroupItem
                                    text={item.titulo}
                                    type="default"
                                    onClick={item.click}
                                    visible={true}
                                />
                            );
                        });
                    }
                }
                catch(e) {
                    console.log(e);
                }
            }

            botoes.push(
                <ButtonGroupItem
                    acao="escreverMensagem"
                    icon="fas fa-comment"
                    text="Escrever Mensagem"
                    type={this.state.botaoPressionado === "escreverMensagem" ? "default" : "normal"}
                    onClick={this.escreverMensagem}
                    visible={!encerrado}
                    elementAttr={{
                        class: "btn_escrever_mensagem"
                    }}
                />,
                <ButtonGroupItem
                    acao="observacaoInterna"
                    icon="fas fa-sticky-note"
                    text="Observação Interna"
                    type={this.state.botaoPressionado === "observacaoInterna" ? "default" : "normal"}
                    onClick={this.observacaoInterna}
                    visible={App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA}
                    elementAttr={{
                        class: "btn_observacao_interna"
                    }}
                />,
                <ButtonGroupItem
                    acao="reclassificar"
                    icon="fas fa-tag"
                    text="Reclassificar"
                    type={this.state.botaoPressionado === "reclassificar" ? "default" : "normal"}
                    onClick={this.reclassificar}
                    visible={!encerrado && App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA}
                    elementAttr={{
                        class: "btn_reclassificar"
                    }}
                />,
                <ButtonGroupItem
                    acao="encerrar"
                    icon="fas fa-check"
                    text="Encerrar"
                    type={this.state.botaoPressionado === "encerrar" ? "default" : "normal"}
                    onClick={this.encerrar}
                    visible={!encerrado && permiteEncerrar}
                    elementAttr={{
                        class: "btn_encerrar"
                    }}
                />,
                <ButtonGroupItem
                    acao="avaliar"
                    icon="fas fa-star-half-alt"
                    text="Avaliar"
                    type={this.state.botaoPressionado === "avaliar" ? "success" : "normal"}
                    onClick={this.avaliar}
                    visible={
                        encerrado
                        && this.state.detalhes.exibir_avaliacao === 'S'        
                        && +this.state.detalhes.nota_avaliacao_id === 0
                        && +this.state.detalhes.formulario_chamado_avaliacao_id === 0
                        && +App.sessao.dados.usuario_id === +this.state.detalhes.solicitante_id
                    }
                    elementAttr={{
                        class: "btn_avaliar"
                    }}
                />,
                <ButtonGroupItem
                    acao="reabertura"
                    icon="far fa-thumbs-down"
                    text="Reabrir chamado"
                    type={this.state.botaoPressionado === "reabertura" ? "danger" : "normal"}
                    onClick={this.reabertura}
                    visible={encerrado}
                    elementAttr={{
                        class: "btn_reabertura"
                    }}
                />
            );
            
            return (
                <React.Fragment>
                    <div className="panel ">
                        <div className="panel-body ">
                            <div className="row">
                                <div className="col-xs-12 text-left">
                                    {this.state.nova ?
                                    <Toolbar visible={this.state.detalhes && !this.state.detalhes.rascunho} stylingMode="text" ref={this.toolBarTopoRef}>
                                        <ToolbarItem location="before">  
                                            <ButtonGroup
                                                selectionMode="single"
                                                stylingMode="text"
                                                selectedItemKeys={[this.state.botaoPressionado]}
                                                keyExpr="acao"
                                            >
                                                {botoes}
                                            </ButtonGroup>
                                        </ToolbarItem>
                                        <ToolbarItem locateInMenu="always" widget="dxButton" visible={!encerrado && App.verifica_permissao(App.temp.empresa, "chamado_fusao")}>
                                            <Button
                                                onClick={this.btnFusaoChamadosClick}
                                                text="Fundir outros chamados neste chamado"
                                                icon="fas fa-object-group"
                                                stylingMode="text"
                                            />
                                        </ToolbarItem>
                                        <ToolbarItem locateInMenu="always" widget="dxButton" visible={!encerrado && permiteEncerrar}>
                                            <Button
                                                onClick={this.btnEncerrarInatividadeClick}
                                                text="Encerrar por Inatividade"
                                                icon="fa fa-clock"
                                                stylingMode="text"
                                            />
                                        </ToolbarItem>
                                        <ToolbarItem locateInMenu="always" widget="dxButton" visible={App.sessao.dados.admin}>
                                            <Button
                                                onClick={this.btnExcluirClick}
                                                text="Excluir"
                                                icon="fa fa-trash-alt"
                                                stylingMode="text"
                                            />
                                        </ToolbarItem>
                                        <ToolbarItem location="after">
                                            <Button
                                                onClick={this.downloadTodosAnexos}
                                                hint="Download de todos os anexos"
                                                icon="fa fa-download"
                                                stylingMode="text"
                                                visible={this.state.exibirBotaoDownloadAnexos}
                                            />
                                        </ToolbarItem>
                                    </Toolbar>
                                    : null }
                                    {this.state.botaoPressionado ?
                                    <React.Fragment>
                                        <VerticalSpacer/>
                                        <div>
                                            {this.state.botaoPressionado !== "avaliar" ?
                                            <Mensagem
                                                detalhes={this.state.detalhes}
                                                editavel={true}
                                                chamadoId={this.state.detalhes.id}
                                                aoEditar={this.aoEditarMensagem}
                                                mensagem={this.state.mensagemEdicao}
                                            />
                                            :null}
                                            {!encerrado && App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA && !["observacaoInterna", "reabertura"].includes(this.state.botaoPressionado) ?
                                            <React.Fragment>
                                                <div className="row">
                                                    {["escreverMensagem"].includes(this.state.botaoPressionado) ?
                                                    <div className="col-md-4">
                                                        <CardSituacao
                                                            detalhes={this.state.detalhes}
                                                            style={{padding: '0px'}}
                                                            novaMensagem={true}
                                                            aoAlterar={this.aoAlterarSituacao}
                                                        />
                                                    </div>
                                                    : null }
                                                    {["escreverMensagem"].includes(this.state.botaoPressionado) ?
                                                    <div className="col-md-4">
                                                        <CardPrevisaoRetorno
                                                            style={{padding: '0px'}}
                                                            novaMensagem={true}
                                                            detalhes={this.state.detalhes}
                                                            aoAlterar={this.aoAlterarPrevisaoRetorno}/>
                                                    </div>
                                                    : null }
                                                    {["escreverMensagem", "reclassificar", "encerrar"].includes(this.state.botaoPressionado) ?
                                                    <div className="col-md-4">
                                                        <CardHorasTrabalhadas style={{padding: '0px'}} aoAlterar={this.aoAlterarHorasTrabalhadas} mascaraHorasTrabalhadas={this.state.mascaraHorasTrabalhadas} />
                                                    </div>
                                                    : null }
                                                </div>
                                                <VerticalSpacer/>
                                            </React.Fragment>
                                            :null}
                                            {this.renderGridAnexos()}
                                            <VerticalSpacer/>
                                            {encerrado && this.state.botaoPressionado === "reabertura" ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    O chamado será reaberto, antes de prosseguir, verifique se o assunto do chamado está relacionado com o motivo da reabertura do mesmo, caso contrário realize a abertura de um novo chamado.
                                                    <VerticalSpacer/>
                                                </div>
                                            </div>
                                            :null}
                                            {["encerrar", "avaliar"].includes(this.state.botaoPressionado)
                                                && this.state.detalhes.exibir_avaliacao === 'S'
                                                && (+App.sessao.dados.usuario_id === +this.state.detalhes.solicitante_id || +App.sessao.dados.usuario_id === +this.state.detalhes.usuario_abertura_id) ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {+this.state.detalhes.formulario_avaliacao_id > 0 ?
                                                        <CardAvaliacaoFormulario detalhes={this.state.detalhes} main={this.formularioAvaliacao}/>
                                                        :
                                                        <CardAvaliacaoNota
                                                            style={{padding: '0px'}}
                                                            detalhes={this.state.detalhes}
                                                            aoAlterar={this.aoAlterarNotaAvaliacao}
                                                        /> }
                                                    </div>
                                                </div>
                                                <VerticalSpacer/>
                                            </React.Fragment>
                                            :null}
                                            {["abertura"].includes(this.state.botaoPressionado) ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <FormularioPersonalizado
                                                            ref={this.formularioAbertura}
                                                            main={this.formularioAberturaMain}
                                                            urlGet="formulario_chamado_abertura/get_formulario"
                                                            urlGetParams={{chamado_id: this.state.detalhes.id, motivo_id: this.state.chamadoMotivoId}}
                                                        />
                                                    </div>
                                                </div>
                                                <VerticalSpacer/>
                                            </React.Fragment>
                                            :null}
                                            <Toolbar>
                                                <ToolbarItem location="before">
                                                    <Button
                                                        onClick={this.confirmar}
                                                        text="Confirmar"
                                                        icon="fas fa-check"
                                                        stylingMode="contained"
                                                        type={this.state.botaoPressionado === "encerrar" ? "success" : "default"}
                                                    />
                                                    <Button
                                                        onClick={this.btnAnexarArquivoClick}
                                                        hint="Anexar arquivo"
                                                        icon="fas fa-paperclip"
                                                        stylingMode="text"
                                                        visible={this.state.botaoPressionado !== "avaliar"}
                                                    />
                                                </ToolbarItem>
                                                {!encerrado
                                                    && !this.state.detalhes.rascunho
                                                    && (App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA || App.sessao.dados.admin === true)
                                                    && !["encerrar", "reabertura"].includes(this.state.botaoPressionado)
                                                    && (this.state.detalhes.notificacao_mensagem_reclassificacao_opcional === "S") ?
                                                <ToolbarItem location="before">
                                                    <CheckBox text="Notificar envolvidos" value={this.state.notificarEnvolvidos} onValueChanged={this.aoAlterarNotificarEnvolvidos}/>
                                                </ToolbarItem>
                                                :null}
                                                <ToolbarItem location="after">
                                                    <Button
                                                        onClick={this.cancelar}
                                                        text="Cancelar"
                                                        stylingMode="text"
                                                    />
                                                </ToolbarItem>
                                            </Toolbar>
                                        </div>
                                    </React.Fragment>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        let fotoUsuario = null;
        let nomeUsuario = "";

        if (this.state.nova) {
            fotoUsuario = App.sessao.dados.foto_id;
            nomeUsuario = App.sessao.dados.nome;
        }
        else if (typeof this.state.mensagem !== 'undefined'
            && this.state.mensagem
            && typeof this.state.mensagem.nome_usuario !== 'undefined') {
            fotoUsuario = this.state.mensagem.foto_id;
            nomeUsuario = this.state.mensagem.nome_usuario;
        }

        let fotoThumb = <FotoThumb tamanho="32" documentoId={fotoUsuario} imgAlternativa="assets/img/imagem_usuario_padrao.svg" />;
        let mensagemDoUsuarioLogado = parseInt(App.sessao.dados.usuario_id, 10) === parseInt(this.state.mensagem.usuario_id, 10);

        let classTextUsuario = mensagemDoUsuarioLogado ? "text-right pull-right" : "text-left";
        
        return(
            <div className="panel">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-xs-12 text-left" style={{display: "inline-flex"}}>
                            {!mensagemDoUsuarioLogado ?
                                <React.Fragment>
                                    {fotoThumb}
                                    <HorizontalSpacer/>
                                </React.Fragment>
                            :null}
                            <div style={{width: '100%'}}>
                                <div className={classTextUsuario}>
                                    <Usuario nome={nomeUsuario}/>
                                    <DataEnvio
                                        dataEnvio={typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.data_envio !== 'undefined' ? this.state.mensagem.data_envio : null}
                                        aoAlterarTipoExibicao={this.aoAlterarTipoExibicaoDataEnvio}
                                        extenso={this.state.dataEnvioExtenso}
                                    />
                                    <div className={{}}>
                                        <HorasTrabalhadas HorasTrabalhadas={typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.horas_trabalhadas !== 'undefined' ? this.state.mensagem.horas_trabalhadas : null}/>
                                    </div>
                                </div>
                                {typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.tipo !== 'undefined' ?
                                    <React.Fragment>
                                        <Tipo tipo={this.state.mensagem.tipo}/>
                                        {!mensagemDoUsuarioLogado || parseInt(this.state.mensagem.tipo, 10) !== window.TIPO_MENSAGEM_CHAMADO.MENSAGEM_TIPO_NORMAL ?
                                        <VerticalSpacer/>
                                        : null}
                                    </React.Fragment>
                                : null}
                                <Mensagem
                                    editavel={this.state.editavel}
                                    chamadoId={this.state.detalhes ? this.state.detalhes.id : null}
                                    mensagem={typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.mensagem !== 'undefined' ? this.state.mensagem.mensagem : "Sem mensagem"}
                                    tipo={typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.tipo !== 'undefined' ? this.state.mensagem.tipo : null}
                                    />
                                {typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.observacoes !== 'undefined' ?
                                <React.Fragment>
                                    <VerticalSpacer/>
                                    <Observacoes observacoes={this.state.mensagem.observacoes}/>
                                </React.Fragment>
                                : null }
                                {typeof this.state.mensagem !== 'undefined' && this.state.mensagem && typeof this.state.mensagem.documentos !== 'undefined' && Array.isArray(this.state.mensagem.documentos) && this.state.mensagem.documentos.length > 0 ?
                                <React.Fragment>
                                    <VerticalSpacer/>
                                    <Documentos documentos={this.state.mensagem.documentos}/>
                                </React.Fragment>
                                : null }
                            </div>
                            {mensagemDoUsuarioLogado ?
                                <React.Fragment>
                                    <HorizontalSpacer/>
                                    {fotoThumb}
                                </React.Fragment>
                            :null}
                        </div>
                    </div>
                </div>
            </div>
    )
    }
}
CardMensagemChamado.contextType = MensagemContext;