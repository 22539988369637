import PivotGrid from 'devextreme-react/pivot-grid';

export default class PivotGridPadrao extends PivotGrid {

}

PivotGridPadrao.defaultProps = {
    allowSortingBySummary: false,
    allowSorting: false,
    allowFiltering: true,
    showBorders: true,
    showColumnTotals: false,
    showColumnGrandTotals: false,
    showRowTotals: false,
    showRowGrandTotals: false,
    allowExpandAll: true
}

