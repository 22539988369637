import View from '../api/view';

export let projetoAtividadeColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Atividade",
        icon: "fa fa-pencil-alt",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.load('atividade_projeto/detalhes', function(html_id, atividade_projeto) {
                atividade_projeto.show(e.row.data.projeto_id, e.row.data.id, window.FORMULARIO.EDITAR);
            }, View.ABA.MULTIPLAS);
        }
    }
};

export const projetoAtividadeColunaButtons = {
    type: "buttons",
    buttons: [
        projetoAtividadeColunaButtonVisualizar()
    ]
};

export const projetoAtividadeColunas = [
    { 
        dataField: "projeto_codigo",
        dataType: "number",
        caption: "Projeto",
        width: 100,
    },
    { 
        dataField: "projeto_nome",
        caption: "Projeto Nome",
        minWidth: 100,
    },
    { 
        dataField: "codigo",
        caption: "Cód. Ativ." ,
        minWidth: 100
    },
    { 
        dataField: "atividade_simplificada",
        caption: "Atividade" ,
    },
    {
        dataField: "assunto", 
        caption: "Assunto",
        minWidth: 100
    },
    {
        dataField: "responsavel_nome", 
        caption: "Responsável",
        minWidth: 100
    },
    { 
        dataField: "prazo",
        caption: "Prazo",
        minWidth: 100,
        dataType: "datetime",
        format: "dd/MM/yyyy HH:mm",
        calculateCellValue: function(data) {
            if (data.prazo) {
                return data.prazo.format_date_time();
            }
        },
    },
    { 
        dataField: "conclusao",
        caption: "Conclusão",
        minWidth: 100,
        dataType: "datetime",
        format: "dd/MM/yyyy HH:mm",
        calculateCellValue: function(data) {

            if (data.conclusao) {
                return data.conclusao.format_date_time();
            }
        }
    }
];

