// React
import React, { useState, useEffect } from 'react';

// API
import WS from '../../api/ws';
import App from '../../api/app';

// PivotGrid
import PivotGridPadrao from '../../components/PivotGridPadrao';
import { FieldChooser, FieldPanel, StateStoring, Export } from 'devextreme-react/pivot-grid';

// PivotGrid Chart
import Chart, {
    AdaptiveLayout,
    CommonSeriesSettings,
    Size,
    Tooltip,
} from 'devextreme-react/chart';

// PivotGrid Excel
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportPivotGrid } from 'devextreme/excel_exporter';

// PivotGrid Datasource
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

// Componentes DevExtreme
import DateBox from 'devextreme-react/date-box';
import { Button } from 'devextreme-react/button';

// Estilos
import styles from '../../styles/components/ListagemAlocacoes.module.css';

export default function ListagemAlocacoes(props) {
    App.titulo_aba(props.html_id, 'Alocação de executores');

    const [arrStore, setArrStore] = useState([]);
    const [pivotGridInstance, setPivotGridInstance] = useState(null);
    const [chartInstance, setChartInstance] = useState(null);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    useEffect(function(){
        function initDates() {
            // Iniciar as datas de início e fim com o primeiro e o último dia da semana, respectivamente
            let curr = new Date();
            let first = curr.getDate() - curr.getDay();
            let last = first + 6;
    
            const firstday = new Date((new Date(curr)).setDate(first));
            const lastday = new Date((new Date(curr)).setDate(last));
    
            setDataInicio(firstday);
            setDataFim(lastday);
        }

        initDates();
    }, []); // Realizar uma ação assim que o componente for renderizado

    // Realizar a requisição de listagem das alocações de executores
    useEffect(function(){
        if (!!dataInicio && !!dataFim) {
            getStore();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataInicio, dataFim]); // ... após a definição/alteração das datas

    // Aplicar o gráfico no PivotGrid
    useEffect(function(){
        if (!!pivotGridInstance && !!chartInstance) {
            pivotGridInstance.bindChart(chartInstance, {
                dataFieldsDisplayMode: 'splitPanes',
                alternateDataFields: false
            });

            setTimeout(function(){
                chartInstance.refresh();

                pivotGridInstance._$element.css("height", "calc(100vh - 332px)");
            });
        }
    }, [pivotGridInstance, chartInstance]); // ... após o PivotGrid e o gráfico terem as instâncias iniciadas

    function getStore() {
        WS.get({
            route: "/atividade_projeto/relatorio_alocacao_executores/",
            data: { data_inicio: window.formatDate(dataInicio, true), data_fim: window.formatDate(dataFim, true) },
            func_response: function(res) {
                for(let i=0; i < res.length; i++) {
                    res[i].dia_alocado = new Date(`${res[i].dia_alocado} 23:59:59`);
                }

                setArrStore(res);
            },
            func_fail: function(res) {
            }
        });
    }

    // Export para Excel do PivotGrid (o gráfico não é exportado)
    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Alocacao_Executores');
    
        exportPivotGrid({
            component: e.component,
            worksheet: worksheet
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Alocacao_Executores_${window.formatDate(new Date(), true)}.xlsx`);
            });
        });

        e.cancel = true;
    }

    const dataSource = new PivotGridDataSource({
        fields: [
            {
                caption: 'Executor',
                dataField: 'usuario_executor_nome',
                area: 'row',
                sortBySummaryField: 'HorasAlocadas',
                width: 200,
                isMeasure: false // Não pode ser arrastado até a área 'data' do pivotgrid
            },
            {
                caption: "Projeto",
                dataField: "projeto_nome",
                area: "row"
            },
            {
                caption: 'Horas Alocadas',
                dataField: 'horas_alocadas',
                dataType: 'number',
                summaryType: 'sum',
                area: 'data',
            },
            {
                caption: 'Dia Alocado',
                dataField: 'dia_alocado',
                dataType: 'date',
                area: 'column',
                isMeasure: false
            },
            {
                groupName: 'dia_alocado',
                groupInterval: 'day',
                visible: true
            },
        ],
        store: arrStore,
    });

    return(
        <>
            <div className={styles.filtrosData}>
                <DateBox applyValueMode="useButtons"
                    value={dataInicio}
                    min={new Date(1900, 1, 1)}
                    max={dataFim}
                    onValueChanged={e => setDataInicio(e.value)}
                />

                <DateBox applyValueMode="useButtons"
                    value={dataFim}
                    min={dataInicio}
                    max={new Date(2222, 12, 31)}
                    onValueChanged={e => setDataFim(e.value)}
                />

                <Button icon="refresh"
                    style={{ cursor: 'pointer' }}
                    onClick={() => getStore()}
                />
            </div>

            <div className={styles.pivotChart}>
                <Chart ref={(ref) => setChartInstance(ref ? ref.instance : null)}>
                    <Size height={200} />
                    <Tooltip enabled={true} />
                    <CommonSeriesSettings type="bar" />
                    <AdaptiveLayout width={450} />
                </Chart>
            </div>

            <PivotGridPadrao
                id="pivotgrid_alocao_executores"
                dataSource={dataSource}
                onExporting={onExporting}
                ref={(ref) => setPivotGridInstance(ref ? ref.instance : null)}
            >
                <FieldChooser enabled={false} />
                <FieldPanel
                    showColumnFields={false}
                    showDataFields={false}
                    showFilterFields={false}
                    showRowFields={true}
                    allowFieldDragging={false}
                    visible={true}
                />
                <Export enabled={true} />
                <StateStoring 
                    enabled={true}
                    type="custom"
                    customLoad={() => App.get_parametro_usuario('alocacao_executores_pivotgrid')}
                    customSave={state => App.set_parametro_usuario('alocacao_executores_pivotgrid', state)}
                />
            </PivotGridPadrao>
        </>
    )
}
