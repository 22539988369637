import React from 'react';
import DataGridPadrao from '../components/DataGridPadrao';
import App from '../api/app';
//import WS from '../api/ws';
import { referenciaList, referenciar, excluirReferencia, referenciaNiveis } from '../datasource/Referencias';
import View from '../api/view';
import { VerticalSpacer, HorizontalSpacer } from '../components/Spacer';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import Accordion from 'devextreme-react/accordion';
import { Button } from 'devextreme-react/button';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';

import SelecaoChamado from '../components/SelecaoChamado';
import { chamadoColunas, chamadoColunaButtonVisualizar } from '../datasource/Chamado';
import { solicitacaoColunas, solicitacaoColunaButtonVisualizar } from '../datasource/Solicitacao';
import { solicitacaoAtividadeColunas, solicitacaoAtividadeColunaButtonVisualizar } from '../datasource/SolicitacaoAtividade';
import { projetoAtividadeColunas, projetoAtividadeColunaButtonVisualizar } from '../datasource/ProjetoAtividade';
import { tarefaQuadroListaCartaoColunas, tarefaQuadroListaCartaoColunaButtonVisualizar } from '../datasource/TarefaQuadroListaCartao';
import { oportunidadeColunas, oportunidadeColunaButtonVisualizar } from '../datasource/Oportunidade';
import { documentoColunas, documentoColunaButtonVisualizar } from '../datasource/Documento';
import { pdcaAtividadeColunas, pdcaAtividadeColunaButtonVisualizar } from '../datasource/PdcaAtividade';

import SelecaoSolicitacao from '../components/SelecaoSolicitacao';
import SelecaoSolicitacaoAtividade from '../components/SelecaoSolicitacaoAtividade';
import SelecaoProjeto from '../components/SelecaoProjeto';
import SelecaoProjetoAtividade from '../components/SelecaoProjetoAtividade';

export default class Referencias extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            relacionamentos: [],
            selectedItemKeys: [],
            salvando: false,
            exibirSelecionarAtividadeProjeto: false,
            exibirSelecionarAtividadeProjetoId: null,
            ...this.propsToObjState()
        };

        this.aoAlterarVisualizacaoTabela = this.aoAlterarVisualizacaoTabela.bind(this);
        this.aoAlterarVisualizacaoId = this.aoAlterarVisualizacaoId.bind(this);
        this.aoAlterarVinculoId = this.aoAlterarVinculoId.bind(this);
        this.aoSelecionarChamado = this.aoSelecionarChamado.bind(this);
        this.aoSelecionarSolicitacao = this.aoSelecionarSolicitacao.bind(this);
        this.aoSelecionarSolicitacaoAtividade = this.aoSelecionarSolicitacaoAtividade.bind(this);
        this.aoSelecionarProjeto = this.aoSelecionarProjeto.bind(this);
        this.aoSelecionarProjetoAtividade = this.aoSelecionarProjetoAtividade.bind(this);

        this.aoAlterarVinculoTabela = this.aoAlterarVinculoTabela.bind(this);
        this.confirmarVinculo = this.confirmarVinculo.bind(this);
        this.reiniciar = this.reiniciar.bind(this);
        this.callbackBtns = this.callbackBtns.bind(this);
        this.onClickRemoverReferencia = this.onClickRemoverReferencia.bind(this);
        this.onClickCancelar = this.onClickCancelar.bind(this);
        this.onClickExpandir = this.onClickExpandir.bind(this);
        this.onClickRetrair = this.onClickRetrair.bind(this);
        this.onClickRefresh = this.onClickRefresh.bind(this);
        this.onClickNovoChamado = this.onClickNovoChamado.bind(this);
        this.onClickNovaSolicitacao = this.onClickNovaSolicitacao.bind(this);
        this.onClickNovoDocumento = this.onClickNovoDocumento.bind(this);
        this.onClickNovoDocumentoMultiplo = this.onClickNovoDocumentoMultiplo.bind(this);

        this.obterReferencias  = this.obterReferencias.bind(this);
        this.aoAlterarAcordeaoSelecionado = this.aoAlterarAcordeaoSelecionado.bind(this);
        this.renderTitulo = this.renderTitulo.bind(this);
        this.renderDataGrid = this.renderDataGrid.bind(this);
    }

    propsToObjState() {
        return {
            tabela: this.props.tabela ?? null,
            id: this.props.id ?? null,
            callbackBtns: this.props.callbackBtns ?? null,
            documentoPastaId: this.props.documentoPastaId ?? null,
            entidadeId: this.props.entidadeId ?? null,
            entidadeTipo: this.props.entidadeTipo ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tabela !== this.props.tabela
            || prevProps.id !== this.props.id) {
            this.setState(this.propsToObjState());
        }
    }
    
    componentDidMount() {
        this.obterReferencias();
    }

    obterReferencias() {
        if (this.state.tabela && this.state.id > 0) {
            referenciaList(this.state.tabela, this.state.id).then(list => {
                this.setState({
                    relacionamentos: list,
                    selectedItemKeys: this.state.selectedItemKeys
                });
            });
        }
    }

    aoAlterarVisualizacaoTabela(data) {
        this.setState({tabela: data.value});
        this.obterReferencias();
    }

    aoAlterarVisualizacaoId(data) {
        this.setState({id: data.value});
        this.obterReferencias();
    }

    aoAlterarVinculoTabela(data) {
        this.setState({referenciarTabela: data.value});
    }

    aoAlterarVinculoId(data) {
        this.setState({vincularId: data.value});
    }

    aoSelecionarChamado(chamadoId) {
        this.setState({vincularId: chamadoId});
        this.confirmarVinculo();
    }

    aoSelecionarSolicitacao(solicitacaoId) {
        this.setState({vincularId: solicitacaoId});
        this.confirmarVinculo();
    }

    aoSelecionarSolicitacaoAtividade(solicitacaoAtividadeId) {
        this.setState({vincularId: solicitacaoAtividadeId});
        this.confirmarVinculo();
    }

    aoSelecionarProjeto(projetoId) {
        this.setState({
            exibirSelecionarAtividadeProjeto: true,
            exibirSelecionarAtividadeProjetoId: projetoId
        });
    }

    aoSelecionarProjetoAtividade(projetoAtividadeId) {
        this.setState({vincularId: projetoAtividadeId});
        this.confirmarVinculo();
    }

    confirmarVinculo() {
        if (this.state.referenciarTabela && this.state.referenciarTabela !== ''
            && this.state.vincularId && this.state.vincularId !== '')
        {
            const callbackPostReferenciar = () => {
                this.reiniciar();
                this.obterReferencias();
            };

            const callbackPostFalhou = () => {
                this.reiniciar();
            };

            this.setState({salvando: true, exibirSelecionarAtividadeProjeto: false});
            
            referenciar(
                {
                    tabela_a: this.state.tabela,
                    id_a: this.state.id,
                    tabela_b: this.state.referenciarTabela,
                    id_b: this.state.vincularId,
                },
                callbackPostReferenciar,
                callbackPostFalhou
            );
        }
    }

    aoAlterarAcordeaoSelecionado(e) {
        let novaSelecao = [...this.state.selectedItemKeys];
        e.removedItems.forEach(item => {
            let index = novaSelecao.indexOf(item.tabela);
            if (index >= 0) {
                novaSelecao.splice(index, 1);
            }
        });
        if (e.addedItems.length) {
            novaSelecao = [...novaSelecao, e.addedItems[0].tabela];
        }
        this.setState({
            selectedItemKeys: novaSelecao
        });
    }

    renderTitulo(data) {
        
        let ids = <div>{data.registros.map((registro, i) => {     
            if (i === 10) {
                return (
                    <button key={registro.referencia_id} type="button" className="btn btn-link">...</button>
                );
            }
            else if (i > 10) {
                return null;
            }
            return (
                <button key={registro.referencia_id} type="button" className="btn btn-link" onClick={(e) => this.abrirTituloLinkReferencia(registro, e)}>{this.labelTituloLinkReferencia(registro)} </button>
           )
        })}</div>;
        
        let descricao = data.tabela;
        let dadosTabela = Referencias.tabelas.filter(tabela => tabela.id === data.tabela);
        if (dadosTabela && dadosTabela.length > 0) descricao = dadosTabela[0].descricao;

        return (
            <React.Fragment>
            <div className="pull-right">
                <span className="badge">{data.registros.length}</span>
            </div>
            <div style={{display: 'flex', alignSelf: 'center'}}>
                <div>
                    {descricao}
                </div>
                <HorizontalSpacer/>
                <div>
                    {ids}
                </div>
            </div>
            </React.Fragment>
        );
    }

    renderDataGrid(data) {
        
        switch (data.tabela) {
            case "chamado":
                return this.renderDataGridChamado(data.registros);
            case "oportunidade":
                return this.renderDataGridOportunidade(data.registros);
            case "solicitacao":
                return this.renderDataGridSolicitacao(data.registros);
            case "solicitacao_atividade":
                return this.renderDataGridSolicitacaoAtividade(data.registros);
            case "projeto":
                return this.renderDataGridProjeto(data.registros);
            case "projeto_atividade":
                return this.renderDataGridProjetoAtividade(data.registros);
            case "documento":
                return this.renderDataGridDocumento(data.registros);
            case "agenda_evento":
                return this.renderDataGridAgendaEvento(data.registros);
            case "pdca":
                return this.renderDataGridPdca(data.registros);
            case "pdca_atividade":
                return this.renderDataGridPdcaAtividade(data.registros);
            case "tarefa_quadro_lista_cartao":
                return this.renderDataGridTarefaQuadroListaCartao(data.registros);
            default:
                break;
        }
    }

    callbackBtns() {
        if (this.state.callbackBtns) this.state.callbackBtns();
    }

    onClickRemoverReferencia(e) {  
        const callbackPostExcluirReferencia = () => {
            this.obterReferencias();
        };
        excluirReferencia(
            {
                tabela_a: this.state.tabela,
                id_a: this.state.id,
                tabela_b: e.row.data.referencia_tabela,
                id_b: e.row.data.referencia_id,
            },
            callbackPostExcluirReferencia
        );
    }

    reiniciar() {
        this.setState({vincularId: null, referenciarTabela: null, salvando: false});
    }

    onClickCancelar(e) {
        this.reiniciar();
    }

    onClickRefresh(e) {
        this.obterReferencias();
    }

    onClickExpandir(e) {
        this.setState({selectedItemKeys: this.state.relacionamentos.map(e => e.tabela) });
    }

    onClickRetrair(e) {
        this.setState({selectedItemKeys: [] });
    }

    onClickNovoChamado(e) {
        let onClose = () => {
            this.obterReferencias();
        };

        this.callbackBtns();

        View.loadReact(
            window.views.chamado.Detalhes.default,
            { 
                referenciaTabela: this.state.tabela,
                referenciaId: this.state.id,
                entidadeId: this.state.entidadeId,
                entidadeTipo: this.state.entidadeTipo,
                onClose: onClose,
            },
            (html_id, view) => { },
            View.ABA.MULTIPLAS
        );
        this.reiniciar();
    }

    onClickNovaSolicitacao(e) {
        this.callbackBtns();

        View.load('solicitacao/nova', (html_id, solicitacao_nova) => {
            var onclose = () => {
                this.obterReferencias();
            };

            solicitacao_nova.onclose = onclose;
            solicitacao_nova.referencia_tabela = this.state.tabela;
            solicitacao_nova.referencia_id = this.state.id;
            solicitacao_nova.show();
        });
        this.reiniciar();
    }

    onClickNovoDocumento(e) {
        this.callbackBtns();
        
        View.load('central_documentos/documento', (html_id, detalhes) => {
            detalhes.onclose = () => { 
                this.obterReferencias();
            };
            detalhes.referencia_tabela = this.state.tabela;
            detalhes.referencia_id = this.state.id;
            detalhes.show(null, window.FORMULARIO.NOVO, this.state.documentoPastaId, null, null, null, null, null, null);
        }, View.ABA.MULTIPLAS);

        this.reiniciar();
    }

    onClickNovoDocumentoMultiplo(e) {
        this.callbackBtns();

        View.load("upload/envio_multiplo", (html_id, envio_multiplo) => {
            envio_multiplo.onclose = () => { 
                this.obterReferencias();
            };
            envio_multiplo.referencia_tabela = this.state.tabela;
            envio_multiplo.referencia_id = this.state.id;
            envio_multiplo.show(this.state.documentoPastaId);
        }, View.ABA.SIM);

        this.reiniciar();
    }

    acessarChamado(id) {
        View.loadReact(
            window.views.chamado.Detalhes.default,
            { chamadoId: id },
            function(html_id, view) { },
            View.ABA.MULTIPLAS
        );
    }

    acessarSolicitacao(id) {
        View.load('solicitacao/listar', function(html_id, solicitacao) {
            solicitacao.show(id);
        }, View.ABA.SIM_E_RECARREGAR_SE_ESTIVER_ABERTA);
    }

    acessarSolicitacaoAtividade(id) {
        View.load('solicitacao/detalhes', function(html_id, solicitacao_detalhes) {
            solicitacao_detalhes.show(id, window.FORMULARIO.EDITAR);
        }, View.ABA.MULTIPLAS);
    }

    acessarDocumento(id) {
        View.load('central_documentos/documento', (html_id, central_documentos_arquivo) => {
            central_documentos_arquivo.show(id, window.FORMULARIO.EDITAR);
        }, View.ABA.MULTIPLAS);
    }

    acessarPdca(registro) {
        let pdca = {
            pdca_id: registro.pdca_id,
            numero: registro.pdca_numero,
            data_inicio: registro.pdca_data_inicio,
            data_fim: registro.pdca_data_fim,
            progresso: registro.pdca_progresso,
            situacao: registro.pdca_situacao,
            objetivo: registro.pdca_objetivo
        }

        View.load('pdca/atividades', (html_id, pdca_atividades) => {
            pdca_atividades.show(pdca);	
        }, View.ABA.SIM);
    }

    acessarTarefaQuadroListaCartao(id) {
        View.load('tarefa/detalhes_cartao', (html_id, detalhes) => {
            detalhes.show(id);
        }, View.ABA.MULTIPLAS);
    }

    acessarProjetoAtividade(projetoId, projetoAtividadeId) {
        View.load('atividade_projeto/detalhes', (html_id, atividade_projeto) => {
            atividade_projeto.show(projetoId, projetoAtividadeId, window.FORMULARIO.EDITAR);
        }, View.ABA.MULTIPLAS);
    }

    acessarOportunidade(funilVendasId, oportunidadeId) {
        View.load('oportunidade/detalhes', function(html_id, detalhes) {
            detalhes.show(oportunidadeId, window.FORMULARIO.EDITAR, funilVendasId);
        }, View.ABA.MULTIPLAS);
    }

    abrirTituloLinkReferencia(registro, e) {
        this.callbackBtns();

        switch (registro.referencia_tabela) {
            case 'chamado':
                this.acessarChamado(registro.id);
                break;
            case 'solicitacao':
                this.acessarSolicitacao(registro.id);
                break;
            case 'solicitacao_atividade':
                this.acessarSolicitacaoAtividade(registro.solicitacao_atividade_id);
                break;
            case 'documento':
                this.acessarDocumento(registro.documento_id);
                break;
            case 'pdca_atividade':
                this.acessarPdca(registro);
                break;
            case 'tarefa_quadro_lista_cartao':
                this.acessarTarefaQuadroListaCartao(registro.id);
                break;
            case 'projeto_atividade':
                this.acessarProjetoAtividade(registro.projeto_id, registro.id);
                break;
            case 'oportunidade':
                this.acessarOportunidade(registro.funil_vendas_id, registro.oportunidade_id);
                break;
        
            default:
                break;
        }
    }

    labelTituloLinkReferencia(registro) {
        const label = (texto) => <div className="referencia_titulo_link" title={texto}>{texto}</div>

        switch (registro.referencia_tabela) {
            case 'chamado':
                return label('#' + registro.id + ' ' + registro.assunto);
            case 'solicitacao':
                const solicitacaoId = (App.sessao.dados.parametro_processo.numero_solicitacao_por_processo === "S" ? registro.numero : registro.id);
                return label('#' + solicitacaoId + ' ' + registro.processo_nome);
            case 'solicitacao_atividade':
                return label('#' + registro.solicitacao_id + ' ' + registro.atividade_descricao);
            case 'documento':
                return label(registro.nome);
            case 'pdca_atividade':
                return label('#' + registro.pdca_numero + ' ' + registro.que);
            case 'tarefa_quadro_lista_cartao':
                return label(registro.nome + ' (' + registro.nome_lista + ' / ' + registro.nome_quadro + ')');
            case 'projeto_atividade':
                return label('#' + registro.codigo + ' ' + registro.atividade_simplificada);
            case 'oportunidade':
                return label('#' + registro.oportunidade_id + ' (' + registro.nome + ' / ' + registro.funil + ')');
            default:
                return '';
        }
    }

    colunaReferenciaNivel = {
        dataField: "referencia_nivel",
        caption: "Nível",
        lookup: {
            dataSource: referenciaNiveis,
            displayExpr: "descricao",
            valueExpr: "id"
        },
        width: 130,
        groupIndex: 0
    }

    renderDataGridChamado(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[ 
                        this.colunaReferenciaNivel,
                        ...chamadoColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                chamadoColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridSolicitacao(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    width="100%"
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...solicitacaoColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                solicitacaoColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridSolicitacaoAtividade(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...solicitacaoAtividadeColunas(App.sessao.dados.parametro_processo.numero_solicitacao_por_processo),
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                solicitacaoAtividadeColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridProjeto(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao>    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridProjetoAtividade(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...projetoAtividadeColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                projetoAtividadeColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridDocumento(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...documentoColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                documentoColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridOportunidade(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...oportunidadeColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                oportunidadeColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridAgendaEvento(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao>    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridPdcaAtividade(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...pdcaAtividadeColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                pdcaAtividadeColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridPdca(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao>    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    }

    renderDataGridTarefaQuadroListaCartao(registros) {
        return (
            <React.Fragment>
                <DataGridPadrao
                    dataSource={registros}
                    remoteOperations={false}
                    columns={[
                        this.colunaReferenciaNivel,
                        ...tarefaQuadroListaCartaoColunas,
                        {
                            type: "buttons",
                            fixed: true,
                            fixedPosition: "right",
                            buttons: [
                                tarefaQuadroListaCartaoColunaButtonVisualizar(this.callbackBtns),
                                {
                                    hint: "Remover Referência",
                                    icon: "fas fa-times",
                                    onClick: this.onClickRemoverReferencia,
                                    visible: (e) => e.row.data.referencia_nivel && e.row.data.referencia_nivel === "0"
                                },
                            ]
                        }]}
                >    
                </DataGridPadrao>
                <VerticalSpacer/>
            </React.Fragment>
        );
    };

    render() {
        return(
            <div className="panel">
                <div className="panel-body">
                    {!this.props.tabela && !this.props.id ?
                    <div className="row">
                        <div className="col-xs-12">
                            <div>Visualizar referências de:</div>
                            <div style={{display: 'flex', alignSelf: 'center'}}>
                                <SelectBox
                                    items={Referencias.tabelas}
                                    displayExpr="descricao"
                                    valueExpr="id"
                                    value={this.state.tabela}
                                    onValueChanged={this.aoAlterarVisualizacaoTabela}
                                />
                                <TextBox value={this.state.id} onValueChanged={this.aoAlterarVisualizacaoId} />
                            </div>
                            <VerticalSpacer/>
                        </div>
                    </div>
                    :null}

                    <div className="row">
                        <div className="col-xs-12">
                            <Toolbar>
                                <ToolbarItem location="before">
                                    <div style={{display: 'flex', alignSelf: 'center'}}>
                                        <SelectBox
                                            items={Referencias.tabelas.filter(tabela => tabela.selecaoVisivel)}
                                            displayExpr="descricao"
                                            valueExpr="id"
                                            value={this.state.referenciarTabela}
                                            onValueChanged={this.aoAlterarVinculoTabela}
                                            placeholder="Adicionar referência interna"
                                            disabled={this.state.salvando}
                                            width={250}
                                        />
                                        <HorizontalSpacer/>
                                        {this.state.referenciarTabela === "chamado" ?
                                        <React.Fragment>
                                            <SelecaoChamado aoAlterar={this.aoSelecionarChamado} readOnly={this.state.salvando} width={250} />
                                            <Button type="default" text="Novo Chamado" disabled={this.state.salvando} onClick={this.onClickNovoChamado} />
                                        </React.Fragment>
                                        : null }

                                        {this.state.referenciarTabela === "solicitacao" ?
                                        <React.Fragment>
                                            <SelecaoSolicitacao aoAlterar={this.aoSelecionarSolicitacao} readOnly={this.state.salvando} width={250} />
                                            <Button type="default" text="Nova Solicitação" disabled={this.state.salvando} onClick={this.onClickNovaSolicitacao} />
                                        </React.Fragment>
                                        : null }

                                        {this.state.referenciarTabela === "solicitacao_atividade" ?
                                        <SelecaoSolicitacaoAtividade aoAlterar={this.aoSelecionarSolicitacaoAtividade} readOnly={this.state.salvando} width={250} />
                                        : null }

                                        {this.state.referenciarTabela === "projeto_atividade" ?
                                        <React.Fragment>
                                            <SelecaoProjeto aoAlterar={this.aoSelecionarProjeto} readOnly={this.state.salvando} width={250} />
                                            {this.state.exibirSelecionarAtividadeProjeto === true ?
                                            <SelecaoProjetoAtividade projetoId={this.state.exibirSelecionarAtividadeProjetoId} aoAlterar={this.aoSelecionarProjetoAtividade} readOnly={this.state.salvando} width={250} />
                                            : null }
                                        </React.Fragment>
                                        : null }

                                        {this.state.referenciarTabela === "documento" ?
                                        <React.Fragment>
                                            <Button type="default" text="Novo Documento" disabled={this.state.salvando} onClick={this.onClickNovoDocumento} />
                                            <Button stylingMode="text" text="Envio Múltiplo" disabled={this.state.salvando} onClick={this.onClickNovoDocumentoMultiplo} />
                                        </React.Fragment>
                                        : null }

                                        {this.state.referenciarTabela ?
                                        <React.Fragment>
                                            <HorizontalSpacer/>
                                            <Button text="Cancelar" disabled={this.state.salvando} stylingMode="text" onClick={this.onClickCancelar}/>
                                        </React.Fragment>
                                        : null }

                                    </div>
                                </ToolbarItem>
                                <ToolbarItem location="after">
                                    <Button
                                        onClick={this.onClickExpandir}
                                        hint="Expandir"
                                        icon="fas fa-expand-alt"
                                        stylingMode="text"
                                    />
                                    <Button
                                        onClick={this.onClickRetrair}
                                        hint="Retrair"
                                        icon="fas fa-compress-alt"
                                        stylingMode="text"
                                    />
                                    <Button
                                        onClick={this.onClickRefresh}
                                        hint="Recarregar"
                                        icon="fas fa-sync-alt"
                                        stylingMode="text"
                                    />
                                </ToolbarItem>
                            </Toolbar>
                        </div>
                    </div>
                    <VerticalSpacer/>
                    <Accordion
                        dataSource={this.state.relacionamentos}
                        collapsible={true}
                        multiple={true}
                        itemTitleRender={this.renderTitulo}
                        selectedItemKeys={this.state.selectedItemKeys}
                        onSelectionChanged={this.aoAlterarAcordeaoSelecionado}
                        itemRender={this.renderDataGrid}
                        keyExpr="tabela"
                        noDataText="Não existe nenhuma referência com este registro."
                    />
                </div>
            </div>
        );
    }
}

Referencias.tabelas = [
    /*
    {
        id: 'agenda_evento',
        descricao: 'Agenda Evento'
    },
    */
    {
        id: 'tarefa_quadro_lista_cartao',
        descricao: 'Cartão de Tarefa',
        selecaoVisivel: false
    },
    {
        id: 'chamado',
        descricao: 'Chamado',
        selecaoVisivel: true
    },
    {
        id: 'documento',
        descricao: 'Documento',
        selecaoVisivel: true
    },
    {
        id: 'solicitacao',
        descricao: 'Solicitação de Processo',
        selecaoVisivel: true
    },
    {
        id: 'solicitacao_atividade',
        descricao: 'Atividade de Processo',
        selecaoVisivel: true
    },
    /*
    {
        id: 'projeto',
        descricao: 'Projeto'
    },
    */
    {
        id: 'projeto_atividade',
        descricao: 'Atividade de Projeto',
        selecaoVisivel: true
    },
    /*
    {
        id: 'pdca',
        descricao: 'PDCA'
    },
    */
    {
        id: 'pdca_atividade',
        descricao: 'Atividade de PDCA',
        selecaoVisivel: false
    },
    {
        id: 'oportunidade',
        descricao: 'Oportunidade',
        selecaoVisivel: false
    },
  ];