import DataGrid from 'devextreme-react/data-grid';

export default class DataGridPadrao extends DataGrid {
    /*
    constructor(props) {
        super(props);
    }
    */
}

DataGridPadrao.defaultProps = {
    selection: {
        mode: 'single'
    },
    showBorders: true,
    hoverStateEnabled: true,
    rowAlternationEnabled: false,
    remoteOperations: true,
    filterPanel: {
        visible: true
    },
    filterRow: {
        visible: true, 
        applyFilter: "auto"
    },
    headerFilter: {
        visible: true
    },
    sorting: {
        mode: "multiple"
    },
    groupPanel: {
        visible: true
    },
    columnFixing: {
        enabled: true
    },
    export: {
        enabled: true,
        fileName: 'Exportacao_' + window.agora(),
        allowExportSelectedData: true
    },
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnChooser: {
        enabled: true,
        allowSearch: true,
        mode: window.detectmob() ? "select" : "dragAndDrop"
    }

};