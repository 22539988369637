import React from 'react';
import WS from '../../api/ws';

export default class InformativoSla extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    };

    componentDidMount() {
        if (this.state.autoUpdate) {
            this.timer = setInterval(() => {
                this.atualiza_dados_sla();
            }, 60000); // 1 minuto
        }
    }
    
    componentWillUnmount() {
        if (this.destruirTimer)
            this.destruirTimer();
    }

    propsToObjState() {
        const detalhes = this.props.detalhes ?? null;
        const chamado_id = detalhes ? detalhes.id : null;
        const sla_excedido = detalhes ? detalhes.sla_excedido : null;
        const sla_tempo_sem_resposta = detalhes ? detalhes.tempo_sem_resposta : null;
        const sla_percentual_tempo_sem_resposta = detalhes ? detalhes.percentual_tempo_sem_resposta : null;
        const sla_cor_percentual = detalhes ? detalhes.cor_percentual_sla : null;
        const sla_tempo_restante_resolucao = detalhes ? detalhes.tempo_restante_resolucao : null;
        const sla_dias_resolucao_atendimento = detalhes ? detalhes.dias_resolucao_atendimento : null;
        const sla_dias_restante_resolucao = detalhes ? detalhes.dias_restante_resolucao : null;
        const tipo_gestao_tempo_resolucao = detalhes ? detalhes.tipo_gestao_tempo_resolucao : null;
        return {
            detalhes: this.props.detalhes ?? null,
            chamado_id: chamado_id,
            sla_excedido: sla_excedido,
            sla_percentual_tempo_sem_resposta: sla_percentual_tempo_sem_resposta,
            sla_cor_percentual: sla_cor_percentual,
            sla_tempo_restante_resolucao: sla_tempo_restante_resolucao,
            sla_tempo_sem_resposta: sla_tempo_sem_resposta,
            sla_dias_resolucao_atendimento: sla_dias_resolucao_atendimento,
            sla_dias_restante_resolucao: sla_dias_restante_resolucao,
            sla_tipo_gestao_tempo_resolucao: tipo_gestao_tempo_resolucao,
            ...this.state_label_sla(sla_excedido),
            ...this.state_tempo_aguardando_resolucao(tipo_gestao_tempo_resolucao, sla_tempo_sem_resposta, sla_percentual_tempo_sem_resposta, sla_dias_resolucao_atendimento, sla_dias_restante_resolucao),
            ...this.state_tempo_restante_resolucao(tipo_gestao_tempo_resolucao, sla_tempo_restante_resolucao, sla_dias_restante_resolucao),
            styleWidth: this.props.styleWidth ?? null,
            alinhamento: this.props.alinhamento ?? null,
            autoUpdate: this.props.autoUpdate ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
        if (!this.state.autoUpdate) {
            this.destruirTimer();
        }
    }

    destruirTimer() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    atualiza_dados_sla() {
        if (!this.state.detalhes) return null;
        
        WS.get(
            "chamado/get_dados_sla/",
            { chamado_id: this.state.chamado_id },
            (response) => {
                
                if (!response) { return }

                this.setState({
                    sla_excedido: response.sla_excedido,
                    sla_percentual_tempo_sem_resposta: response.percentual_tempo_sem_resposta,
                    sla_cor_percentual: response.cor_percentual_sla,
                    sla_tempo_restante_resolucao: response.tempo_restante_resolucao,
                    sla_tempo_sem_resposta: response.tempo_sem_resposta,
                    sla_dias_resolucao_atendimento: response.dias_resolucao_atendimento,
                    sla_dias_restante_resolucao: response.dias_restante_resolucao,
                    sla_tipo_gestao_tempo_resolucao: response.tipo_gestao_tempo_resolucao,
                    ...this.state_label_sla(response.sla_excedido),
                    ...this.state_tempo_aguardando_resolucao(response.tipo_gestao_tempo_resolucao, response.tempo_sem_resposta, response.percentual_tempo_sem_resposta, response.dias_resolucao_atendimento, response.dias_restante_resolucao),
                    ...this.state_tempo_restante_resolucao(response.tipo_gestao_tempo_resolucao, response.tempo_restante_resolucao, response.dias_restante_resolucao),
                });
            }
        );
    }

    state_label_sla(sla_excedido) {
        let dados_sla = window.str_sla(sla_excedido);
        return {
            sla_class: dados_sla.classe,
            sla_text: dados_sla.texto
        };
    }

    state_tempo_aguardando_resolucao(sla_tipo_gestao_tempo_resolucao, sla_tempo_sem_resposta, sla_percentual_tempo_sem_resposta, sla_dias_resolucao_atendimento, sla_dias_restante_resolucao) {
        let state = {};
        if (parseInt(sla_tipo_gestao_tempo_resolucao, 10) === window.CHAMADO_MOTIVO_TEMPO.HORAS_UTEIS) {
            state = { txt_tempo_aguardando_resolucao: window.format_time(sla_tempo_sem_resposta) };
            if (parseInt(sla_percentual_tempo_sem_resposta, 10) > 100) {
                state = {
                    ...state,
                    class_tempo_aguardando_resolucao: 'text-danger',
                    class_bar_progress_aguardando: 'progress-bar-danger'
                };
            }
        }
        else if (parseInt(sla_tipo_gestao_tempo_resolucao, 10) === window.CHAMADO_MOTIVO_TEMPO.DIAS_CORRIDOS) {
            if (sla_tempo_sem_resposta <= sla_dias_resolucao_atendimento) {
                state = { txt_tempo_aguardando_resolucao: sla_dias_restante_resolucao ? sla_dias_restante_resolucao+ ' dia' +(Math.abs(sla_dias_restante_resolucao) > 1 ? 's' : '') : '' };
            }
            else{
                let dias_em_atraso = sla_dias_restante_resolucao.substring(0);
                state = {
                    txt_tempo_aguardando_resolucao: dias_em_atraso+' dia'+(Math.abs(dias_em_atraso) > 1 ? 's' : ''),
                    class_tempo_aguardando_resolucao: 'text-danger',
                    class_bar_progress_aguardando: 'progress-bar-danger'
                };
            }
        }
        return state;
    }

    state_tempo_restante_resolucao(sla_tipo_gestao_tempo_resolucao, sla_tempo_restante_resolucao, sla_dias_restante_resolucao) {
        if (parseInt(sla_tipo_gestao_tempo_resolucao, 10) === window.CHAMADO_MOTIVO_TEMPO.HORAS_UTEIS) {
            return { txt_tempo_restante_resolucao: window.format_time(sla_tempo_restante_resolucao) };
        }
        else{
            return { txt_tempo_restante_resolucao: sla_dias_restante_resolucao ? sla_dias_restante_resolucao +' dia'+(Math.abs(sla_dias_restante_resolucao) > 1 ? 's' : '') : '' };
        }
    }

    render() {

        if (this.state.detalhes.utiliza_resolucao_atendimento !== 'S') {
            return null;
        }
        /*
        if (+this.state.detalhes.situacao === window.CHAMADO_SITUACAO.ENCERRADO || +this.state.detalhes.situacao === window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE) {
            return null;
        }
        */

        let divStyle = {fontWeight: 'bold'};

        divStyle.paddingLeft = '0px';
        divStyle.paddingRight = '0px';
        if (this.state.alinhamento === 'left') {
            divStyle.paddingRight = '10px';
        }
        if (this.state.alinhamento === 'right') {
            divStyle.paddingLeft = '10px';
        }

        let tempo_restante_resolucao = (
                (
                    parseInt(this.state.sla_tipo_gestao_tempo_resolucao, 10) === window.CHAMADO_MOTIVO_TEMPO.HORAS_UTEIS &&
                    parseInt(this.state.sla_percentual_tempo_sem_resposta, 10) < 100
                )
                ||
                (
                    parseInt(this.state.sla_tipo_gestao_tempo_resolucao, 10) === window.CHAMADO_MOTIVO_TEMPO.DIAS_CORRIDOS &&
                    this.state.sla_tempo_sem_resposta <= this.state.sla_dias_resolucao_atendimento
                )
            ) ? <span style={divStyle} data-toggle="tooltip" title="Tempo Restante">{window.format_time(this.state.sla_tempo_restante_resolucao)}</span>
            : null;

        let flexFlow = null;
        if (this.state.alinhamento === 'right') {
            flexFlow = 'row-reverse';
        }

        return (
            <div style={{width: this.state.styleWidth}}>
                <div>
                    <div className={this.state.alinhamento === 'right' ? 'text-right' : 'text-left'} style={{whiteSpace: 'nowrap'}}>
                        <span className={this.state.sla_class}>{this.state.sla_text}</span>
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap', flexFlow: flexFlow}}>
                    {tempo_restante_resolucao}
                    <div className="text-left">
                        <span style={{ marginRight: 5 }} className={this.state.class_tempo_aguardando_resolucao} data-toggle="tooltip" title="Tempo em andamento">
                            {this.state.txt_tempo_aguardando_resolucao}
                        </span>
                    </div>
                </div>

                <div>
                    <div className="progress" style={{ marginBottom: 0, height: 12 }}>
                        <div 
                            className={["progress-bar", this.state.class_bar_progress_aguardando].join(' ')}
                            style={{ 
                                width: (parseInt(this.state.sla_percentual_tempo_sem_resposta, 10) < 100 ? parseInt(this.state.sla_percentual_tempo_sem_resposta, 10)+'%' : '100%'), 
                                backgroundColor: this.state.sla_cor_percentual,
                                fontSize: 'xx-small' 
                            }}>

                            <div style={{ 
                                marginTop: -3, 
                                backgroundColor: this.state.sla_cor_percentual
                            }}>
                                {Math.round(this.state.sla_percentual_tempo_sem_resposta) < 100 ? Math.round(this.state.sla_percentual_tempo_sem_resposta)+'%' : '100%'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

InformativoSla.defaultProps = {
    autoUpdate: false,

    styleWidth: '120px',
    alinhamento: 'left',
};