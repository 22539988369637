import WS from '../api/ws';
//import DataSource from 'devextreme/data/data_source';

export let referenciaList = (tabela, id) => {
    let promise = new Promise(function(resolve, reject) {
        WS.get({
            route: "referencia/listar/", 
            data: {
                tabela: tabela,
                id: id
            },
            func_response: function(res) {
                resolve(res)
            },
            /*
            func_fail: function(res) {
                if (res.validation) {
                    reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                }
            }
            */
        });
    });
    return promise;
};

export let referenciar = (parametros, callbackAoSalvar, callbackAoFalhar) => {
    
    const aoSalvar = (response) => {
        window.alert_saved('Relacionamento salvo com sucesso');
        if (callbackAoSalvar) callbackAoSalvar(response);
    };
    
    const aoFalhar = (response) => {
        window.alert_fail('Falhou');
        if (callbackAoFalhar) callbackAoFalhar(response);
    };

    WS.post({
        route: "referencia/salvar",
        data: parametros,
        func_response: aoSalvar,
        func_fail: aoFalhar,
    });
}

export let excluirReferencia = (parametros, callbackAoSalvar, callbackAoFalhar) => {
    
    const aoSalvar = (response) => {
        window.alert_saved('Relacionamento excluido com sucesso');
        if (callbackAoSalvar) callbackAoSalvar(response);
    };
    
    const aoFalhar = (response) => {
        window.alert_fail('Falhou');
        if (callbackAoFalhar) callbackAoFalhar(response);
    };

    WS.post({
        route: "referencia/excluir",
        data: parametros,
        func_response: aoSalvar,
        func_fail: aoFalhar,
    });
}

export const referenciaTabelas = [
    {
        id: 'chamado',
        descricao: 'Chamado'
    },
    {
        id: 'solicitacao',
        descricao: 'Solicitação'
    },
    {
        id: 'solicitacao_atividade',
        descricao: 'Solicitação Atividade'
    },
    {
        id: 'projeto',
        descricao: 'Projeto'
    },
    {
        id: 'projeto_atividade',
        descricao: 'Projeto Atividade'
    },
    {
        id: 'documento',
        descricao: 'Documento'
    },
    {
        id: 'oportunidade',
        descricao: 'Oportunidade'
    },
    {
        id: 'agenda_evento',
        descricao: 'Agenda Evento'
    },
    {
        id: 'pdca',
        descricao: 'PDCA 5W2H'
    },
    {
        id: 'pdca_atividade',
        descricao: 'PDCA 5W2H Atividade'
    },
    {
        id: 'tarefa_quadro_lista_cartao',
        descricao: 'Tarefa Cartão'
    },
];

export const NIVEL = { INTERNO: 0, EXTERNO: 1 };

export const referenciaNiveis = [
    {
        id: NIVEL.INTERNO,
        descricao: 'Interno'
    },
    {
        id: NIVEL.EXTERNO,
        descricao: 'Externo'
    }
]