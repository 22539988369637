// Views
window.views = {};

window.views.chamado = {};
window.views.chamado.Detalhes = require('./chamado/Detalhes.js');

window.views.temas = {};
window.views.temas.SelecionarTema = require('./temas/SelecionarTema.js');

window.views.alocacaoExecutoresProjeto = {};
window.views.alocacaoExecutoresProjeto.ListagemAlocacoes = require('./alocacao_executores_projeto/ListagemAlocacoes');

window.views.fornecedores = {};
window.views.fornecedores.Listagem = require('./fornecedores/Listagem.js');

window.views.prospects = {};
window.views.prospects.Listagem = require('./prospects/Listagem.js');

window.views.clientes = {};
window.views.clientes.Listagem = require('./clientes/Listagem.js');

window.views.modelagemFormulario = {};
window.views.modelagemFormulario.Listagem = require('./modelagem_formulario/Listagem');

window.views.processos = {};
window.views.processos.Listagem = require('./processos/Listagem.js');

// Components
window.components = {};
window.components.SelecaoUsuario = require('../components/SelecaoUsuario.js');
window.components.Referencias = require('../components/Referencias.js');
window.components.MovimentacaoFinanceira = require('./projeto/Listagem.js');
window.components.SelecaoGrupoUsuarios = require('../components/SelecaoGrupoUsuarios.js');
window.components.Cockpit = require('../components/Cockpit');
window.components.Favoritos = require('../components/Favoritos');
window.components.FormularioPersonalizado = require('../components/FormularioPersonalizado');
