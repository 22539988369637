import React from 'react';
import App from '../api/app';

export default class FotoThumb extends React.Component {
        
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    propsToObjState() {
        return {
            documentoId: (typeof this.props.documentoId != 'undefined' ? this.props.documentoId : null),
            documentoRevisaoId: (typeof this.props.documentoRevisaoId != 'undefined' ? this.props.documentoRevisaoId : null),
            imgAlternativa: (typeof this.props.imgAlternativa != 'undefined' ? this.props.imgAlternativa : "assets/img/imagem_usuario_padrao.svg"),
            titulo: (typeof this.props.titulo != 'undefined' ? this.props.titulo : null),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.documentoId !== this.props.documentoId || prevProps.documentoRevisaoId !== this.props.documentoRevisaoId || prevProps.imgAlternativa !== this.props.imgAlternativa) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        let thumb = this.state.imgAlternativa;
        if (this.state.documentoId) {
            thumb = window.WS_URI + 'documento/midia/?tamanho=' + this.props.tamanho + '&documento_id=' + this.state.documentoId + '&token_midia=' + App.sessao.token_midia;
        }
        else if (this.state.documentoRevisaoId) {
            thumb = window.WS_URI + 'documento/midia/?tamanho=' + this.props.tamanho + '&documento_revisao_id=' + this.state.documentoRevisaoId + '&token_midia=' + App.sessao.token_midia;
        }
        return(
            <img src={thumb} height={this.props.tamanho} width={this.props.tamanho} style={{borderRadius: "25em"}} alt="" title={this.state.titulo}/>
        )
    }
}

FotoThumb.defaultProps = {
    tamanho: 28,
    titulo: ''
};