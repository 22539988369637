import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaGrupoUsuariosNome = {
    dataField: "nome",
    caption: "Nome"
};

export const grupoUsuariosColunas = [
    colunaGrupoUsuariosNome
];

export let listarGrupoUsuarios = () => new DataSource({
    paginate: false,
    key: 'grupo_usuarios_id',
    load: (loadOptions) => {
        let dxParams = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "grupo_usuarios/listar/", 
                data: {
                    listar_grupos_sistema: false,
                    ...dxParams,
                },
                func_response: function(res) {
                    resolve({ data: res, key: 'grupo_usuarios_id' })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
});