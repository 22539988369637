import React from 'react';
import TagBox from 'devextreme-react/tag-box';

export default class SelecaoPadraoTagBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selecionado: props.selecionado ? [props.selecionado] : null,
            placeholder: props.placeholder ?? "",
            dataSource: props.dataSource ?? "",
            readOnly: props.readOnly ?? false,
            displayExpr: props.displayExpr ?? null,
            valueExpr: props.valueExpr ?? null,
        };

        this.tagBoxRef = React.createRef();
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    get tagBox() {
        return this.tagBoxRef.current ? this.tagBoxRef.current.instance : null;
    }

    render() {
        return (
            <TagBox
                showSelectionControls={true}
                applyValueMode="useButtons"
                ref={this.tagBoxRef}
                dataSource={this.state.dataSource}
                displayExpr={this.state.displayExpr}
                valueExpr={this.state.valueExpr}
                value={this.state.selecionado}
                placeholder={this.props.placeholder}
                readOnly={this.state.readOnly}
                onValueChanged={this.onValueChanged}
                deferRendering={false}
            />
        );
        }

    onValueChanged(e){
        this.setState({
            selecionado: e.value,
        });

        if (this.props.aoAlterar) {
            let selecionado = this.state.selecionado ??  null;
            this.props.aoAlterar(selecionado);
        }
    }
}

SelecaoPadraoTagBox.defaultProps = {
    dataSource: null,
    placeholder: "Pesquisar",
    displayExpr: null,
    readOnly: false,
    aoAlterar: null,
};