import React from 'react';
import DataGridPadrao from '../../../src/components/DataGridPadrao';
import { clienteColunas, listarClientes, stateStoring } from '../../datasource/Cliente';
import WS from '../../api/ws';

class ListagemCliente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clienteLogo: '',
            clienteCodigo: '',
            clienteUnidade: '',
            clienteNomeFantasia: '',
            clienteRazaoSocial: '',
            clienteTelefone1: '',
            clienteTelefone2: '',
            clienteTelefone3: '',
            clienteTelefone4: '',
            clienteParametros: null,
        };

        this.getParametrosCliente = this.getParametrosCliente.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }

    getParametrosCliente(dataGrid, repaintGrid=false) {
        let _this = this;

        WS.get(
            "cliente/get_parametros/",
            null,
            function(response) {
                _this.setState({ clienteParametros: response }, () => {
                    if (repaintGrid) {
                        dataGrid.component.repaint();
                    }
                });
            }
        );
    }

    componentDidMount() {
        this.getParametrosCliente();
    }

    onToolbarPreparing(e){
        let _this = this;

        const arr_botoes = [
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-plus",
                    "hint":"Novo cliente",
                    "text":"Novo cliente",
                    onClick: function(){
                        window.View.load('cliente/detalhes', function(html_id, cliente_detalhes) {
                            cliente_detalhes.onclose = e.component.refresh;
                            cliente_detalhes.show(null, window.FORMULARIO.NOVO);
                        }, window.View.ABA.MULTIPLAS);
                    },
                    "visible": window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'cliente') && this.state.clienteParametros && this.state.clienteParametros.somente_consulta_cliente === "N"
                },
                "location":"after",
                "name":"BtnNovoCliente",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-sync",
                    "hint":"Recarregar listagem de clientes",
                    "text":"Recarregar clientes",
                    onClick: function(){
                        this.listarClientes = e.component.refresh();

                    }
                },
                "showText":"inMenu",
                "location":"after",
                "name":"BtnRefresh",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-cog",
                    "hint":"Configurações",
                    onClick: function(){
                        window.View.load('cliente/configuracoes', function(html_id, cliente_configuracoes) {
                            cliente_configuracoes.onclose = function() {
                                _this.getParametrosCliente(e, true);
                            };
                            
                            cliente_configuracoes.show();
                        });
                    },
                    "visible": window.App.sessao.dados.admin
                },
                "location":"after",
                "name":"BtnConfiguracoes",
                "locateInMenu":"auto"
            },
            
        ];
        e.toolbarOptions.items = window.add_botao_dx_toolbar(arr_botoes, e.toolbarOptions.items);
    }

    render() {

        return (
            this.state.clienteParametros !== null &&
            (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <DataGridPadrao style={{height: "calc(100vh - 46px)"}}
                                onToolbarPreparing={this.onToolbarPreparing}
                                dataSource={
                                    listarClientes({
                                        dx_grid: true,
                                    })
                                }
                                stateStoring={stateStoring}
                                columns={clienteColunas(this.state.clienteParametros)}
                            >
                            </DataGridPadrao>
                        </div>
                    </div>
                </React.Fragment>
            )
        );
    }
}

ListagemCliente.defaultProps = {
    abaTitulo: "Clientes"
};

export default ListagemCliente;