import React from 'react';
import App from '../../api/app';

import CardMotivo from './CardMotivo';
import CardGrupoMotivo from './CardGrupoMotivo';
import CardEntidade from './CardEntidade';
import CardSolicitante from './CardSolicitante';
import CardResponsavel from './CardResponsavel';
import CardPrevisaoRetorno from './CardPrevisaoRetorno';
import CardPrioridade from './CardPrioridade';
import CardAvaliacao from './CardAvaliacao';
import CardEmailsAdicionais from './CardEmailsAdicionais';
import CardTotalHorasTrabalhadas from './CardTotalHorasTrabalhadas';
import CardClassificacao from './CardClassificacao';
import CardSituacao from './CardSituacao';

export default class CardReclassificacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.solicitarRefresh = this.solicitarRefresh.bind(this);
        this.reclassificacaoMemoria = this.reclassificacaoMemoria.bind(this);
        this.reclassificacaoMemoriaEntidade = this.reclassificacaoMemoriaEntidade.bind(this);
        this.reclassificacaoMemoriaSolicitante = this.reclassificacaoMemoriaSolicitante.bind(this);
        this.reclassificacaoMemoriaGrupoMotivo = this.reclassificacaoMemoriaGrupoMotivo.bind(this);
        this.reclassificacaoMemoriaMotivo = this.reclassificacaoMemoriaMotivo.bind(this);
        this.reclassificacaoMemoriaResponsavel = this.reclassificacaoMemoriaResponsavel.bind(this);
        this.reclassificacaoMemoriaClassificacao = this.reclassificacaoMemoriaClassificacao.bind(this);
        this.reclassificacaoMemoriaPrioridade = this.reclassificacaoMemoriaPrioridade.bind(this);
        this.reclassificacaoMemoriaPrevisaoRetorno = this.reclassificacaoMemoriaPrevisaoRetorno.bind(this);
        this.reclassificacaoMemoriaSituacao = this.reclassificacaoMemoriaSituacao.bind(this);
        this.reclassificacaoMemoriaEmailsAdicionais = this.reclassificacaoMemoriaEmailsAdicionais.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes
            || prevProps.botaoPressionadoReclassificacao !== this.props.botaoPressionadoReclassificacao
            || prevProps.colaboradorPodeAlterarSolicitante !== this.props.colaboradorPodeAlterarSolicitante
            || !window.isEquivalent(prevProps.reclassificacoesMemoria, this.props.reclassificacoesMemoria)) {
            this.setState(this.propsToObjState());
        }
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            botaoPressionadoReclassificacao: this.props.botaoPressionadoReclassificacao ?? null,
            colaboradorPodeAlterarSolicitante: this.props.colaboradorPodeAlterarSolicitante ?? false,
            reclassificacoesMemoria: this.props.reclassificacoesMemoria ?? null,
        };
    }

    solicitarRefresh(chamado) {
        if (this.props.aoSolicitarRefresh) this.props.aoSolicitarRefresh(chamado);
    };

    reclassificacaoMemoria(parametros) {

        this.setState(state => {
            let novaReclassificacoesMemoria = { ...state.reclassificacoesMemoria, ...parametros };
            return {
                reclassificacoesMemoria: novaReclassificacoesMemoria
            };
        });
        if (this.props.aoAlterarReclassificacoesMemoria) {
            this.props.aoAlterarReclassificacoesMemoria(this.state.reclassificacoesMemoria);
        }
    }

    reclassificacaoMemoriaEntidade(selecionado) {
        this.reclassificacaoMemoria({ 'entidade_tipo': selecionado.tipo, 'entidade_id': selecionado.id });
    }

    reclassificacaoMemoriaSolicitante(selecionado) {
        this.reclassificacaoMemoria({ 'usuario_solicitante_id': selecionado });
    }

    reclassificacaoMemoriaGrupoMotivo(selecionado) {
        this.reclassificacaoMemoria({ 'grupo_motivo_id': selecionado });
    }

    reclassificacaoMemoriaMotivo(selecionado, selecionadoPropriedades) {
        let memoria = { 'chamado_motivo_id': selecionado };
        if (selecionadoPropriedades) {
            memoria = {
                ...memoria,
                chamado_motivo_horas_uteis: selecionadoPropriedades.horas_uteis,
                chamado_motivo_tipo_gestao_tempo: selecionadoPropriedades.tipo_gestao_tempo,
                chamado_motivo_solicitante_pode_alterar_previsao_retorno: selecionadoPropriedades.solicitante_pode_alterar_previsao_retorno,
                chamado_motivo_altera_solicitante: selecionadoPropriedades.altera_solicitante === 'S',
            }
        }

        this.reclassificacaoMemoria(memoria);
    }

    reclassificacaoMemoriaResponsavel(selecionado) {
        this.reclassificacaoMemoria({ 'usuario_responsavel_id': selecionado });
    }

    reclassificacaoMemoriaClassificacao(selecionado) {
        this.reclassificacaoMemoria({ 'classificacao_chamado_id': selecionado });
    }

    reclassificacaoMemoriaPrioridade(selecionado) {
        this.reclassificacaoMemoria({ 'chamado_prioridade_id': selecionado });
    }

    reclassificacaoMemoriaPrevisaoRetorno(selecionado) {
        let valorSelecionado = null;
        if (selecionado && selecionado.value) {
            valorSelecionado = window.formatDateTime(selecionado.value, true);
        }
        this.reclassificacaoMemoria({ 'data_previsao_retorno': valorSelecionado });
    }

    reclassificacaoMemoriaSituacao(selecionado) {
        this.reclassificacaoMemoria({ 'situacao': selecionado });
    }

    reclassificacaoMemoriaEmailsAdicionais(selecionado) {
        this.reclassificacaoMemoria({ 'emails_adicionais': selecionado });
    }

    render(){
        return(
            <div>
                <div className="panel panel-default card-dados-chamado">
                    <div className="list-group">
                        <CardEntidade
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaEntidade : this.solicitarRefresh}
                        />
                        <CardSolicitante
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaSolicitante : this.solicitarRefresh}
                            colaboradorPodeAlterarSolicitante={this.state.colaboradorPodeAlterarSolicitante}
                        />
                        { App.sessao.dados.parametro_chamado.chk_exibir_na_abertura_grupo_motivo_chamado === 'S' ?
                        <CardGrupoMotivo
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaGrupoMotivo : this.solicitarRefresh}
                        />
                        : null }
                        <CardMotivo
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaMotivo : this.solicitarRefresh}
                        />
                        { !this.state.detalhes.rascunho || App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA ?
                        <CardResponsavel
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaResponsavel : this.solicitarRefresh}
                        />
                        : null }
                        { App.sessao.dados.parametro_chamado.chamado_classificacoes === 'S' ?
                        <CardClassificacao
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaClassificacao : this.solicitarRefresh}
                        />
                        : null }
                        <CardPrioridade
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaPrioridade : this.solicitarRefresh}
                        />
                        { !this.state.detalhes.rascunho || App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA ?
                        <CardPrevisaoRetorno
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaPrevisaoRetorno : this.solicitarRefresh}
                        />
                        : null }
                        { !this.state.detalhes.rascunho || App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA ?
                        <CardSituacao
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaSituacao : this.solicitarRefresh}
                        />
                        : null }
                    </div>
                </div>    
                <div className="panel panel-default card-dados-chamado">
                    <div className="list-group">
                        <CardEmailsAdicionais
                            detalhes={this.state.detalhes}
                            novaMensagem={this.state.botaoPressionadoReclassificacao}
                            reclassificacoesMemoria={this.state.reclassificacoesMemoria}
                            aoAlterar={this.state.botaoPressionadoReclassificacao ? this.reclassificacaoMemoriaEmailsAdicionais : this.solicitarRefresh}
                        />
                    </div>
                </div>
                { !this.state.detalhes.rascunho ?
                <div className="panel panel-default card-dados-chamado">
                    <div className="list-group">
                        { this.state.detalhes.nota_avaliacao_id ?
                        <CardAvaliacao detalhes={this.state.detalhes} />
                        : null }
                        {this.props.detalhes && 
                            this.props.detalhes.horas_trabalhadas !== '' 
                            && this.props.detalhes.horas_trabalhadas !== null
                            && typeof this.props.detalhes.horas_trabalhadas !== 'undefined' 
                            && this.props.detalhes.horas_trabalhadas !== '00:00:00'
                            && (App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA || App.sessao.dados.admin === true) ?
                        <CardTotalHorasTrabalhadas
                            valor={this.props.detalhes.horas_trabalhadas}
                        />
                        : null}                        
                    </div>
                </div>
                : null }
            </div>
        )
    }
}