import React from 'react';
import DataGridPadrao from '../../../src/components/DataGridPadrao';
import { processoColunas, listarProcessos, stateStoring } from '../../datasource/Processo';

class ListagemProcesso extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processoId: '',
            processoNome: '',
            processoCategoria: '',
            processoAtivo: '',
            processoVersao: '',
            processoPublicacao: '',
            processoSolicitacoes: ''
        };
    }

    onToolbarPreparing(e){
        const arr_botoes = [
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-plus",
                    "hint":"Novo processo",
                    "text":"Novo processo",
                    onClick: function(){
                        window.View.load('processo/detalhes', function(html_id, processo_detalhes) {
                            processo_detalhes.onclose = e.component.refresh;
                            processo_detalhes.show(null, window.FORMULARIO.NOVO);
                        }, window.View.ABA.MULTIPLAS);
                    }
                },
                "location":"after",
                "name":"BtnNovoProcesso",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-upload",
                    "hint":"Importar Processo",
                    // "text":"Importar",
                    onClick: function(){
                        window.View.load('processo/importacao', function(html_id, processo_importacao) {
                            processo_importacao.onclose = e.component.refresh;
                            processo_importacao.show();
                        });
                    },
                },
                "location":"after",
                "name":"BtnImportarProcesso",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-sync",
                    "hint":"Recarregar listagem de processos",
                    "text":"Recarregar processos",
                    onClick: function(){
                        this.listarProcessos = e.component.refresh();

                    }
                },
                "showText":"inMenu",
                "location":"after",
                "name":"BtnRefresh",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-cog",
                    "hint":"Configurações",
                    //"text":"Configurações",
                    onClick: function(){
                        window.View.load('processo/configuracoes', function(html_id, processo_configuracoes) {
                            processo_configuracoes.onclose = onclose;
                            processo_configuracoes.show();
                        });
                    },
                    "visible": window.App.sessao.dados.admin
                },
                "location":"after",
                "name":"BtnConfiguracoes",
                "locateInMenu":"auto"
            },
            
        ];
        e.toolbarOptions.items = window.add_botao_dx_toolbar(arr_botoes, e.toolbarOptions.items);
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <DataGridPadrao 
                            style={{height: "calc(100vh - 46px)"}}
                            onToolbarPreparing={this.onToolbarPreparing}
                            dataSource={
                                listarProcessos({
                                    dx_grid: true
                                })
                            }
                            stateStoring={stateStoring}
                            columns={processoColunas}
                            export={{ enabled: true, fileName: 'Listagem_Processos_' + window.agora(), allowExportSelectedData: false }}
                        >
                        </DataGridPadrao>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ListagemProcesso.defaultProps = {
    abaTitulo: "Processos"
};

export default ListagemProcesso;