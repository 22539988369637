import { createContext, useState } from 'react';

export const MensagemContext = createContext({});

export function MensagemProvider({ children }) {
    const [botaoPressionado, setBotaoPressionado] = useState(null);

    function handleSetBotaoPressionado(value) {
        setBotaoPressionado(value);
    }

    return(
        <MensagemContext.Provider value={{
            botaoPressionado,
            handleSetBotaoPressionado,
        }}>
            { children }
        </MensagemContext.Provider>
    );
}
