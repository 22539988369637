import View from '../api/view';

export let oportunidadeColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Oportunidade",
        icon: "fa fa-pencil-alt",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.load('oportunidade/detalhes', function(html_id, detalhes) {
                detalhes.show(e.row.data.oportunidade_id, window.FORMULARIO.EDITAR, e.row.data.funil_vendas_id);
            }, View.ABA.MULTIPLAS);
        }
    }
};

export const oportunidadeColunaButtons = {
    type: "buttons",
    buttons: [
        oportunidadeColunaButtonVisualizar()
    ]
};

export const oportunidadeColunas = [
    {
        dataField: "id",
        caption: "#",
        dataType: "number",
        alignment: "right",
        width: 80
    },
    {
        dataField: "funil_vendas_etapa_nome",
        caption: "Etapa do Funil",
        dataType: "string",
    },
    {
        dataField: "nome",
        caption: "Nome",
        dataType: "string",
    },
    {
        dataField: "data_abertura",
        caption: "Data de inclusão",
        dataType: "datetime"
    },
    {
        caption: "Tipo de Entidade",
        dataType: "string",
        calculateCellValue: function(data) {
            var txt_tipo_situacao = "";

            switch(data.entidade_tipo) {
                case 'C':
                    txt_tipo_situacao = "Cliente";
                    break;
                case 'O':
                    txt_tipo_situacao = "Organização";
                    break;
                default:
                    break;
            }

            return txt_tipo_situacao;
        }
    },
    {
        dataField: "entidade_nome",
        caption: "Entidade",
        dataType: "string"
    },
    {
        dataField: "representante_nome",
        caption: "Representante",
        dataType: "string"
    },
    {
        dataField: "status",
        caption: "Status",
        dataType: "string"
    },
    {
        caption: "Valor do Negócio R$",
        dataType: "string",
        calculateCellValue: function(data) {
            //return 'R$ '+data.valor_negocio.format_number(2, true);
        }
    },
    {
        dataField: 'situacao',
        caption: "Situação da Oportunidade",
        dataType: "string",
        calculateCellValue: function(data) {
            var txt_situacao_oportunidade = "";

            switch(data.situacao) {
                case 'G':
                    txt_situacao_oportunidade = 'Ganho';
                    break;
                case 'P':
                    txt_situacao_oportunidade = 'Perdido';
                    break;
                case 'A':
                    txt_situacao_oportunidade = 'Aberto';
                    break;
                case 'AT':
                    txt_situacao_oportunidade = 'Atrasado';
                    break;
                case 'E':
                    txt_situacao_oportunidade = 'Estagnado';
                    break;
                default:
                    break;
            }

            return txt_situacao_oportunidade;
        }
    },
    {
        dataField: 'situacao_ultimo_orcamento',
        caption: "Situação do Orçamento",
        dataType: "string",
        calculateCellValue: function(data) {
            var txt_situacao_orcamento = "";

            if (data.situacao_ultimo_orcamento) {

                switch(data.situacao_ultimo_orcamento) {
                    case '0':
                        txt_situacao_orcamento = 'Orçamento Aguardando Aprovação';
                        break;
                    case '1':
                        txt_situacao_orcamento = 'Orçamento Aprovado';
                        break;
                    case '2':
                        txt_situacao_orcamento = 'Orçamento Reprovado';
                        break;
                    case '4':
                        txt_situacao_orcamento = 'Orçamento Aprovado / Aguardando E-Mail';
                        break;
                    default:
                        txt_situacao_orcamento = 'Orçamento Em Análise';
                        break;
                }
            }

            return txt_situacao_orcamento;
        }
    },
];