import View from '../api/view';

export let pdcaAtividadeColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Atividades PDCA",
        icon: "folder",
        onClick: function(e) {
            var pdca = {
                pdca_id: e.row.data.pdca_id,
                numero: e.row.data.pdca_numero,
                data_inicio: e.row.data.pdca_data_inicio,
                data_fim: e.row.data.pdca_data_fim,
                progresso: e.row.data.pdca_progresso,
                situacao: e.row.data.pdca_situacao,
                objetivo: e.row.data.pdca_objetivo
            }

            if (callbackBtns) callbackBtns();
            View.load('pdca/atividades', (html_id, pdca_atividades) => {
                pdca_atividades.show(pdca);	
            }, View.ABA.SIM);
        }
    }
};

export const pdcaAtividadeColunaButtons = {
    type: "buttons",
    buttons: [
        pdcaAtividadeColunaButtonVisualizar()
    ]
};

export const pdcaAtividadeColunas = [
    { 
        dataField: "pdca_numero",
        dataType: "number",
        caption: "#",
        width: 100,
    },
    { 
        dataField: "pdca_objetivo",
        caption: "PDCA",
        calculateCellValue: function(data) {
            let span = document.createElement('span');
            span.innerHTML = data.pdca_objetivo;
            return span.textContent || span.innerText;
        },
    },
    { 
        dataField: "que",
        caption: "Atividade - O Quê?",
    }
];