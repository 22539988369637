import App from '../api/app';
import WS from '../api/ws';
import View from '../api/view';
import DataSource from 'devextreme/data/data_source';

export let solicitacaoAtividadeColunas = (numero_solicitacao_por_processo) => {
    return [
        { 
            dataField: numero_solicitacao_por_processo === "S" ? "numero" : "solicitacao_id",
            dataType: "number",
            caption: "#",
            width: 100,
        },
        { 
            dataField: "processo_nome",
            caption: "Processo",
            minWidth: 100,
        },
        { 
            dataField: "atividade_descricao",
            caption: "Atividade" ,
            minWidth: 100
        },
        {
            dataField: "usuario_solicitacao_nome",
            caption: 'Solicitante',
            cellTemplate: function (container, options) {
                var imagem_id = options.data.usuario_solicitante_foto_id;
                var link_imagem = imagem_id ? window.WS_URI+'documento/midia/?&tamanho=20&documento_id='+imagem_id+'&token_midia='+App.sessao.token_midia : 'assets/img/imagem_usuario_padrao.svg';
                var nome_solicitante = options.data.usuario_solicitacao_nome;
                container.html(
                    '<div style="margin-top: 3px;">' +
                        '<img style="height: 20px; width: 20px" class="imagem_responsavel" src="'+ link_imagem +'">' +
                        '<span style="margin-left: 5px;">'+ nome_solicitante +'</span>' +
                    '</div>'
                );
            },
            width: 100
        },
        {
            dataField: "usuario_responsavel_nome",
            dataType: 'string',
            caption: 'Responsável',
            width: 100
        },
        { 
            dataField: "data",
            caption: "Início Atividade",
            minWidth: 100,
            dataType: "datetime",
            format: "dd/MM/yyyy HH:mm",
            calculateCellValue: function(data) {

                if (data.data) {
                    return data.data.format_date_time();
                }
            },
        },
        { 
            dataField: "prazo_execucao",
            caption: "Prazo",
            minWidth: 100,
            dataType: "datetime",
            format: "dd/MM/yyyy HH:mm",
            calculateCellValue: function(data) {

                if (data.prazo_execucao) {
                    return data.prazo_execucao.format_date_time();
                }
            }
        },
        {
            dataField: "situacao",
            caption: "Situação da Atividade",
            minWidth: 120,
            lookup: {
                dataSource: [
                    {
                        "id": "1",
                        "descricao": "Concluída"
                    }, 
                    {
                        "id": "5",
                        "descricao": "Retornada"
                    },
                    {
                        "id": "9",
                        "descricao": "Cancelada"
                    },
                    {
                        "id": "4",
                        "descricao": "Não entregue"
                    },
                    {
                        "id": "0",
                        "descricao": "Andamento"
                    },
                    {
                        "id": "3",
                        "descricao": "Atrasada"
                    }
                ],
                displayExpr: "descricao",
                valueExpr: "id"
            } 
        },
    ];
};

export let solicitacaoAtividadeColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Atividade",
        icon: "fa",
        cssClass: "icon_solicitacao",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.load('solicitacao/detalhes', function(html_id, solicitacao_detalhes) {
                solicitacao_detalhes.show(e.row.data.solicitacao_atividade_id, window.FORMULARIO.EDITAR);
            }, View.ABA.MULTIPLAS);
        }
    };
}

export const solicitacaoAtividadeColunaButtons = {
    type: "buttons",
    buttons: [
        solicitacaoAtividadeColunaButtonVisualizar()
    ]
};

export let solicitacaoAtividadeList = (situacao, solicitacaoAtividadeId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'solicitacao_atividade_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "solicitacao/listar_todas/",
                data: {
                    dx_grid: true,
                    situacao: situacao,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "solicitacao/listar_todas/",
                data: {
                    dx_grid: true,
                    situacao: window.SOLICITACAO_FILTROS.TODAS,
                    solicitacao_atividade_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});