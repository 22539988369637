import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaProcessoId = {
    dataField: "processo_id",
    caption: "Id"
};

export const colunaProcessoNome = {
    dataField: "nome",
    caption: "Nome"
};

export const colunaProcessoCategoria = {
    dataField: "categoria_processos_nome",
    caption: "Categoria"
};

export const colunaProcessoAtivo = {
    dataField: "ativo",
    caption: "Ativo"
}

export const colunaProcessoVersao = {
    dataField: "versao_processo",
    caption: "Versão"
}
export const colunaProcessoPublicacao = {
    dataField: "versao_publicacao",
    caption: "Publicação"
}
export const colunaProcessoSolicitacoes = {
    dataField: "sequencia_solicitacoes",
    caption: "Solicitações"
}

export const processoBotoes = function() {
    var icon_class = "icon_black"

    return {
        type: "buttons",
        buttons: [
            {
                hint: "Editar",
                icon: "fas fa-pencil-alt",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('processo/detalhes', function(html_id, processo_detalhes) {
                        processo_detalhes.onclose = e.component.refresh;
                        processo_detalhes.show(e.row.data.processo_id, window.FORMULARIO.EDITAR);
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: "Clonar processo",
                icon: "copy",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('processo/clonar', function(html_id, processo_clonar) {
                        processo_clonar.onclose = e.component.refresh;
                        processo_clonar.show(e.row.data.nome ,e.row.data.processo_id);
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: 'Exportar',
                icon: "export",
                cssClass: icon_class,
                onClick: function(e) {
                    WS.get("processo/objeto/",
                        { 
                            processo_id: e.row.data.processo_id,
                            exportacao: true
                        },
                        function(response) {
                            let corpo = encodeURIComponent(JSON.stringify(response));
                            let link = document.createElement('a');
                            link.setAttribute('download', e.row.data.nome + '.nbpm');
                            link.setAttribute('href', 'data:application/json;charset=utf-8,' + corpo);
                            link.click();
                        }
                    );
                }
            },
            {
                hint: "Visualizar",
                icon: "fas fa-eye",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('processo/detalhes', function(html_id, processo_detalhes) {
                        processo_detalhes.show(e.row.data.processo_id, window.FORMULARIO.VISUALIZAR);
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: "Excluir",
                icon: "fas fa-trash",
                cssClass: icon_class,
                onClick: function(e) {
                    window.View.load('processo/detalhes', function(html_id, processo_detalhes) {
                        processo_detalhes.onclose = e.component.refresh;
                        processo_detalhes.show(e.row.data.processo_id, window.FORMULARIO.EXCLUIR);
                    }, window.View.ABA.MULTIPLAS);
                }
            }
        ],
    }
};

export const processoColunas = [
    colunaProcessoId,
    colunaProcessoNome,
    colunaProcessoCategoria,
    colunaProcessoAtivo,
    colunaProcessoVersao,
    colunaProcessoPublicacao,
    colunaProcessoSolicitacoes,
    processoBotoes()
];

export const stateStoring = {
    enabled: true,
    type: "custom",
    customLoad: function (state) {
        return window.App.get_parametro_usuario('listarProcessos');
    },
    customSave: function (state) {
        window.App.set_parametro_usuario('listarProcessos', state);
    }
}

export let listarProcessos = (parametros) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'processo_id',
    load: (loadOptions) => {
        let dxParams = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            
            WS.get({
            route: "processo/listar/", 
                data: {
                    ...dxParams,
                    ...parametros
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data });
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;

        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "processo/listar/", 
                data: {
                    dx_grid: true,
                    processo_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});