import React from 'react';

class VerticalSpacer extends React.Component {
    render() {
        return (
            <div style={{height: '10px'}}></div>
        );
    }
}

class HorizontalSpacer extends React.Component {
    render() {
        return (
            <div style={{width: '10px'}}></div>
        );
    }
}

export { VerticalSpacer, HorizontalSpacer };