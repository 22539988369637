import View from '../api/view';

export let documentoColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Detalhes do Documento",
        icon: "fa fa-pencil-alt",
        onClick: (e) => {
            if (callbackBtns) callbackBtns();
            View.load('central_documentos/documento', (html_id, central_documentos_arquivo) => {
                central_documentos_arquivo.show(e.row.data.documento_id, window.FORMULARIO.EDITAR);
            }, View.ABA.MULTIPLAS);
        }
    }
};

export const documentoColunaButtons = {
    type: "buttons",
    buttons: [
        documentoColunaButtonVisualizar()
    ]
};

export const documentoColunas = [
    { 
        dataField: "codigo",
        caption: "Código",
        width: 100,
    },
    { 
        dataField: "nome",
        caption: "Nome",
        minWidth: 200,
    },
    { 
        dataField: "tag_documento",
        caption: "Tag" ,
        minWidth: 100
    },
    {
        dataField: "assunto_descricao", 
        caption: "Assunto",
        minWidth: 100
    },
    {
        dataField: "tipo_documento_nome", 
        caption: "Tipo",
        minWidth: 100
    },
    {
        dataField: "versao", 
        caption: "Versão",
        minWidth: 100
    },
    {
        dataField: "revisao_tamanho", 
        caption: "Tamanho",
        minWidth: 100,
        calculateCellValue: function(data) {
            if (data.revisao_tamanho) {
                return window.filesize(parseInt(data.revisao_tamanho,10) || 0);
            }
        }
    },
    { 
        dataField: "revisao_data_envio",
        caption: "Modificação",
        minWidth: 100,
        dataType: "datetime",
        format: "dd/MM/yyyy HH:mm",
        calculateCellValue: function(data) {
            if (data.revisao_data_envio) {
                return data.revisao_data_envio.format_date_time();
            }
        }
    },
    { 
        dataField: "data_vigencia",
        caption: "Vigente até",
        minWidth: 100,
        dataType: "datetime",
        format: "dd/MM/yyyy HH:mm",
        calculateCellValue: function(data) {
            if (data.data_vigencia) {
                return data.data_vigencia.format_date_time();
            }
        }
    }
];