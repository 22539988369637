import App from '../api/app';
import WS from '../api/ws';
import View from '../api/view';
import DataSource from 'devextreme/data/data_source';

export let chamadoColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar chamado",
        icon: "fa fa-comments",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.loadReact(
                window.views.chamado.Detalhes.default,
                { chamadoId: e.row.data.id },
                function(html_id, view) { },
                View.ABA.MULTIPLAS
            );
        }
    }
};

export const chamadoColunaButtons = {
    type: "buttons",
    buttons: [
        {
            hint: "Avaliar atendimento",
            icon: "fa fa-star-half-alt",
            visible: function(e){
                return e.row.data.exibir_avaliacao === 'S' && e.row.data.nota_avaliacao_id == null && (e.row.data.situacao === window.CHAMADO_SITUACAO.ENCERRADO || e.row.data.situacao === window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE) && (e.row.data.usuario_abertura_id === App.sessao.dados.usuario_id || (App.sessao.dados.usuario_id === e.row.data.usuario_cliente_id)||(App.sessao.dados.usuario_id === e.row.data.usuario_empresa_id)||(App.sessao.dados.usuario_id === e.row.data.usuario_fornecedor_id)||(App.sessao.dados.usuario_id === e.row.data.usuario_prospect_id))
            },
            onClick: function(e) {
                View.load('chamado/encerramento_chamado', function(html_id, encerramento_chamado) {
                    var onclose = function(){
                        //
                    }
                    
                    encerramento_chamado.onclose = onclose;    
                    encerramento_chamado.show(e.row.data.id);
                });
            }
        },
        chamadoColunaButtonVisualizar()
    ]
};

export const chamadoColunas = [
    {
        dataField: "id",
        dataType: "number",
        caption: "#",
        width: 90
    },
    { 
        dataField: "data_abertura",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        width: 110
    },
    { 
        dataField: "data_encerramento",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        width: 110
    },
    {
        dataField: "prioridade_nome",
        caption: "Prioridade",
        width: 100
    },
    {
        dataField: "assunto",
        width: 150
    },
    {
        dataField: "situacao",
        caption: "Situação",
        lookup: {
            dataSource: window.CHAMADO_SITUACAO_LOOKUP,
            displayExpr: "descricao",
            valueExpr: "id"
        },
        width: 130
    },
    {
        dataField: "usuario_solicitante_nome",
        cellTemplate: function (container, options) {
            var imagem_id = options.data.usuario_solicitante_foto_id;
            var link_imagem = imagem_id ? window.WS_URI+'documento/midia/?&tamanho=20&documento_id='+imagem_id+'&token_midia='+App.sessao.token_midia : 'assets/img/imagem_usuario_padrao.svg';
            var nome_solicitante = options.data.usuario_solicitante_nome;
            container.html(
                '<div style="margin-top: 3px;">' +
                    '<img style="height: 20px; width: 20px" class="imagem_responsavel" src="'+ link_imagem +'">' +
                    '<span style="margin-left: 5px;">'+ nome_solicitante +'</span>' +
                '</div>'
            );
        },
        width: 100
    },
    {
        dataField: "usuario_responsavel_nome",
        cellTemplate: function (container, options) {
            var nome_responsavel = options.data.usuario_responsavel_nome;
            if(nome_responsavel){
                var imagem_id = options.data.foto_usuario_responsavel
                var link_imagem = imagem_id ? window.WS_URI+'documento/midia/?&tamanho=20&documento_id='+imagem_id+'&token_midia='+App.sessao.token_midia : 'assets/img/imagem_usuario_padrao.svg';
                container.html(
                    '<div style="margin-top: 3px;">' +
                        '<img style="height: 20px; width: 20px" class="imagem_responsavel" src="'+ link_imagem +'">' +
                        '<span style="margin-left: 5px;">'+ nome_responsavel +'</span>' +
                    '</div>'
                );
            }
        },
        width: 100
    },
    {
        dataField: "tipo",
        calculateCellValue: function(data) {
            return data ? window.DescricaoTipoEntidade(data.tipo) : null;
        },
        width: 100
    },
    {
        dataField: "codigo_entidade",
        width: 100
    },
    {
        dataField: "entidade_nome",
        width: 100
    },
    { 
        dataField: "data_previsao_retorno",
        caption: "Previsão retorno",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        width: 150
    },
    {
        dataField: "motivo",
        width: 100
    },
    {
        dataField: "classificacao_nome",
        visible: false,
        width: 100
    },
    {
        caption: "Grupo de Motivo",
        dataField: "grupo_motivo_nome",
        width: 100
    },
    {
        caption: "Nota de Avaliação",
        dataField: "nota_avaliacao_nome",
        width: 100,
        visible: false
    },
];

export let chamadoList = (situacao, numeroChamado, calculosAdicionais=false) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar/",
                data: {
                    dx_grid: true,
                    situacao: situacao,
                    numero_chamado: numeroChamado,
                    sla: !calculosAdicionais ? 'N' : 'S',
                    tempo_inativo: !calculosAdicionais ? 'N' : 'S',
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar/",
                data: {
                    dx_grid: true,
                    situacao: window.CHAMADO_FILTRO.TODOS,
                    numero_chamado: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export let chamadoSolicitanteList = (chamadoId, entidadeTipo, entidadeId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'usuario_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "chamado/listar_solicitantes_para_selecao/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    entidade_tipo: entidadeTipo,
                    entidade_id: entidadeId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar_solicitantes_para_selecao/", 
                data: {
                    dx_grid: true,
                    usuario_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export const colunaSituacaoNome = {
    dataField: "nome",
    caption: "Nome"
};

export let chamadoResponsavelList = (chamadoId, entidadeTipo, entidadeId, motivoId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'usuario_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "chamado/listar_responsaveis_para_selecao/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    entidade_tipo: entidadeTipo,
                    entidade_id: entidadeId,
                    motivo_id: motivoId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar_responsaveis_para_selecao/", 
                data: {
                    dx_grid: true,
                    usuario_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export let chamadoSituacaoList = (chamadoId) => new DataSource({
    key: 'id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "chamado/listar_situacao_para_selecao/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado/listar_situacao_para_selecao/", 
                data: {
                    dx_grid: true,
                    situacao_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});

export let reclassificar = (parametros, callbackAoSalvar, callbackAoFalhar) => {
    
    const aoSalvar = (response) => {
        if (response.chamado.rascunho === true) {
            window.alert_info('Rascunho salvo com sucesso');
        }
        else {
            window.alert_saved('Reclassificação salva com sucesso');
        }
        if (callbackAoSalvar) callbackAoSalvar(response);
    };
    
    const aoFalhar = (response) => {
        window.alert_fail('Falhou');
        if (callbackAoFalhar) callbackAoFalhar(response);
    };

    WS.post({
        route: "chamado/salvar",
        data: parametros,
        func_response: aoSalvar,
        func_fail: aoFalhar,
    });
}