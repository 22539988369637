import React from 'react';
import App from '../../api/app';
import SelecaoEntidade from '../../components/SelecaoEntidade';
import { reclassificar } from '../../datasource/Chamado';
import LabelCard from './LabelCard';

// Contextos
import { MensagemContext } from '../../contexts/MensagemContext';

export default class CardEntidade extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: this.props.detalhes ? { tipo: this.props.detalhes.tipo, id: this.props.detalhes.entidade_id ? this.props.detalhes.entidade_id.toString() : null  } : null,
            novaMensagem: this.props.novaMensagem ?? false,
            exibirTelefones: false,
            caretIcon: 'fas fa-caret-right'
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes.tipo !== this.props.detalhes.tipo
            || prevProps.detalhes.entidade_id !== this.props.detalhes.entidade_id
            || prevProps.detalhes.rascunho !== this.props.detalhes.rascunho
            || prevProps.novaMensagem !== this.props.novaMensagem) {
            this.setState(this.propsToObjState());
        }
    }

    aoAlterar(selecionado) {
        let selecionadoAnterior = this.state.selecionado;
        if (selecionadoAnterior && (selecionadoAnterior.tipo !== selecionado.tipo || +selecionadoAnterior.entidade_id !== +selecionado.id)) {
            this.setState({ selecionado: selecionado });
        }
        
        if (!this.state.novaMensagem
            && selecionado
            && +this.state.detalhes.id
            && (this.state.detalhes.tipo !== selecionado.tipo || +this.state.detalhes.entidade_id !== +selecionado.id)) {

            reclassificar(
                {
                    chamado_id: +this.state.detalhes.id,
                    entidade_tipo: selecionado.tipo,
                    entidade_id: selecionado.id
                },
                (this.props.aoAlterar ? this.props.aoAlterar : null),
                (response => this.setState({ selecionado: selecionadoAnterior }))
            );
        }
        else {
            if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
        }
    }

    handleExibirTelefones() {
        this.setState({ 
            exibirTelefones: !this.state.exibirTelefones,
            caretIcon: !this.state.exibirTelefones === true ? 'fas fa-caret-down' : 'fas fa-caret-right'
        });
    }

    render() {
        let readOnly = (
            (this.context.botaoPressionado && this.context.botaoPressionado !== "reclassificar")
            ||
            (
                !this.state.detalhes.rascunho
                && 
                (
                    !App.verifica_permissao(App.temp.empresa, "reclassificar_chamado")
                    || [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.state.detalhes.situacao)
                )
            )
        );
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <LabelCard label={this.props.label} novaMensagem={this.state.novaMensagem} />
                <SelecaoEntidade
                    selecionado={this.state.selecionado}
                    aoAlterar={this.aoAlterar}
                    stylingMode="filled"
                    readOnly={readOnly}
                />
                {/* Se a entidade do Chamado não for uma empresa filial, disponibilizo os telefones de contato cadastrados, caso exista algum */}
                {this.props.detalhes.tipo !== null && this.props.detalhes.tipo !== "" && this.props.detalhes.tipo !== 'E' && (this.props.detalhes.entidade_telefone1 || this.props.detalhes.entidade_telefone2 || this.props.detalhes.entidade_telefone3 || this.props.detalhes.entidade_telefone4) && (
                <div style={{ marginTop: 10 }}>
                    <p style={{ cursor: 'pointer', display: 'inline-block' }} onClick={this.handleExibirTelefones.bind(this)}>
                        <i class="fas fa-phone-square-alt"></i> <strong>Contato</strong> <i class={this.state.caretIcon}></i>
                    </p>
                    {this.state.exibirTelefones && (<div>
                        {this.props.detalhes.entidade_telefone1 && (<p><strong>Telefone 1: </strong>{this.props.detalhes.entidade_telefone1}</p>)}
                        {this.props.detalhes.entidade_telefone2 && (<p><strong>Telefone 2: </strong>{this.props.detalhes.entidade_telefone2}</p>)}
                        {this.props.detalhes.entidade_telefone3 && (<p><strong>Telefone 3: </strong>{this.props.detalhes.entidade_telefone3}</p>)}
                        {this.props.detalhes.entidade_telefone4 && (<p><strong>Telefone 4: </strong>{this.props.detalhes.entidade_telefone4}</p>)}
                    </div>)}
                </div>)}
            </div>
        );
    }
}

CardEntidade.defaultProps = {
    label: "Entidade",
    selecionadoTipo: null,
    selecionadoId: null
};

CardEntidade.contextType = MensagemContext;