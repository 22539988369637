
import SelecaoPadraoTagBox from './SelecaoPadraoTagBox';
import { listarGrupoUsuarios } from '../datasource/GrupoUsuarios';

export default class SelecaoGrupoUsuarios extends SelecaoPadraoTagBox {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            ...this.propsToObjState(),
        };
    }

    propsToObjState() {
        return {
            selecionado: this.props.selecionado ? this.props.selecionado : null,
            readOnly: this.props.readOnly ?? null,
            dataSource: listarGrupoUsuarios(this.props),
            instanceSelecaoPadraoTagBox: this.props.instanceSelecaoGrupoUsuarios ?? null,
            onSelectionChanged: this.props.onSelectionChanged ?? null,
            onValueChanged: this.props.onValueChanged ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.selecionado) !== JSON.stringify(this.props.selecionado) || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

SelecaoGrupoUsuarios.defaultProps = {
    placeholder: "Pesquisar por nome",
    dataSource: listarGrupoUsuarios({ 
        grupo_usuarios: true,
    }),
    displayExpr: 'nome',
    valueExpr: 'grupo_usuarios_id'
};