import { projetoAtividadeColunas } from '../datasource/ProjetoAtividade';
import WS from '../api/ws';
import React from 'react';
import $ from 'jquery';

export default class SelecaoProjetoAtividade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};

        this.listarAtividadeProjeto = null;
        this.divComponenteAtividadesRef = React.createRef();

        this.preencherAtividadeProjeto = this.preencherAtividadeProjeto.bind(this);
        this.obterAtividadesProjeto = this.obterAtividadesProjeto.bind(this);
    }

    get divComponenteAtividades() {
        return this.divComponenteAtividadesRef.current ?? null;
    }

    propsToObjState() {
        return {
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
            projetoId: this.props.projetoId ?? null
        };
    }

    componentDidMount() {
        this.obterAtividadesProjeto();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.projetoId !== this.props.projetoId
            || prevProps.projetoAtividadeId !== this.props.projetoAtividadeId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
        if (prevProps.projetoId !== this.props.projetoId) {
            this.obterAtividadesProjeto(this.props.projetoId);
        }
    }

    componentWillUnmount() {
        this.desmontarListarAtividadeProjeto();
    }

    desmontarListarAtividadeProjeto() {
        window.require(['react-dom'], (amdReactDOM) => {
            if (this.divComponenteAtividades)
                amdReactDOM.unmountComponentAtNode(this.divComponenteAtividades)
        });
    }

    preencherAtividadeProjeto = function(data){
        window.require(['react', 'react-dom', './assets/view/atividade_projeto/ListarAtividadeProjeto'], (amdReact, amdReactDOM, ListarAtividadeProjeto) => {
            
            if (this.divComponenteAtividades)
                amdReactDOM.unmountComponentAtNode(this.divComponenteAtividades)

            this.listarAtividadeProjeto = amdReact.createElement(ListarAtividadeProjeto, null, null);

            amdReactDOM.render(
                this.listarAtividadeProjeto,
                this.divComponenteAtividades
            );
            
            this.listarAtividadeProjeto.props.setParams({
                div: $(this.divComponenteAtividades),
                data: data,
                dropdown: true,
                dropdown_disabled: false,
                somente_leitura: true,
                atividade_selecionada: this.state.selecionado ?? null,
                ao_selecionar: (projeto_atividade_id) => {
                    this.setState({selecionado: projeto_atividade_id});
                    if (this.props.aoAlterar) {
                        this.props.aoAlterar(this.state.selecionado);
                    }
                },
                parametro_usuario: "atividade_projeto_referencias"
            });

        });
    }

    obterAtividadesProjeto = function(projetoId) {
        WS.get({
            route: "atividade_projeto/listar/",
            data: {
                projeto_id: projetoId ?? this.state.projetoId
            },
            func_response: (response) => {
                response.atividades.push({
                    atividade_simplificada: response.projeto.nome,
                    id: "raiz",
                    projeto_atividade_sintetica_pai_id: null,
                    tipo_atividade: "P",
                    marco: false,
                    horas_previstas: "000:00"
                });

                this.preencherAtividadeProjeto(response);
            }
        });
    }

    render() {
        return <div ref={this.divComponenteAtividadesRef}></div>
    }
}


SelecaoProjetoAtividade.defaultProps = {
    placeholder: "Pesquisar Atividade de Projeto",
    columns: projetoAtividadeColunas,
    visible: true
};