import React from 'react';
import DataGridPadrao from '../../../src/components/DataGridPadrao';
import { fornecedorColunas, listarFornecedores, stateStoring } from '../../datasource/Fornecedor';

class ListagemFornecedor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fornecedorLogo: '',
            fornecedorCodigo: '',
            fornecedorUnidade: '',
            fornecedorNomeFantasia: '',
            fornecedorRazaoSocial: '',
            fornecedorTelefone1: '',
            fornecedorTelefone2: '',
            fornecedorTelefone3: '',
            fornecedorTelefone4: '',
            
        };
    }

    onToolbarPreparing(e){
        const arr_botoes = [
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-plus",
                    "hint":"Novo fornecedor",
                    "text":"Novo fornecedor",
                    onClick: function(){
                        window.View.load('fornecedor/detalhes', function(html_id, fornecedor_detalhes) {
                            fornecedor_detalhes.onclose = e.component.refresh;
                            fornecedor_detalhes.show(null, window.FORMULARIO.NOVO);
                        }, window.View.ABA.MULTIPLAS);
                    },
                    "visible": window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'fornecedor'),
                },
                "location":"after",
                "name":"BtnNovoFornecedor",
                "locateInMenu":"auto",
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-sync",
                    "hint":"Recarregar listagem de fornecedores",
                    "text":"Recarregar fornecedores",
                    onClick: function(){
                        this.listarFornecedores = e.component.refresh();

                    }
                },
                "showText":"inMenu",
                "location":"after",
                "name":"BtnRefresh",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-cog",
                    "hint":"Configurações",
                    //"text":"Configurações",
                    onClick: function(){
                        window.View.load('fornecedor/configuracoes', function(html_id, fornecedor_configuracoes) {
                            fornecedor_configuracoes.onclose = onclose;
                            fornecedor_configuracoes.show();
                        });
                    },
                    "visible": window.App.sessao.dados.admin
                },
                "location":"after",
                "name":"BtnConfiguracoes",
                "locateInMenu":"auto"
            },
            
        ];
        e.toolbarOptions.items = window.add_botao_dx_toolbar(arr_botoes, e.toolbarOptions.items);
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <DataGridPadrao style={{height: "calc(100vh - 46px)"}}
                            onToolbarPreparing={this.onToolbarPreparing}
                            dataSource={
                                listarFornecedores({
                                    dx_grid: true,
                                    
                                })
                            }
                            stateStoring={stateStoring}
                            columns={fornecedorColunas()}
                        >
                        </DataGridPadrao>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ListagemFornecedor.defaultProps = {
    abaTitulo: "Fornecedores"
};

export default ListagemFornecedor;