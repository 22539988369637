import App from '../api/app';
import SelecaoPadrao from './SelecaoPadrao';
import { solicitacaoAtividadeList, solicitacaoAtividadeColunas } from '../datasource/SolicitacaoAtividade';

export default class SelecaoSolicitacaoAtividade extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: solicitacaoAtividadeList(window.SOLICITACAO_FILTROS.TODAS),
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
            columns: solicitacaoAtividadeColunas(App.sessao.dados.parametro_processo.numero_solicitacao_por_processo)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.solicitacaoAtividadeId !== this.props.solicitacaoAtividadeId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && (App.sessao.dados.parametro_processo.numero_solicitacao_por_processo === "S" ? item.numero : item.solicitacao_id) + ' ' + item.atividade_descricao;
}

SelecaoSolicitacaoAtividade.defaultProps = {
    placeholder: "Pesquisar Solicitação Atividade",
    displayExpr: displayExpr,
    visible: true
};