import React from 'react';
import App from '../../../api/app';

export function Tipo(props) {

    let descricao = "";
    switch (parseInt(props.tipo, 10)){

        case 1:
            descricao = 'Abertura';
            break;
        case 3:
            descricao = 'Encerramento';
            break;
        case 4:
            descricao = 'Reabertura';
            break;
        case 5:
            descricao = 'Reclassificação';
            break;
        case 6:
            descricao = 'Encerramento por Inatividade';
            break;
        case 8:
            descricao = 'Fusão';
            break;
        case 7:
            descricao = App.lang.observacao_interna_chamado.observacao_interna;
            break;
        default:
            descricao = "";
            break;
    }

    return (
        <div>
            <span className="label label-info" style={{}}>{descricao}</span>
        </div>
    );
}