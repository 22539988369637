import App from '../../api/app';
import React from 'react';
import DataGridPadrao from '../../components/DataGridPadrao'
import { Editing } from 'devextreme-react/data-grid';

class ListagemMovimentacaoFinanceira extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <DataGridPadrao style={{height: "calc(100vh - 173px)"}}
                            dataSource={this.props.arrDatasource}
                            remoteOperations={false}
                            columns={
                                [
                                    {
                                        dataField: "tipo",
                                        placeholder: App.lang.combobox.selecione,
                                        lookup: {
                                            dataSource: [
                                                {
                                                    "id": "1",
                                                    "descricao": "Entrada Manual"
                                                },
                                                {
                                                    "id": "2",
                                                    "descricao": "Saída Manual"
                                                },
                                                {
                                                    "id": "3",
                                                    "descricao": "Horas Alocadas"
                                                },
                                                {
                                                    "id": "4",
                                                    "descricao": "Apontamento de Horas"
                                                },
                                                {
                                                    "id": "5",
                                                    "descricao": "Apontamento de Despesas"
                                                }
                                            ],
                                            displayExpr: "descricao",
                                            valueExpr: "id"
                                        },
                                    },
                                    {
                                        dataField: "descricao"
                                    },
                                    {
                                        dataField: "valor_previsto",
                                        dataType: "number",
                                        format: {
                                            type: "currency",
                                            precision: 2
                                        },
                                        editorOptions: {
                                            format: "R$ #,##0.##",
                                            showClearButton: true
                                        }
                                    },
                                    {
                                        dataField: "valor_realizado",
                                        dataType: "number",
                                        format: {
                                            type: "currency",
                                            precision: 2
                                        },
                                        editorOptions: {
                                            format: "R$ #,##0.##",
                                            showClearButton: true
                                        }
                                    },
                                    {
                                        dataField: "data_prevista",
                                        dataType: "date",
                                    },
                                    {
                                        dataField: "data_realizado",
                                        dataType: "date"
                                    }
                                ]
                            }
                            onInitialized={(e) => {
                                this.props.instanceMovimentacaoFinanceira(e.component);
                            }}
                            onEditorPreparing={(e) => {
                                // find the Lookup column
                                if (e.parentType === "dataRow" && e.dataField === "tipo") {
                                    if(!e.editorOptions.value){
                                        e.editorOptions.dataSource = {
                                            store: {
                                                type: "array",
                                                data: [
                                                    {
                                                        "id": "1",
                                                        "descricao": "Entrada Manual"
                                                    },
                                                    {
                                                        "id": "2",
                                                        "descricao": "Saída Manual"
                                                    }
                                                ]
                                            }
                                        }
                                    }else if(e.editorOptions.value === window.MOVIMENTACAO_FINANCEIRA.HORAS_ALOCADAS || e.editorOptions.value === window.MOVIMENTACAO_FINANCEIRA.APONTAMENTO_HORAS || e.editorOptions.value === window.MOVIMENTACAO_FINANCEIRA.APONTAMENTO_DESPESAS){
                                        e.editorOptions.disabled = true;
                                    }
                                }
                            }}
                            onToolbarPreparing={(e) => {
                                var arr_botoes = [
                                    {
                                        "widget": "dxButton",
                                        "options":
                                        {
                                            "icon": "fa fa-plus",
                                            "hint": "Adicionar uma movimentação financeira",
                                            "text": "Adicionar uma movimentação financeira",
                                            onClick: function(){
                                                e.component.addRow();
                                            }
                                        },
                                        "location": "after",
                                        "name": "BtnNovoRegistro",
                                        "locateInMenu": "auto"
                                    }
                                    
                                ];
            
                                e.toolbarOptions.items = window.add_botao_dx_toolbar(arr_botoes, e.toolbarOptions.items);
            
                                for (var i = 0; i < e.toolbarOptions.items.length; i++) {
                                    var item = e.toolbarOptions.items[i];
                                    if(item.name === "saveButton") {
                                        item.visible = false;
                                    }
                                }
                            }}
                            summary={
                                {
                                    totalItems: [
                                        {
                                            name: "entrada_valor_previsto",
                                            showInColumn: "valor_previsto",
                                            summaryType: "custom",
                                            valueFormat: "currency",
                                            customizeText: function(itemInfo){
                                                return "Entrada: " + window.numberToReal(parseFloat(itemInfo.value.toString().replace(",", ".")));
                                            }
                                        },
                                        {
                                            name: "saida_valor_previsto",
                                            showInColumn: "valor_previsto",
                                            summaryType: "custom",
                                            valueFormat: "currency",
                                            customizeText: function(itemInfo){
                                                return "Saída: " + window.numberToReal(parseFloat(itemInfo.value.toString().replace(",", ".")));
                                            }
                                        },
                                        {
                                            name: "saldo_valor_previsto",
                                            showInColumn: "valor_previsto",
                                            summaryType: "custom",
                                            valueFormat: "currency",
                                            customizeText: function(itemInfo){
                                                return "Saldo: " + window.numberToReal(parseFloat(itemInfo.value.toString().replace(",", ".")));
                                            }
                                        },
                                        {
                                            name: "entrada_valor_realizado",
                                            showInColumn: "valor_realizado",
                                            summaryType: "custom",
                                            valueFormat: "currency",
                                            customizeText: function(itemInfo){
                                                return "Entrada: " + window.numberToReal(parseFloat(itemInfo.value.toString().replace(",", ".")));
                                            }
                                        },
                                        {
                                            name: "saida_valor_realizado",
                                            showInColumn: "valor_realizado",
                                            summaryType: "custom",
                                            valueFormat: "currency",
                                            customizeText: function(itemInfo){
                                                return "Saída: " + window.numberToReal(parseFloat(itemInfo.value.toString().replace(",", ".")));
                                            }
                                        },
                                        {
                                            name: "saldo_valor_realizado",
                                            showInColumn: "valor_realizado",
                                            summaryType: "custom",
                                            valueFormat: "currency",
                                            customizeText: function(itemInfo){
                                                return "Saldo: " + window.numberToReal(parseFloat(itemInfo.value.toString().replace(",", ".")));
                                            }
                                        }
                                    ],
                                    calculateCustomSummary: function(options){
                                        if (options.name === "entrada_valor_previsto") {
                                            if (options.summaryProcess === "start") {
                                                options.totalValue = 0;
                                            }
                                            if (options.summaryProcess === "calculate") {
                                                if (options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.ENTRADA_MANUAL) {
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) + (options.value.valor_previsto ? parseFloat(options.value.valor_previsto) : 0)).toString().replace(".", ",");
                                                }
                                            }
                                        }

                                        if (options.name === "saida_valor_previsto") {
                                            if (options.summaryProcess === "start") {
                                                options.totalValue = 0;
                                            }
                                            if (options.summaryProcess === "calculate") {
                                                if (options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.SAIDA_MANUAL || options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.HORAS_ALOCADAS || options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.APONTAMENTO_HORAS || options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.APONTAMENTO_DESPESAS) {
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) + (options.value.valor_previsto ? parseFloat(options.value.valor_previsto) : 0)).toString().replace(".", ",");
                                                }
                                            }
                                        }

                                        if (options.name === "saldo_valor_previsto") {
                                            if (options.summaryProcess === "start") {
                                                options.totalValue = 0;
                                            }
                                            if (options.summaryProcess === "calculate") {
                                                if (options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.ENTRADA_MANUAL) {
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) + (options.value.valor_previsto ? parseFloat(options.value.valor_previsto) : 0)).toString().replace(".", ",");
                                                }else{
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) - (options.value.valor_previsto ? parseFloat(options.value.valor_previsto) : 0)).toString().replace(".", ",");
                                                }
                                            }
                                        }

                                        if (options.name === "entrada_valor_realizado") {
                                            if (options.summaryProcess === "start") {
                                                options.totalValue = 0;
                                            }
                                            if (options.summaryProcess === "calculate") {
                                                if (options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.ENTRADA_MANUAL) {
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) + (options.value.valor_realizado ? parseFloat(options.value.valor_realizado) : 0)).toString().replace(".", ",");
                                                }
                                            }
                                        }

                                        if (options.name === "saida_valor_realizado") {
                                            if (options.summaryProcess === "start") {
                                                options.totalValue = 0;
                                            }
                                            if (options.summaryProcess === "calculate") {
                                                if (options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.SAIDA_MANUAL || options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.HORAS_ALOCADAS || options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.APONTAMENTO_HORAS || options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.APONTAMENTO_DESPESAS) {
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) + (options.value.valor_realizado ? parseFloat(options.value.valor_realizado) : 0)).toString().replace(".", ",");
                                                }
                                            }
                                        }

                                        if (options.name === "saldo_valor_realizado") {
                                            if (options.summaryProcess === "start") {
                                                options.totalValue = 0;
                                            }
                                            if (options.summaryProcess === "calculate") {
                                                if (options.value.tipo === window.MOVIMENTACAO_FINANCEIRA.ENTRADA_MANUAL) {
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) + (options.value.valor_realizado ? parseFloat(options.value.valor_realizado) : 0)).toString().replace(".", ",");
                                                }else{
                                                    options.totalValue = (parseFloat((options.totalValue ? options.totalValue.replace(",", ".") : 0)) - (options.value.valor_realizado ? parseFloat(options.value.valor_realizado) : 0)).toString().replace(".", ",");
                                                }
                                            }
                                        }
                                    },
                                }
                            }
                        >
                            <Editing
                                mode="batch"
                                allowUpdating={true}
                                allowDeleting={true}
                                useIcons={true}
                            />
                        </DataGridPadrao>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ListagemMovimentacaoFinanceira.defaultProps = {
    arrDatasource: []
};

export default ListagemMovimentacaoFinanceira;