import React from 'react';
import App from '../../api/app';
import SelecaoChamadoSolicitante from '../../components/SelecaoChamadoSolicitante';
import { reclassificar } from '../../datasource/Chamado';
import LabelCard from './LabelCard';

// Contextos
import { MensagemContext } from '../../contexts/MensagemContext';

export default class CardSolicitante extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
    }

    propsToObjState() {
        let entidadeTipo = this.props.entidadeTipo ?? this.props.detalhes.tipo ?? null;
        let entidadeId = this.props.entidadeId ?? this.props.detalhes.entidade_id ?? null;
        let selecionado = this.props.detalhes ? this.props.detalhes.solicitante_id : null;
        let colaboradorPodeAlterarSolicitante = this.props.colaboradorPodeAlterarSolicitante ?? false;
        
        if (this.props.novaMensagem
            && this.props
            && this.props.reclassificacoesMemoria) {

            if (this.props.reclassificacoesMemoria.entidade_tipo
                && this.props.reclassificacoesMemoria.entidade_id) {
                entidadeTipo = this.props.reclassificacoesMemoria.entidade_tipo;
                entidadeId = this.props.reclassificacoesMemoria.entidade_id;
            }

            if (this.props.reclassificacoesMemoria.usuario_solicitante_id) {
                selecionado = this.props.reclassificacoesMemoria.usuario_solicitante_id;
            }

            if (this.props.reclassificacoesMemoria.hasOwnProperty('chamado_motivo_altera_solicitante')) {
                colaboradorPodeAlterarSolicitante = this.props.reclassificacoesMemoria.chamado_motivo_altera_solicitante;
            }
        }

        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: selecionado,
            novaMensagem: this.props.novaMensagem ?? false,
            reclassificacoesMemoria: this.props.reclassificacoesMemoria ?? false,
            entidadeTipo: entidadeTipo,
            entidadeId: entidadeId,
            colaboradorPodeAlterarSolicitante: colaboradorPodeAlterarSolicitante,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes.solicitante_id !== this.props.detalhes.solicitante_id
            || prevProps.detalhes.rascunho !== this.props.detalhes.rascunho
            || prevProps.detalhes.tipo !== this.props.detalhes.tipo
            || prevProps.detalhes.entidade_id !== this.props.detalhes.entidade_id
            || prevProps.colaboradorPodeAlterarSolicitante !== this.props.colaboradorPodeAlterarSolicitante
            || prevProps.novaMensagem !== this.props.novaMensagem
            || !window.isEquivalent(prevProps.reclassificacoesMemoria, this.props.reclassificacoesMemoria)) {
            this.setState(this.propsToObjState());
        }
    }

    aoAlterar(selecionado) {
        let selecionadoAnterior = this.state.selecionado;

        if (+selecionadoAnterior !== +selecionado) {
            this.setState({ selecionado: selecionado });
        }

        if (!this.state.novaMensagem
            && selecionado
            && +this.state.detalhes.id
            && +this.state.detalhes.solicitante_id !== +selecionado) {

            reclassificar(
                {
                    "chamado_id": this.state.detalhes.id,
                    "usuario_solicitante_id": selecionado,
                },
                (this.props.aoAlterar ? this.props.aoAlterar : null),
                (response => this.setState({ selecionado: selecionadoAnterior }))
            );
        }
        else {
            if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
        }
    }

    render() {
        let selecionado = this.state.selecionado ? this.state.selecionado.toString() : null;
        let readOnly = (
            (this.context.botaoPressionado && this.context.botaoPressionado !== "reclassificar")
            ||
            (
                !App.verifica_permissao(App.temp.empresa, "reclassificar_chamado")
                || [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.state.detalhes.situacao)
                || (!App.sessao.dados.admin && App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA && !this.state.colaboradorPodeAlterarSolicitante)
            )
        );
        
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <LabelCard label={this.props.label} novaMensagem={this.state.novaMensagem} />
                <SelecaoChamadoSolicitante
                    chamadoId={this.state.detalhes.id}
                    entidadeTipo={this.state.entidadeTipo}
                    entidadeId={this.state.entidadeId}
                    selecionado={selecionado}
                    aoAlterar={this.aoAlterar}
                    stylingMode="filled"
                    readOnly={readOnly}
                />
            </div>
        );
    }
}

CardSolicitante.defaultProps = {
    label: "Solicitante",
};

CardSolicitante.contextType = MensagemContext;