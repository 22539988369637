import SelecaoPadrao from './SelecaoPadrao';
import { projetoList, projetoColunas } from '../datasource/Projeto';

export default class SelecaoProjeto extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: projetoList(),
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
            columns: projetoColunas()
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.projetoId !== this.props.projetoId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && item.codigo + ' ' + item.nome;
}

SelecaoProjeto.defaultProps = {
    placeholder: "Pesquisar Projeto",
    displayExpr: displayExpr,
    visible: true
};