import WS from '../../api/ws';
import $ from 'jquery';
import React from 'react';
import FormularioPersonalizado from '../../components/FormularioPersonalizado';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';

export default class CardFormularioAbertura extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();

        this.formularioAbertura = React.createRef();
        this.formularioAberturaMain = {};

        this.confirmarClick = this.confirmarClick.bind(this);
        this.cancelarClick = this.cancelarClick.bind(this);
    }

    propsToObjState() {
        return {
            chamadoId: this.props.chamadoId ?? null,
            chamadoMotivoId: this.props.chamadoMotivoId ?? null,
            readOnly: this.props.readOnly ?? false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chamadoId !== this.props.chamadoId
            || prevProps.chamadoMotivoId !== this.props.chamadoMotivoId) {
            this.setState(this.propsToObjState());
        }
    }

    confirmarClick() {
        if (!this.formularioAbertura.current.validar()) {
            return false;   
        }

        let params = {
            chamado_id: this.state.chamadoId,
            ...this.formularioAbertura.current.getPostParams(),
            ...this.formularioAbertura.current.getAnexosParams(),
        };

        let aoSalvar = (response) => {
            window.alert_saved('Formulário salvo com sucesso.');
        };
        
        let aoFalhar = (response) => {
            window.alert_fail('Falhou, tente novamente.');
        };

        WS.post({
            route: "formulario_chamado_abertura/salvar",
            data: params,
            func_response: aoSalvar,
            func_fail: aoFalhar,
            data_files: true
        });
    }

    cancelarClick() {
        this.formularioAbertura.current.renderFormulario()
    }
    
    render() {
        return (
            <React.Fragment>
                <FormularioPersonalizado
                    ref={this.formularioAbertura}
                    main={this.formularioAberturaMain}
                    urlGet="formulario_chamado_abertura/get_formulario"
                    urlGetParams={{chamado_id: this.state.chamadoId, motivo_id: this.state.chamadoMotivoId}}
                    readOnly={this.state.readOnly}
                />

                <Toolbar visible={!this.state.readOnly}>
                    <ToolbarItem location="before">
                        <Button
                            onClick={this.confirmarClick}
                            text="Salvar"
                            icon="fas fa-check"
                            stylingMode="contained"
                            type="default"
                        />
                    </ToolbarItem>
                    <ToolbarItem location="after">
                        <Button
                            onClick={this.cancelarClick}
                            text="Cancelar"
                            stylingMode="text"
                        />
                    </ToolbarItem>
                </Toolbar>
            </React.Fragment>
        );
    }

}