import React from 'react';

export default class CopiarLink extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        let url = this.props.url;
        
        // verifica se é um link interno do BP
        if (this.props.url.trim().substr(0, 1) === '#') {
            let novaUrl = document.URL;
            let posicaoHash = document.URL.indexOf('#');
            if (posicaoHash >= 0) {
                novaUrl = document.URL.substr(0, posicaoHash);
            }
            url = novaUrl + this.props.url;
        }
        
        window.copyToClipboard(url);
    }

    render() {
        return (
            <button
                onClick={this.onClick} 
                className="btn btn-link"
                style={this.props.style}
                title="Copiar link para área de transferência">
                {this.props.children}
            </button>
        );
    }

}

CopiarLink.defaultProps = {
    url: "about:blank",
    children: "Copiar Link"
};