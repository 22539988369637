import WS from '../api/ws';
import View from '../api/view';
import DataSource from 'devextreme/data/data_source';

export const solicitacaoSituacoes = [
    {
        "id": "3",
        "descricao": "Andamento"
    },
    {
        "id": "1",
        "descricao": "Concluída"
    }, 
    {
        "id": "2",
        "descricao": "Cancelada"
    },
];

export let solicitacaoColunaButtonVisualizar = (callbackBtns) => {
    return {
        hint: "Visualizar Solicitação",
        icon: "fa",
        cssClass: "icon_solicitacao",
        onClick: function(e) {
            if (callbackBtns) callbackBtns();
            View.load('solicitacao/listar', function(html_id, solicitacao) {
                solicitacao.show(e.row.data.id);
            }, View.ABA.SIM_E_RECARREGAR_SE_ESTIVER_ABERTA);
        }
    }
};

export const solicitacaoColunaButtons = {
    type: "buttons",
    buttons: [
        solicitacaoColunaButtonVisualizar()
    ]
};

export const solicitacaoColunas = [
    {
        dataField: "id",
        dataType: "number",
        caption: "#",
        width: 90
    },
    { 
        caption: "Data Solicitação",
        dataField: "data_solicitacao",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        width: 110
    },
    {
        caption: "Data Finalização",
        dataField: "data_finalizacao",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        width: 110
    },
    { 
        caption: "Prazo",
        dataField: "prazo",
        dataType: "date",
        format: "dd/MM/yyyy HH:mm",
        width: 110
    },
    {
        caption: "Descrição",
        dataField: "descricao",
        width: 100
    },
    {
        caption: "Processo",
        dataField: "processo_nome",
        width: 150
    },
    {
        dataField: "solicitacao_situacao",
        caption: "Situação",
        lookup: {
            dataSource: solicitacaoSituacoes,
            displayExpr: "descricao",
            valueExpr: "id"
        },
        width: 130
    },
];

export let solicitacaoList = (solicitacaoId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "solicitacao/listar/",
                data: {
                    dx_grid: true,
                    id_solicitacao: solicitacaoId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "solicitacao/listar/",
                data: {
                    solicitacao_id: solicitacaoId
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});