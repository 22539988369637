export const temas = [
    {
        id: 'amarelo',
        nome: 'Amarelo',
        corPrimaria: '#debb00'
    },
    {
        id: 'azul_escuro',
        nome: 'Azul Escuro',
        corPrimaria: '#002245'
    },
    {
        id: 'azul',
        nome: 'Azul',
        corPrimaria: '#030394'
    },
    {
        id: 'cinza',
        nome: 'Cinza',
        corPrimaria: '#474747'
    },
    {
        id: 'laranja_escuro',
        nome: 'Laranja Escuro',
        corPrimaria: '#944e03'
    },
    {
        id: 'laranja',
        nome: 'Laranja',
        corPrimaria: '#de6f00'
    },
    {
        id: 'preto',
        nome: 'Preto',
        corPrimaria: '#000'
    },
    {
        id: 'roxo',
        nome: 'Roxo',
        corPrimaria: '#4e0394'
    },
    {
        id: 'roxo_escuro',
        nome: 'Roxo Escuro',
        corPrimaria: '#270843'
    },
    {
        id: 'verde_claro',
        nome: 'Verde Claro',
        corPrimaria: '#289403'
    },
    {
        id: 'verde_escuro',
        nome: 'Verde Escuro',
        corPrimaria: '#174308'
    },
    {
        id: 'vermelho',
        nome: 'Vermelho',
        corPrimaria: '#be0101'
    },
    {
        id: 'vermelho_escuro',
        nome: 'Vermelho Escuro',
        corPrimaria: '#6b0606'
    },
];
