// Estilos
import styles from '../styles/components/Cockpit.module.css'

// Componentes
import CardCockpit from './CardCockpit';
import App from '../api/app';

export default function Cockpit({ 
    data, 
    supervisor_id, 
    supervisor_nome, 
    supervisionado_id, 
    supervisionado_nome, 
    onCloseModuleTab,
})
{

    const propsData = JSON.parse(data);

    return(
        <div className={styles.container}>
            {
                /* COCKPIT AGENDA */
                App.verifica_permissao(App.temp.empresa, "agenda") ?
                <CardCockpit
                    nomeModulo='Agenda'
                    qtdAbertoHoje={propsData.cardCockpitAgendaHoje}
                    qtdEmAtraso={propsData.cardCockpitAgendaAtrasadas}
                    qtdTotal={propsData.cardCockpitAgendaTotal}
                    iconeUrl='assets/img/icones_bp/calendar.svg'
                    btnAddClick={() => {
                        window.View.load('agenda/agenda_evento', function(html_id, agenda_evento) {
                            let usuario_id = 0;
                            let usuario_nome = '';

                            if (supervisor_id != null && supervisor_nome != null) {
                                usuario_id = supervisor_id;
                                usuario_nome = supervisor_nome;
                            }
                            else if (supervisionado_id != null && supervisionado_nome != null) {
                                usuario_id = supervisionado_id;
                                usuario_nome = supervisionado_nome;
                            }
                            else{
                                usuario_id = App.sessao.dados.usuario_id;
                                usuario_nome = App.sessao.dados.nome;
                            }

                            let params = {
                                evento_id: null,
                                usuario_id: usuario_id,
                                usuario_nome: usuario_nome,
                                obj_dados_projeto: null
                            }

                            agenda_evento.onclose = onCloseModuleTab;
                            agenda_evento.show(params);
                        });
                    }}
                /> : ''
            }

            {
                /* COCKPIT CHAMADOS */
                App.verifica_permissao(App.temp.empresa, "gestao_chamados") ?
                <CardCockpit
                    nomeModulo='Chamados'
                    qtdAbertoHoje={propsData.cardCockpitChamadosHoje}
                    qtdEmAtraso={propsData.cardCockpitChamadosAtrasados}
                    qtdTotal={propsData.cardCockpitChamadosTotal}
                    iconeUrl='assets/img/icones_bp/comunication.svg'
                    btnAddClick={() => {
                        window.View.loadReact(
                            window.views.chamado.Detalhes.default,
                            { onCloseModuleTab: onCloseModuleTab },
                            function(html_id, view) { },
                            window.View.ABA.MULTIPLAS
                        );
                    }}
                /> : ''
            }

            {
                /* COCKPIT DOCUMENTOS */
                App.verifica_permissao(App.temp.empresa, "gestao_documentos") ?
                <CardCockpit
                    nomeModulo='Documentos'
                    qtdAbertoHoje={propsData.cardCockpitDocumentosHoje}
                    qtdEmAtraso={propsData.cardCockpitDocumentosAtrasados}
                    qtdTotal={propsData.cardCockpitDocumentosTotal}
                    iconeUrl='assets/img/icones_bp/folder.svg'
                    btnListClick={() => {
                        window.View.load("central_documentos/listar", function(html_id, documento_listar) {
                            // init
                            documento_listar.show(true);
                        }, window.View.ABA.SIM);
                    }}
                /> : ''
            }

            {
                /* COCKPIT PROCESSOS */
                App.verifica_permissao(App.temp.empresa, "gestao_processos") ?
                <CardCockpit
                    nomeModulo='Processos'
                    qtdAbertoHoje={propsData.cardCockpitProcessosHoje}
                    qtdEmAtraso={propsData.cardCockpitProcessosAtrasados}
                    qtdTotal={propsData.cardCockpitProcessosTotal}
                    iconeUrl='assets/img/icones_bp/processos.svg'
                    btnAddClick={() => {
                        window.View.load('solicitacao/nova', function(html_id, solicitacao_nova) {
                            solicitacao_nova.onclose = onCloseModuleTab;
                            solicitacao_nova.show();
                        });
                    }}
                /> : ''
            }

            {
                /* COCKPIT PROJETOS */
                App.verifica_permissao(App.temp.empresa, "gestao_projetos") ?
                <CardCockpit
                    nomeModulo='Projetos'
                    qtdAbertoHoje={propsData.cardCockpitProjetosHoje}
                    qtdEmAtraso={propsData.cardCockpitProjetosAtrasados}
                    qtdTotal={propsData.cardCockpitProjetosTotal}
                    iconeUrl='assets/img/icones_bp/icon_black_project.svg'
                    customButtons={[
                        {
                            icon: 'fas fa-file-invoice-dollar',
                            hint: 'Apontamento de Despesa',
                            onClick: function() {
                                window.View.load('apontamento_despesas/detalhes', function(html_id, apontamento_despesas) {
                                    apontamento_despesas.show(window.FORMULARIO.NOVO, null);
                                }, window.View.ABA.MULTIPLAS);
                            },
                            visible: App.verifica_permissao(App.temp.empresa, "apontamento_despesa") || App.verifica_permissao(App.temp.empresa, "admin_apontamento_despesa")
                        },
                        {
                            icon: 'far fa-clock',
                            hint: 'Apontamento de Atividade',
                            onClick: function() {
                                window.View.load('apontamento_projeto/detalhes', function(html_id, detalhes) {
                                    detalhes.show(null, window.FORMULARIO.NOVO);
                                }, window.View.ABA.MULTIPLAS);
                            },
                            visible: App.verifica_permissao(App.temp.empresa, "apontamento_atividade") || App.verifica_permissao(App.temp.empresa, "admin_apontamento_atividade")
                        }
                    ]}
                /> : ''
            }

            {
                /* COCKPIT PDCA */
                App.verifica_permissao(App.temp.empresa, "pdca") ?
                <CardCockpit
                    nomeModulo='PDCA'
                    qtdAbertoHoje={propsData.cardCockpitPDCAHoje}
                    qtdEmAtraso={propsData.cardCockpitPDCAAtrasados}
                    qtdTotal={propsData.cardCockpitPDCATotal}
                    iconeUrl='assets/img/icones_bp/list_alt.svg'
                /> : ''
            }

            {
                /* COCKPIT CRM */
                App.verifica_permissao(App.temp.empresa, "gestao_relacionamento") ?
                <CardCockpit
                    nomeModulo='CRM'
                    qtdAbertoHoje={propsData.cardCockpitOportunidadesHoje}
                    qtdEmAtraso={propsData.cardCockpitOportunidadesAtrasadas}
                    qtdTotal={propsData.cardCockpitOportunidadesTotal}
                    iconeUrl='assets/img/icones_bp/people.svg'
                /> : ''
            }

            {
                /* COCKPIT TAREFAS */
                App.verifica_permissao(App.temp.empresa, "gestao_tarefas") ?
                <CardCockpit
                    nomeModulo='Tarefas'
                    qtdAbertoHoje={propsData.cardCockpitCartoesHoje}
                    qtdEmAtraso={propsData.cardCockpitCartoesAtrasados}
                    qtdTotal={propsData.cardCockpitCartoesTotal}
                    iconeUrl='assets/img/icones_bp/tarefa.svg'
                    btnListClick={() => {
                        window.View.load("tarefa/listar", function(html_id, tarefa_quadro) {
                            // init
                        }, window.View.ABA.SIM);
                    }}
                /> : ''
            }

        </div>
    );
}
