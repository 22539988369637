import React from 'react';
import DataGridPadrao from '../../components/DataGridPadrao';
import { prospectColunas, listarProspects, dataGridConfig } from '../../datasource/Prospect';

class ListagemProspect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prospectCodigo: '',
            prospectUnidade: '',
            prospectNomeFantasia: '',
            prospectRazaoSocial: '',
            prospectTelefone1: '',
            prospectTelefone2: '',
            prospectTelefone3: '',
            prospectTelefone4: '',
            
        };
    }

    onToolbarPreparing(e){
        const arr_botoes = [
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-plus",
                    "hint":"Nova organização",
                    "text":"Nova organização",
                    onClick: function(){
                        window.View.load('prospect/detalhes', function(html_id, prospect_detalhes) {
                            prospect_detalhes.onclose = e.component.refresh;
                            prospect_detalhes.show(null, window.FORMULARIO.NOVO);
                        }, window.View.ABA.MULTIPLAS);
                    },
                    "visible": window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'prospect'),
                },
                "location":"after",
                "name":"BtnNovaOrganizacao",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fa fa-sync",
                    "hint":"Recarregar listagem de organizações",
                    "text":"Recarregar organizações",
                    onClick: function(){
                        this.listarProspects = e.component.refresh();

                    }
                },
                "showText":"inMenu",
                "location":"after",
                "name":"BtnRefresh",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-file-import",
                    "hint":"Importar",
                    onClick: function(){
                        window.View.load('prospect/importar', function(html_id, prospect_importar) {
                            prospect_importar.onclose = onclose;
                            prospect_importar.show();
                        });
                    },
                    "visible": window.App.sessao.dados.admin
                },
                "location":"after",
                "name":"BtnImportar",
                "locateInMenu":"auto"
            },
            {
                "widget":"dxButton",
                "options":
                {
                    "icon":"fas fa-cog",
                    "hint":"Configurações",
                    onClick: function(){
                        window.View.load('prospect/configuracoes', function(html_id, prospect_configuracoes) {
                            prospect_configuracoes.onclose = onclose;
                            prospect_configuracoes.show();
                        });
                    },
                    "visible": window.App.sessao.dados.admin
                },
                "location":"after",
                "name":"BtnConfiguracoes",
                "locateInMenu":"auto"
            },
            
        ];
        e.toolbarOptions.items = window.add_botao_dx_toolbar(arr_botoes, e.toolbarOptions.items);
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                            <DataGridPadrao style={{height: "calc(100vh - 46px)"}}
                                onToolbarPreparing={this.onToolbarPreparing}
                                dataSource={
                                    listarProspects({
                                        dx_grid: true,
                                        
                                    }) 
                                }
                                dataGridConfig={dataGridConfig}
                                columns={prospectColunas()}
                            >
                            </DataGridPadrao>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ListagemProspect.defaultProps = {
    abaTitulo: "Organizações"
};

export default ListagemProspect;