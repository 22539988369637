import SelecaoPadrao from './SelecaoPadrao';
import { chamadoNotaAvaliacaoAvaliacaoColunas, chamadoNotaAvaliacaoAvaliacaoList } from '../datasource/ChamadoNotaAvaliacao';

export default class SelecaoChamadoNotaAvaliacao extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: chamadoNotaAvaliacaoAvaliacaoList(this.props.chamadoId)
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chamadoId !== this.props.chamadoId) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && item.nome;
}

SelecaoChamadoNotaAvaliacao.defaultProps = {
    chamadoId: null,
    aoAlterar: null,
    displayExpr: displayExpr,
    columns: chamadoNotaAvaliacaoAvaliacaoColunas,
};