import React from 'react';
import List from 'devextreme-react/list';
import { confirm, alert } from 'devextreme/ui/dialog';
import App from '../api/app';
import WS from '../api/ws';
import $ from 'jquery';
import DataSource from 'devextreme/data/data_source';

import styles from '../styles/components/CardFavorito.module.css';

export default class CardFavorito extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayExpression: null,
            valueExpression: null,
            ...this.propsToObjState(),
        }
    }

    propsToObjState() {
        return {
            lista: this.props.lista
        }
    }

    componentDidMount() {
        switch(this.props.tipoModulo) {
            case window.MODULOS.DOCUMENTO:
                this.setState({
                    displayExpression: 'nome',
                    valueExpression: 'id'
                });
            break;

            case window.MODULOS.PROCESSO:
                this.setState({
                    displayExpression: 'nome',
                    valueExpression: 'processo_id'
                });
            break;

            default:
                break;
        }
    }

    handleItemDeleting(e) {
        const _this = this;

        const d = $.Deferred();

        confirm('Deseja remover este item dos favoritos? <br /><br /> <strong>'+e.itemData.nome+'</strong>', 'Atenção')
        .done(function(value) { 
            d.resolve(!value);

            if (value) {
                if (_this.props.tipoModulo === window.MODULOS.DOCUMENTO) {
                    window.favoritar_item(e.itemData.tipo, e.itemData.id, false);
                }
                else if (_this.props.tipoModulo === window.MODULOS.PROCESSO) {
                    window.favoritar_item('solicitacao', e.itemData.processo_id, false);
                }
            }
        })
        .fail(d.reject);
        
        e.cancel = d.promise();
    }

    handleItemClick(e) {
        if (this.props.tipoModulo === window.MODULOS.DOCUMENTO) {
            if (e.itemData.tipo === 'pasta') {
                window.View.load('central_documentos/listar', function (html_id, central_documentos_listar) {
                    central_documentos_listar.show(true, e.itemData.id);
                }, window.View.ABA.SIM_E_RECARREGAR_SE_ESTIVER_ABERTA);
            }
            else if (e.itemData.tipo === 'documento') {
                if (e.itemData.id) {
                    // Se a revisão estiver ativa, realiza o download do documento, caso contrário, abre a janela de propriedades.
                    if (e.itemData.ativa === 'S') {
                        window.alert_info('Por favor, aguarde o download do documento');

                        App.obter_token_publico(function (token_publico) {
                            App.download(window.WS_URI + 'documento/download/?documento_revisao_id=' + e.itemData.documento_revisao_id + '&token_publico=' + token_publico);
                        });
                    }
                    else{
                        window.View.load('central_documentos/documento', function(html_id, central_documentos_arquivo) {
                            central_documentos_arquivo.show(e.itemData.id, window.FORMULARIO.EDITAR);
                        }, window.View.ABA.MULTIPLAS);
                    }
                }
                else{
                    alert('Nenhuma versão do documento disponível para download', 'Aviso');
                }
            }
        }
        else if (this.props.tipoModulo === window.MODULOS.PROCESSO) {
            const nova_solicitacao = function(data_referencia) {
                window.alert_info('Por favor, aguarde a abertura de uma nova solicitação');

                WS.post("solicitacao/nova/",
                    {
                        versao_processo_id: e.itemData.versao_processo_id,
                        versao_processo_atividade_id: e.itemData.versao_processo_atividade_id,
                        data_referencia: data_referencia || null,
                    },
                    function(response) {
                        window.View.load('solicitacao/detalhes', function(html_id, solicitacao_detalhes) {
                            solicitacao_detalhes.show(response, window.FORMULARIO.NOVO);
                        }, window.View.ABA.MULTIPLAS);
                    }
                ); 
            }

            // Se alguma atividade do processo estiver utilizando o controle de dias corridos por data de referencia (gestão de tempo).
            if (e.itemData.dias_corridos_data_referencia === 'S') {
                window.View.load('solicitacao/data_referencia', function(html_id, detalhes) {
                    const onselect = function(data_referencia) {
                        nova_solicitacao(data_referencia);
                    }

                    detalhes.onselect = onselect;
                    detalhes.show();
                });
            }
            else{
                nova_solicitacao();
            }
        }
    }

    handleItemTemplate(e) {
        let icone = '';

        if (this.props.tipoModulo === window.MODULOS.DOCUMENTO) {
            if (e.tipo === 'pasta') {
                icone = '<i class="fas fa-folder"></i>';
            }
            else if (e.tipo === 'documento') {
                icone = '<i class="fas fa-file"></i>';
            }
        }
        
        return $('<div title="'+e.nome+'">'+icone+' <span>'+e.nome+'</span></div>');
    }

    render() {
        return(
            <div className={styles.cardContainer}>
                <div>
                    <div>
                        {this.props.iconeUrl !== "" && <img src={this.props.iconeUrl} className={styles.iconeModulo} alt={this.props.nomeModulo}/>}
                        <span className={styles.topoTitulo}>{this.props.nomeModulo}</span>
                    </div>
                    <div>
                        <List
                            dataSource={new DataSource({
                                store: this.state.lista,
                                paginate: true,
                                pageSize: 10,
                                valueExpr: this.state.valueExpression,
                                displayExpr: this.state.displayExpression,
                            })}
                            allowItemDeleting={true}
                            itemDeleteMode='static'
                            onItemDeleting={this.handleItemDeleting.bind(this)}
                            onItemClick={this.handleItemClick.bind(this)}
                            itemTemplate={this.handleItemTemplate.bind(this)}
                        >
                        </List>
                    </div>
                </div>
            </div>
        );
    }
}

CardFavorito.defaultProps = {
    iconeUrl: '',
    nomeModulo: '?',
    tipoModulo: null,
    lista: [],
}
