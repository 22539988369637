import SelecaoPadrao from './SelecaoPadrao';
import { solicitacaoList, solicitacaoColunas } from '../datasource/Solicitacao';

export default class SelecaoSolicitacao extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: solicitacaoList(),
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.solicitacaoId !== this.props.solicitacaoId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && item.id + ' ' + item.assunto;
}

SelecaoSolicitacao.defaultProps = {
    placeholder: "Pesquisar Solicitação",
    displayExpr: displayExpr,
    columns: solicitacaoColunas,
    visible: true
};