import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';
import { alert } from 'devextreme/ui/dialog';

export const colunasListagemFormularios = [
    {
        dataField: 'formulario_id',
        caption: 'ID'
    },
    {
        dataField: 'nome',
        caption: 'Nome'
    },
    {
        dataField: 'tipo_formulario_descritivo',
        caption: 'Tipo Formulário',
    },
    {
        type: 'buttons',
        buttons: [
            {
                hint: 'Editar',
                icon: "edit",
                cssClass: 'icon_black',
                onClick: function(e) {
                    window.View.load('formulario/detalhes', function(html_id, formulario_detalhes) {
                        formulario_detalhes.onclose = e.component.refresh;
                        formulario_detalhes.show(e.row.data.formulario_id, window.FORMULARIO.EDITAR);
                    });
                }
            },
            {
                hint: 'Visualizar',
                icon: "fas fa-eye",
                cssClass: 'icon_black',
                onClick: function(e) {
                    window.View.load('formulario/detalhes', function(html_id, formulario_detalhes) {
                        formulario_detalhes.onclose = e.component.refresh;
                        formulario_detalhes.show(e.row.data.formulario_id, window.FORMULARIO.VISUALIZAR);
                    });
                }
            },
            {
                hint: 'Clonar',
                icon: "copy",
                cssClass: 'icon_black',
                onClick: function(e) {
                    window.View.load('formulario/clonar', function(html_id, clonar_formulario) {
                        clonar_formulario.onclose = e.component.refresh;
                        clonar_formulario.show(e.row.data.nome, e.row.data.formulario_id);
                    });
                }
            },
            {
                hint: 'Exportar',
                icon: "export",
                cssClass: 'icon_black',
                onClick: function(e) {
                    WS.get("formulario/objeto/",
                        { formulario_id: e.row.data.formulario_id },
                        function(response) {
                            let corpo = encodeURIComponent(JSON.stringify(response));
                            let link = document.createElement('a');
                            link.setAttribute('download', e.row.data.nome + '.nbpf');
                            link.setAttribute('href', 'data:application/json;charset=utf-8,' + corpo);
                            link.click();
                        }
                    );
                }
            },
            {
                hint: 'Excluir',
                icon: "trash",
                cssClass: 'icon_black',
                onClick: function(e) {
                    let encontrou_vinculo = false;
                    let perfil_prospect = "";
                    let perfil = "";

                    WS.get("formulario/get_parametros/",
                        null,
                        function(response) {
                            if(response.formulario_cliente_id === e.row.data.formulario_id && response.formulario_cliente_id != null){
                                encontrou_vinculo = true;
                                perfil = "Cliente";
                            } 

                            if(response.formulario_prospect_id === e.row.data.formulario_id && response.formulario_prospect_id != null){
                                encontrou_vinculo = true;
                                perfil_prospect = "Organização";

                                if(perfil !== ""){
                                    perfil = perfil+' e '+perfil_prospect
                                }else{
                                    perfil = perfil_prospect
                                }
                            } 

                            if(!encontrou_vinculo){
                                window.View.load('formulario/detalhes', function(html_id, formulario_detalhes) {
                                    formulario_detalhes.onclose = e.component.refresh;
                                    formulario_detalhes.show(e.row.data.formulario_id, window.FORMULARIO.EXCLUIR);
                                });
                            }
                            else{
                                alert('Este formulário está sendo utilizado em cadastros de <strong>'+perfil+'</strong>. Por gentileza, desfaça o vínculo antes de prosseguir com a exclusão.', 'Aviso');
                            }
                        }
                    );
                }
            },
        ]
    },
];

export const listarFormularios = (parametros) => new DataSource({
    key: 'formulario_id',
    load: (loadOptions) => {
        const dxParams = window.loadOptionsToActionParams(loadOptions);
        const promise = new Promise(function(resolve, reject) {
            WS.get({
                route: 'formulario/listar/',
                data: {
                    ...dxParams,
                    ...parametros
                },
                func_response: function(res) {
                    const paramsGrid = window.createLoadExtra(res);

                    resolve({ ...paramsGrid, data: res.data });
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });

        return promise;
    }
});