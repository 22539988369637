import React from 'react';
import WS from '../api/ws';
import App from '../api/app';
import CardFavorito from './CardFavorito';
import ScrollView from 'devextreme-react/scroll-view';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import styles from '../styles/components/Favoritos.module.css';

export default class Favoritos extends React.Component {
    constructor(props) {
        super(props);

        this.modulos = {
            documentos: window.MODULOS.DOCUMENTO,
            processos: window.MODULOS.PROCESSO,
        }

        this.state = {
            allowRender: false,
            listouProcessos: false,
            listouDocumentos: false,
            listaFavoritos: {
                documentos: [],
                processos: [],
            }
        }

        this.listarFavoritos = this.listarFavoritos.bind(this);
    }

    listarFavoritosDocumentos(_this) {
        if (App.verifica_permissao(App.sessao.dados.empresa_filial_id, 'gestao_documentos')) {
            WS.get("documento/get_favoritos/",
                null,
                function(response) {
                    let documentos, 
                        pastas, 
                        listDocumentos = [];
    
                    if(response){
                        if (response.documentos) {
                            documentos = response.documentos;
                        }
    
                        if (response.pastas) {
                            pastas = response.pastas;
                        }
    
                        if (pastas && documentos) {
                            listDocumentos = [
                                ...documentos,
                                ...pastas
                            ];
                        }
                        else{
                            if (pastas) {
                                listDocumentos = pastas;
                            }
                            else if (documentos) {
                                listDocumentos = documentos;
                            }
                        }
                    }
    
                    _this.setState(prevState => ({
                        listaFavoritos: {
                            ...prevState.listaFavoritos,
                            documentos: listDocumentos,
                        },
                        listouDocumentos: true,
                    }));
                }
            );
        }
        else{
            this.setState({ listouDocumentos: true });
        }
    }

    listarFavoritosProcessos(_this) {
        if (App.verifica_permissao(App.sessao.dados.empresa_filial_id, 'gestao_processos')) {
            WS.get("solicitacao/listar_disponiveis/",
                { lista_apenas_favoritas: true },
                function(response) {
                    let listProcessos = [];
    
                    if (response) {
                        listProcessos = response;
                    }
    
                    _this.setState(prevState => ({
                        listaFavoritos: {
                            ...prevState.listaFavoritos,
                            processos: listProcessos
                        },
                        listouProcessos: true,
                    }));
                }
            );
        }
        else{
            this.setState({ listouProcessos: true });
        }
    }

    listarFavoritos() {
        this.setState({
            listouDocumentos: false,
            listouProcessos: false,
            allowRender: false
        }, () => {
            this.listarFavoritosDocumentos(this);
            this.listarFavoritosProcessos(this);
        });
    }

    componentDidMount() {
        this.listarFavoritos();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.listouDocumentos && this.state.listouProcessos && !prevState.allowRender) {
            this.setState({ allowRender: true });
        }
    }

    render() {
        return(
            (
                this.state.allowRender &&
                (
                    <ScrollView useNative={true} style={{ height: "calc(100vh - 105px)" }}>
                        <div className="panel-heading text-primary">
                            <strong className="dropdown home-title">
                                <i className="fa fa-star text-primary" aria-hidden="true"></i>
                                &nbsp;
                                <span className="txt_timeline">Favoritos</span>

                                <button className="btn btn-default btn_refresh_favoritos btn-title" onClick={this.listarFavoritos}>
                                    <span className="fas fa-sync-alt text-primary"></span>
                                </button>
                            </strong>
                        </div>
                        
                        <div className={styles.container}>
                            {App.verifica_permissao(App.sessao.dados.empresa_filial_id, 'gestao_documentos') && 
                                <CardFavorito 
                                    iconeUrl='assets/img/icones_bp/folder.svg'
                                    nomeModulo='Documentos'
                                    tipoModulo={this.modulos.documentos}
                                    lista={this.state.listaFavoritos.documentos}
                                />
                            }

                            {App.verifica_permissao(App.sessao.dados.empresa_filial_id, 'gestao_processos') && 
                                <CardFavorito 
                                    iconeUrl='assets/img/icones_bp/processos.svg'
                                    nomeModulo='Processos'
                                    tipoModulo={this.modulos.processos}
                                    lista={this.state.listaFavoritos.processos}
                                />
                            }
                        </div>
                    </ScrollView>
               )
            )
            || /* Renderizar o "loading" do Favoritos */
            (
                <>
                    <div className="panel-heading text-primary" style={{ marginBottom: 10 }}>
                        <strong className="dropdown home-title">
                            <i className="fa fa-star text-primary" aria-hidden="true"></i>
                            &nbsp;
                            <span className="txt_timeline">Favoritos</span>

                            <button className="btn btn-default btn_refresh_favoritos btn-title" onClick={this.listarFavoritos}>
                                <span className="fas fa-sync-alt text-primary"></span>
                            </button>
                        </strong>
                    </div>

                    <div className={styles.loadingIndicator}>
                        <LoadIndicator
                            visible={true}
                            height={40}
                            width={40}
                        />
                        <p>Por favor, aguarde</p>
                    </div>
                </>
            )
        );
    }
}
