import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaClienteLogo = {
    dataField: "logo_id",
    caption: "Logo",
    cellTemplate: cellTemplate,
};

function cellTemplate(container, options) {
    var logo_id = options.data.logo_id;
    if(logo_id){
        var link_imagem = window.WS_URI + 'documento/midia/?documento_id='+logo_id+'&token_midia=' + window.App.sessao.token_midia;
        container.html(
            '<div style="text-align: center; margin-top: 3px;">' +
                '<img style="border-radius: 50%; height: 50px; width: 50px" class="imagem_responsavel" src="'+ link_imagem +'">' +
            '</div>'
        );
    }else{
        link_imagem = 'assets/img/img.svg';
        container.html(
            '<div style="text-align: center; margin-top: 3px;">' +
                '<img style="border-radius: 50%; height: 50px; width: 50px" class="imagem_responsavel" src="'+ link_imagem +'">' +
            '</div>'
        );
    }
};

export const colunaClienteCodigo = {
    dataField: "codigo",
    caption: "Código"
};

export const colunaClienteUnidade = {
    dataField: "unidade",
    caption: "Unidade"
};

export const colunaClienteNomeFantasia = {
    dataField: "nome_fantasia",
    caption: "Nome Fantasia"
};

export const colunaClienteRazaoSocial = {
    dataField: "razao_social",
    caption: "Razão Social"
};

export const colunaClienteTelefone1 = {
    dataField: "telefone1",
    caption: "Telefone 1"
};

export const colunaClienteTelefone2 = {
    dataField: "telefone2",
    caption: "Telefone 2"
};

export const colunaClienteTelefone3 = {
    dataField: "telefone3",
    caption: "Telefone 3"
};

export const colunaClienteTelefone4 = {
    dataField: "telefone4",
    caption: "Telefone 4"
};

export const ClienteBotoes = function(clienteParametros) {
    return {
        type: "buttons",
        buttons: [
            {
                hint: "Editar",
                icon: "fas fa-pencil-alt",
                cssClass: "icon_black",
                onClick: function(e) {
                    window.View.load('cliente/detalhes', function(html_id, cliente_detalhes) {
                        cliente_detalhes.onclose = e.component.refresh;
                        cliente_detalhes.show(e.row.data.cliente_id, window.FORMULARIO.EDITAR);
                    }, window.View.ABA.MULTIPLAS);
                },
                visible: window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'cliente') && clienteParametros && clienteParametros.somente_consulta_cliente === "N",
            },
            {
                hint: "Criar Unidade",
                icon: "fas fa-plus",
                cssClass: "icon_black",
                onClick: function(e) {
                    window.View.load('cliente/detalhes', function(html_id, cliente_detalhes) {
                        cliente_detalhes.onclose = e.component.refresh;
                        cliente_detalhes.show(e.row.data.cliente_id, window.FORMULARIO.CRIAR_UNIDADE);
                    }, window.View.ABA.MULTIPLAS);
                },
                visible: window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'cliente') && clienteParametros && clienteParametros.somente_consulta_cliente === "N",
            },
            {
                hint: "Histórico Entidade",
                icon: "fas fa-file",
                cssClass: "icon_black",
                onClick: function(e) {
                    window.View.load("entidade_historico/listar", function(html_id, entidade_historico) {
                        entidade_historico.show(e.row.data.cliente_id, 'C');
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: "Visualizar",
                icon: "fas fa-eye",
                cssClass: "icon_black",
                onClick: function(e) {
                    window.View.load('cliente/detalhes', function(html_id, cliente_detalhes) {
                        cliente_detalhes.show(e.row.data.cliente_id, window.FORMULARIO.VISUALIZAR);
                    }, window.View.ABA.MULTIPLAS);
                }
            },
            {
                hint: "Excluir",
                icon: "fas fa-trash",
                cssClass: "icon_black",
                onClick: function(e) {
                    window.View.load('cliente/detalhes', function(html_id, cliente_detalhes) {
                        cliente_detalhes.onclose = e.component.refresh;
                        cliente_detalhes.show(e.row.data.cliente_id, window.FORMULARIO.EXCLUIR);
                    }, window.View.ABA.MULTIPLAS);
                },
                visible: window.App.verifica_permissao(window.App.sessao.dados.empresa_filial_id, 'cliente') && clienteParametros && clienteParametros.somente_consulta_cliente === "N",
            }
        ],
    };
}

export const clienteColunas = function(clienteParametros) {
    return [
        colunaClienteLogo,
        colunaClienteCodigo,
        colunaClienteUnidade,
        colunaClienteNomeFantasia,
        colunaClienteRazaoSocial,
        colunaClienteTelefone1,
        colunaClienteTelefone2,
        colunaClienteTelefone3,
        colunaClienteTelefone4,
        ClienteBotoes(clienteParametros),
    ]
}

export const stateStoring = {
    enabled: true,
    type: "custom",
    customLoad: function (state) {
        return window.App.get_parametro_usuario('listarClientes');
    },
    customSave: function (state) {
        window.App.set_parametro_usuario('listarClientes', state);
    }
}

export let listarClientes = (parametros) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'cliente_id',
    load: (loadOptions) => {
        let dxParams = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "cliente/listar/", 
                data: {
                    ...dxParams,
                    ...parametros
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;

        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "cliente/listar/", 
                data: {
                    dx_grid: true,
                    cliente_id: key,
                    
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});